import logo from '../../../images/portal/navigation/logo.svg';

window.portalModule.controller 'NavigationController', ($rootScope, $scope, $window, $state, $stateParams, $log, AuditrService, AuthService, BreakpointService, NavigationService, MessageService, LogoutService) ->
  $log = $log.getInstance('NavigationController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'navigation controller loaded.'
  $rootScope.navigationState = {}
  $scope.patientDrawer = {}
  $scope.patientDrawer.isOpen = false

  breakpoint = BreakpointService.getBreakpoint()
  if breakpoint is 'md' or breakpoint is 'lg'
    $rootScope.navigationState.isOpen = true

  $scope.init = ->
    $scope.logo = logo
    $scope.navigation = NavigationService.getNavigationStructure()
    $scope.username = AuthService.getUsername()
    $scope.phrPatients = $rootScope.DS.getPhrPatients()
    $log.log '$scope.phrPatients', $scope.phrPatients
    $scope.corporateLinks = NavigationService.getCorporateLinks()
    # $log.log '$scope.navigation', $scope.navigation
    $scope.setMessagesVisibility()

    # handle events from enquire directive
    $scope.$on 'match', (event, payload) ->
      # $log.log 'setup'
      handleBreakpointChange(event, payload)
    $scope.$on 'setup', (event, payload) ->
      # $log.log 'setup'
      handleBreakpointChange(event, payload)

    handleBreakpointChange = (event, payload) ->
      if payload is '$isMobile' then initMobile(event, payload)
      if payload is '$isTablet' then initTablet(event, payload)
      if payload is '$isDesktop' then initDesktop(event, payload)

    initMobile = (event, payload) ->
      $rootScope.navigationState.isOpen = false
      $scope.$apply()
    initTablet = (event, payload) ->
      $rootScope.navigationState.isOpen = false
      $scope.$apply()
    initDesktop = (event, payload) ->
      $rootScope.navigationState.isOpen = true
      $scope.$apply()

    # listens for event from hamburger to open nav
    $scope.$on 'navigation-toggle-open', (event, payload) ->
      $rootScope.navigationState.isOpen = !$rootScope.navigationState.isOpen

    $scope.$on 'route-change-update-navigation', (event, payload) ->
      # $log.log 'route-change-update-navigation', event, payload
      # if in mobile or tablet mode then close the nav after we navigation
      if $scope.IS_MOBILE or $scope.IS_TABLET
        $rootScope.navigationState.isOpen = false

      filtered = []
      for navItem in $scope.navigation
        if navItem.value == payload
          filtered.push navItem
        else if navItem.children?
          for childItem in navItem.children
            if childItem.value == payload
              filtered.push childItem

      # $log.log event, payload, filtered[0]

      # POST to the audit service on navigation change
      # but don't do that if we're coming from the 'root' controller
      if payload isnt 'root' and filtered[0]?.name
        AuditrService.create("viewed " + filtered[0].name, 'navigation')

      if _.isEmpty filtered[0]
        NavigationService.deactivateSelected($scope.navigation)
      else
        updateNavigation(filtered[0])

  # fired only by clicking a navigation element
  $scope.navigate = (item) ->
    # don't state change when clicking a parent, only toggle it
    if !item.parent
      if item.isLogout
        LogoutService.logout().finally () ->
          AuthService.signoutUserFromAuth0()
      else
        $state.go('portal.' + item.value, {})
    else
      # go to the parent's first child if the parent is closed
      $state.go('portal.' + item.children[0].value, {}) if !item.isOpen
      item.isOpen = !item.isOpen
      item.isActive = !item.isActive
      closeOpenNavItems(item)

  # on a navigation event, update the navigation elements to look right
  updateNavigation = (item) ->
    if item isnt undefined
      if item.root and !item.parent # root, not parent
        item.isActive = true
        closeOpenNavItems(item)
      else if item.root and item.parent # root, and parent (with children)
        toggleAccordion(item)
      else if !item.root # children
        toggleAccordion(item)
        # if a child is clicked, close all others
        for navItem in $scope.navigation
          # only update the caret if the clicked child is not in the same children array
          if navItem.children and !(item in navItem.children)
            navItem.isActive = false
          if navItem.children
            for child in navItem.children
              child.isActive = false
        item.isActive = true

  # open the accordion that was clicked and close the others
  # set the first child as active
  toggleAccordion = (item) ->
    if item.root
      item.isOpen = !item.isOpen
      item.isActive = !item.isActive
      item.children?[0].isActive = true
      closeOpenNavItems(item)
    else if !item.root
      # if the item is a child element, find it's parent
      filtered = []
      for navItem in $scope.navigation
        if navItem.value == item.value
          filtered.push navItem
        else if navItem.children?
          for childItem in navItem.children
            if childItem.value == item.value
              filtered.push navItem

      # don't close the parent if it's already open
      if filtered[0].isOpen == false
        filtered[0].isOpen = !filtered[0].isOpen
        filtered[0].isActive = !filtered[0].isActive
        filtered[0].children?[0].isActive = true
        closeOpenNavItems(filtered[0])

  # close open nav items that are not "item"
  closeOpenNavItems = (item) ->
    for navItem in $scope.navigation
      if navItem != item
        navItem.isOpen = false
        navItem.isActive = false

  $scope.setMessagesVisibility = ->
    messages = _.find $scope.navigation, (item) -> item.value is 'messages'
    if MessageService.isMessagingEnabled()
      messages.isVisible = true
    else
      MessageService.getThreads().then (threads) ->
        messages.isVisible = threads.data && !!threads.data.length

  $scope.init()
