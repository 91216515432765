window.globalHelpersModule.directive "upcomingAppointment", 
['$rootScope', '$log', '$timeout', '$filter', '$state', 'AuthService',
($rootScope, $log, $timeout, $filter, $state, AuthService) ->
  restrict: "E"

  link: (scope, element, attrs) ->
    scope.isOpen = true # is drawer open?

    scope.intakeFormLink = AuthService.getConfigData().INTAKE_URL + '/patient?route=patient/appointments&appointment_guid=' + scope.appointment.scheduledEventGuid
    scope.intakeFormLinkText = 'Complete check-in'

    if scope.appointment.submittedAt
      scope.intakeFormLinkText = 'Review check-in'

    scope.getAddress = (appointment) ->
      facilityAddress = appointment.facilityAddress
      addressArray = [
        facilityAddress.address1,
        facilityAddress.address2,
        facilityAddress.address3,
        facilityAddress.city,
        facilityAddress.state,
        facilityAddress.postalCode
      ]
      return _.compact(addressArray).join(', ')

    # open or close the drawers based on IS_MOBILE
    scope.$watch 'IS_MOBILE', (newValue, oldValue) ->
      if newValue isnt undefined
        scope.isOpen = !newValue

    # enable / disable toggle buttons if IS_MOBILE
    scope.toggleDrawer = (test) ->
      if scope.IS_MOBILE
        scope.isOpen = !scope.isOpen
      else
        scope.isOpen = true

  templateUrl: 'portal/appointments/v1/upcoming/upcoming-appointment-directive-template.html'
]