globalHelpersModule = angular.module('GlobalHelpersModule')
globalHelpersModule.directive 'updatesCount', ['$rootScope', '$log', ($rootScope, $log) ->
  restrict: 'E'
  scope:
    count: '='
  link: (scope, element, attrs) ->

  template:
    """
      <span class="updates-count" data-element="updates-count">{{count}}</span>
    """
]

