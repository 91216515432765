window.portalModule.factory 'NewRequestService', ($rootScope, $log, Restangular, AuthService, FeedItem, SchedulingService) ->
  $log = $log.getInstance('NewRequestService')

  # Configuration
  # -----------------------------------
  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().APPOINTMENT_ENDPOINT_URL + '/api/v2')
    Configurer.addResponseInterceptor (data, operation, what) ->
      if operation is 'get'
        if _.isArray(data.data)
          for item in data.data
            # extend the objects with the FeedItem factory
            angular.extend(item, FeedItem)
        else
          angular.extend(data.data, FeedItem)

        return data
      return data

  _newRequestService = restAngular.all('creation_requests')

  # Service calls
  # -----------------------------------

  # POST - book appointment
  create: (appointmentRequest) ->
    $log.log 'appointmentRequest', appointmentRequest

    request = {}
    request.data = {
      type: 'creation_requests'
      attributes: {
        practice_guid: $rootScope.DS.getCurrentPractice().practice_guid
        facility_guid: appointmentRequest.facilityGuid
        start_date_time_flt: appointmentRequest.dateTime
        patient_practice_guid: $rootScope.DS.getCurrentPatientPracticeGuid()
        patient_first_name: appointmentRequest.firstName
        patient_last_name: appointmentRequest.lastName
        email: appointmentRequest.email
        phone_number: appointmentRequest.mobilePhone
        is_existing_patient: appointmentRequest.isExistingPatient
        birth_date: appointmentRequest.dateOfBirth
        provider_guid: appointmentRequest.providerGuid
        reason_for_visit: appointmentRequest.reason
        additional_time_preferences: appointmentRequest.preferences
      }
    }

    return _newRequestService.post(request)