# takes in an array of tasks and orders them by their state
# TODO: during medication adherence: https://jira.practicefusion.com/browse/CON-3119
# Tasks display and ordering will be based on task type (Adherence vs. appointment booking vs. view lab results), timestamp and state of completion
#   first: Uncompleted Adherence tasks ordered by time-stamp (P1)
#   second: Uncompleted Appointment booking tasks ordered by time-stamp (P2)
#   third: Completed Adherence tasks ordered by time-stamp (P3)
#   Forth: completed appointment booking intake tasks (P4)
angular.module('GlobalHelpersModule').filter "sortByState", ->
  (tasks) ->
    newTasks = _.filter tasks, (task) -> task.isNew()
    inProgressTasks = _.filter tasks, (task) -> task.isInProgress()
    ignored = _.filter tasks, (task) -> task.isIgnored()
    completed = _.filter tasks, (task) -> task.isComplete()

    return _.flatten([newTasks, inProgressTasks, completed, ignored])