window.globalHelpersModule.directive "pastVisit", 
['$rootScope', '$log', '$timeout', '$filter', '$state', 'ModalService', 'PastVisitsService',
($rootScope, $log, $timeout, $filter, $state, ModalService, PastVisitsService) ->
  restrict: "E"

  link: (scope, element, attrs) ->
    scope.isOpen = true # is drawer open?

    scope.launchModal = (pastVisit) ->
      PastVisitsService.getVisit(pastVisit.transcriptGuid).then (response) ->
        # merge the details with the original past visit object
        pastVisit.allergies = response.data.allergies
        pastVisit.diagnoses = response.data.diagnoses
        pastVisit.medications = response.data.medications
        pastVisit.labResults = response.data.labResults

        modalOptions =
          headerText: "Activity Log"
          pastVisit: pastVisit
          emptyDiagnosesMessage: 'No diagnoses recorded during this visit.'
          emptyMedicationsMessage: 'No medications recorded during this visit.'
          emptyAllergiesMessage: 'No allergies recorded during this visit.'
          emptyLabResultsMessage: 'No lab results recorded during this visit.'

        ModalService.showModal({templateUrl: "portal/health-records/past-visits/past-visit-modal.html"}, modalOptions).then (result) ->
          return # no action button here, just return

  templateUrl: 'portal/health-records/past-visits/past-visit-directive-template.html'
]