# this is a modal service that wraps the ui-bootstrap $modal directive
angular.module('GlobalHelpersModule').service "ModalService", ($uibModal) ->
  class ModalService
    constructor: (@$timeout) -> #@color = 'grey'
    modalDefaults =
      backdrop: true
      keyboard: true
      modalFade: true
      templateUrl: "global/templates/modal.html"

    modalOptions =
      closeButtonText: "Close"
      actionButtonText: "OK"
      headerText: "Proceed?"
      bodyText: "Perform this action?"
      formHTML: ""

    @showModal: (customModalDefaults, customModalOptions) ->
      customModalDefaults = {}  unless customModalDefaults
      @show customModalDefaults, customModalOptions

    @show: (customModalDefaults, customModalOptions) ->

      #Create temp objects to work with since we're in a singleton service
      tempModalDefaults = {}
      tempModalOptions = {}

      #Map angular-ui modal custom defaults to modal defaults defined in service
      angular.extend tempModalDefaults, modalDefaults, customModalDefaults

      #Map modal.html $scope custom properties to defaults defined in service
      angular.extend tempModalOptions, modalOptions, customModalOptions
      unless tempModalDefaults.controller
        # the angular ui-bootstrap library supports < angular 1.3 officially.
        # so i'm patching a few of those edge cases. one of them is here.
        # the source doesn't use strict string DI injection into the controller here, so we do it manually
        # hence the ['$scope', '$uibModalInstance', (...)] syntax here
        # http://stackoverflow.com/questions/25296961/angular-bootstrap-modal-causing-strict-di-error-not-sure-why
        tempModalDefaults.controller = ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) ->
          $scope.modalOptions = tempModalOptions
          # the result object is whatever you pass in from the button with the "ok" method on it
          $scope.modalOptions.ok = (result) ->
            $uibModalInstance.close result
            return false

          $scope.modalOptions.close = (result) ->
            $uibModalInstance.dismiss "cancel"
        ]

      $uibModal.open(tempModalDefaults).result
