window.globalHelpersModule.directive "dropdownFilter", 
['$rootScope', '$log', '$timeout', '$filter', '$state'
($rootScope, $log, $timeout, $filter, $state) ->
  restrict: "E"
  scope:
    states: '='
  link: (scope, element, attrs) ->

    scope.getCurrentState = ->
      selected = _.filter scope.states, (state) -> state.selected is true
      return selected[0]

    scope.navigateTo = (state) ->
      $state.go(state)

  template:
    """
      <div class="btn-group dropdown-filter fx-fade-down" data-element="dropdown-filter-appointments" ng-show="getCurrentState()">
        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-expanded="false" data-element="selected-state">
            {{getCurrentState().name}} ({{getCurrentState().itemCount || 0}}) <i class="icon icon-caret-bottom"></i>
        </button>
        <ul class="dropdown-menu" role="menu">
            <li ng-repeat="state in states track by $index">
                <a href="javascript:void(0)" ng-click="navigateTo(state.uiSref)">
                    <span class="name" data-element="state">{{state.name}}</span> ({{state.itemCount || 0}})
                </a>
            </li>
        </ul>
      </div>
    """
]