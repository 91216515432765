# setup application-wide event listening here
window.phr.run(($rootScope, $window, $http, $log, $timeout, $state, Restangular, NotificationService, AuthService, ValidateJwtService) ->
  AuthService.initializeAuthenticatedSession()

  # hook into the route change process here
  $rootScope.$on "$stateChangeStart", (event, toState, toParams, fromState, fromParams) ->
    if (toState.data)
      $rootScope.pageTitle = toState.data.pageTitle
    $rootScope.params = toParams
    $log.log '$stateChangeStart', toState.name.replace('portal.', '')
    $rootScope.$broadcast('route-change-update-navigation', toState.name.replace('portal.', ''))

  # loading handles turning on and off our spinners globally
  $rootScope.loading = 0
  Restangular.addRequestInterceptor (element, operation, what, url) ->
    # $log.log 'addRequestInterceptor', element, operation, what, url
    $rootScope.loading++ unless (what is 'ccdas' or what is 'phr_patients' or what is 'audit_entries')
    return element # make sure to return response or calls will fail

  Restangular.addResponseInterceptor (element, operation, what, url) ->
    # $log.log 'addResponseInterceptor', element, operation, what, url
    $rootScope.loading-- unless (what is 'ccdas' or what is 'phr_patients' or what is 'audit_entries')
    return element # make sure to return response or calls will fail

  Restangular.setErrorInterceptor (response) ->
    # $log.log 'setErrorInterceptor', response
    $rootScope.loading--

    # If the auth credentials are bad (session timeout),
    # and we're not in dev mode, go to intake login page
    if response.status == 401
      if (AuthService.getEnvironment() == 'development'  || AuthService.getEnvironment() == 'test')
        AuthService.deleteAuthToken()
        $state.go('dev_landing_page', {})
      else
        ValidateJwtService.validate().then (response) ->
          # $log.log '401', response.data
          if !response.data.isLoggedIn
            AuthService.deleteAuthToken()
            $window.location.href = AuthService.getSignoutUrl()

    return response # make sure to return response or calls will fail
)
