window.portalModule.factory 'AgreementsOrderService', ($rootScope, $log, $sce, AgreementsService) ->
  $log = $log.getInstance('AgreementsOrderService')
  localSmsAgreement = null

  init: ($scope) ->
    AgreementsService.findAll().then (response) ->
        $scope.smsAgreement = null
        $scope.agreements = response.data

        # find the terms agreement
        for agreement in $scope.agreements
          if (agreement.agreement_type is 'SmsConsent')
            $scope.smsAgreement = agreement
            $scope.smsVetoUrl = $sce.trustAsResourceUrl $scope.smsAgreement.url
            $scope.smsVetoSummaryUrl = $sce.trustAsResourceUrl $scope.smsAgreement.summary_url

        localSmsAgreement = {agreement:$scope.smsAgreement, url:$scope.smsVetoUrl, summaryUrl:$scope.smsVetoSummaryUrl}
