window.portalModule.controller 'SubmitAppointmentRequestController', ($rootScope, $scope, $state, $stateParams, $log, toastr, NewRequestService, SchedulingService, ProvidersService, PhrPatientService, PhrUserService) ->
  $log = $log.getInstance('SubmitAppointmentRequestController')
  $log.log 'submit appointment request controller loaded.'

  $scope.headerText = "Request appointment"
  $log.log '$scope.$parent.newAppointmentRequest', $scope.$parent.newAppointmentRequest
  $log.log '$stateParams', $stateParams
  $scope.submitBtnDisabled = false

  ProvidersService.getProvidersForCurrentPractice().then (providers) ->
    $scope.provider = _.find providers, (provider, key) -> provider.profile_guid is $stateParams.profileGuid
    $log.log '$scope.provider', $scope.provider

  # get the patient's data to display
  PhrPatientService.get($rootScope.DS.getCurrentPhrPatient().phrPatientGuid).then (response) ->
    $scope.phrPatient = response.data

  # helper variable so we can present the appointment time in the modal formatted correctly
  $scope.formattedDateTime = SchedulingService.formatDateTime($scope.$parent.newAppointmentRequest.dateTime)

  $scope.submit = ->
    $scope.submitBtnDisabled = true
    # make sure the dateTime is formatted as 'YYYY/MM/DD HH:mm' before submission
    $scope.$parent.newAppointmentRequest.dateTime = $scope.formattedDateTime

    # update the request object w/ data from the phr patient service call
    $scope.$parent.newAppointmentRequest.firstName = $scope.phrPatient.firstName
    $scope.$parent.newAppointmentRequest.lastName = $scope.phrPatient.lastName
    $scope.$parent.newAppointmentRequest.dateOfBirth = $scope.phrPatient.dateOfBirth
    $scope.$parent.newAppointmentRequest.email = $scope.phrPatient.email
    $scope.$parent.newAppointmentRequest.mobilePhone = $scope.phrPatient.mobilePhone
    $scope.$parent.newAppointmentRequest.providerGuid = $scope.provider.provider_guid

    NewRequestService.create($scope.$parent.newAppointmentRequest).then (response) ->
      $scope.submitBtnDisabled = false
      $rootScope.$broadcast 'fetch-feed-items'
      toastr.success('Your appointment request has been sent to the doctor\'s office')
    , (response) ->
      handleError(response)

    # close the modal on success
    $scope.$parent.close()

  handleError = (response) ->
    $scope.submitBtnDisabled = false
    toastr.error('There was an error with your request. Please try again.')