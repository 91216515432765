angular.module('GlobalHelpersModule').filter "dayOfTheWeekLocal", ->
  (date, includeDate) ->
    if date? and date.length > 0
      # embedding new Date(date) here avoids deprecations coming from moment.js
      day = moment(new Date(date)).format('ddd')
      if includeDate is 'includeDate'
        date = moment(new Date(date)).format('MMM D')
        return day + " " + date
      else
        return day
    else
      ""