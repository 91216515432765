window.portalModule.controller 'DeprecatedPastAppointmentsController', ($scope, $state, $stateParams, $log, AuthService, AppointmentsService) ->
  $log = $log.getInstance('DeprecatedPastAppointmentsController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'appointments-past controller loaded.'

  $scope.customPageHeader = "Appointments"
  AppointmentsService.past().then (response) ->
    $scope.past = response.data
    $log.log '$scope.past', response

  $scope.hasDownloadAndTransmitLinks = false
