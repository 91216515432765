window.portalModule.controller 'CanceledAppointmentsController', ($scope, $state, $stateParams, $log, AuthService, FeedItemService) ->
  $log = $log.getInstance('CanceledAppointmentsController')
  $log.log 'canceled appointments controller loaded.'
  $scope.hasDownloadAndTransmitLinks = false
  $scope.customPageHeader = 'Appointments'
  $scope.filter = 'canceled'

  # listen for an event to refresh the feed items (reload the data)
  $scope.$on 'fetch-feed-items', (event, payload) ->
    $log.log event
    $scope.init()

  $scope.init = ->
    FeedItemService.canceled().then (response) ->
      $scope.appointmentFeed = response.data
      $log.log '$scope.appointments', $scope.appointmentFeed

    FeedItemService.getStates('canceled').then (response) ->
      $scope.states = response

  $scope.init()