window.portalModule.directive "appointmentDetails", ['$state', '$log', ($state, $log) ->
  restrict: "E"
  scope:
    feedItem: '='
    uiSref: '@'
  link: (scope, element, attrs) ->
    scope.showModal = ->
      $state.go(scope.uiSref, {feedItemGuid:scope.feedItem.feed_item_guid, feedItemType:scope.feedItem.feed_item_type})

  templateUrl: 'portal/appointments/feed-items/appointment-details/appointment-details-directive-template.html'
]