window.tasksModule.directive "tOabSurvey", 
['$window', '$rootScope', '$log', '$state', 'TasksService',
($window, $rootScope, $log, $state, TasksService) ->
  restrict: "E"
  scope:
    task: '='
  link: (scope, element, attrs) ->
    scope.$log = $log.getInstance('tOabSurvey directive', true, false, true)
    scope.$log.log 'tOab'

    scope.setTaskState = (state) ->
      # update the task on the server
      $log.log 'setTaskState: tAppointment', state
      TasksService.setState(scope.task, state).then (response) ->
        scope.$emit 'task-updated'

    scope.beginSurvey = ->
      $window.open(scope.beginSurveyUrl(scope.task.properties.survey_url), '_blank')

    # CON-4631 - the task property survey_url is the one that forces the user
    # to log out and back in again, prepending /patient to the route will allow
    # the user to get to the survey without first being logged out
    scope.beginSurveyUrl = (surveyUrl) ->
      parser = document.createElement('a')
      parser.href = surveyUrl
      parser.pathname = '/patient' + parser.pathname
      return parser.href

  templateUrl: 'portal/tasks/tasks/t-oab-survey/t-oab-survey-directive-template.html'
]