window.globalHelpersModule.directive 'calendarCell', ->
  restrict: 'E'
  replace: true
  link: (scope, element, attrs) ->
    month = scope.day.month()
    scope.date = scope.day.date()
    scope.isPreviousMonth = month < scope.month
    scope.isNextMonth = month > scope.month
    scope.isToday = scope.day.isSame(scope.today, 'day')
    scope.isFuture = scope.day.isAfter(scope.today)
    updateIsSelected = ->
      scope.isSelected = false
      scope.isDisabled = scope.isFuture
      if scope.startDay
        if scope.day.isSame(scope.startDay, 'day')
          scope.isSelected = true
        else if scope.endDay
          if scope.day.isSame(scope.endDay, 'day')
            scope.isSelected = true
          else if scope.day.isBefore(scope.endDay) and scope.day.isAfter(scope.startDay)
            scope.isSelected = true
        else if scope.day.isBefore(scope.startDay)
          scope.isDisabled = true

    scope.$watch(attrs.startDay, updateIsSelected)
    scope.$watch(attrs.endDay, updateIsSelected)
    updateIsSelected()

    scope.selectDay = (day) ->
      if not scope.isDisabled
        scope.onSelectDay { day }

  scope:
    day: '='
    month: '='
    today: '='
    startDay: '='
    endDay: '='
    onSelectDay: '&onSelectDay'

  templateUrl: 'portal/health-records/calendar-cell/calendar-cell-directive-template.html'
