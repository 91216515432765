window.portalModule.controller 'ActivityController', ($rootScope, $scope, $log, AuditrService, ModalService, PhrPatientService) ->
  $log = $log.getInstance('ActivityController')
  $log.log 'activity controller loaded.'

  $scope.customPageHeader = "Activity log"
  $scope.hasDownloadAndTransmitLinks = false

  # infinite scroll
  $scope.isScrolling = false # setting to true disables scrolling
  $scope.isLoading = false # shows the loading indicator

  # offset, limit
  $scope.init = ->
    AuditrService.findAll(0, 100).then (response) ->
      $scope.audits = response.data
      $log.log 'audits', $scope.audits

  # TODO/FIXME: we only fetch PatientPractice records for current PPRAs
  # This means the auditr log may not be able to reconstruct patient descriptions for every entry.
  # We could use phrPatients as a surrogate for this data, but the right fix is probably to store the data in the auditr entries directly
  $scope.patientPractices = $rootScope.DS.getPatientPractices() # note the response object here is an object, not an array.

  $scope.updatePatientDescription = (records) ->
    for record in records
      ppg = record.patientPracticeGuid.toLowerCase()
      if !_.includes(record.description, 'logged in') and _.has($scope.patientPractices, ppg)
        patient = $scope.patientPractices[ppg]
        record.patientDescription = 'for ' + patient.firstName + ' ' + patient.lastName

  $scope.getMoreEntries = ->
    $scope.isScrolling = true
    $scope.isLoading = true
    AuditrService.getMoreEntries(100, 100).then (response) ->
      # add the new audits to the existing array
      $scope.updatePatientDescription response.data
      $scope.audits.push.apply($scope.audits, response.data)
      $log.log 'audits - get more', $scope.audits
      # turn off scrolling calls permanently when we recieve an empty array (no more forms to load)
      $scope.isScrolling = false if response.data.length isnt 0
      $scope.isLoading = false

  # make sure to update the data when we switch patientPractices
  $scope.$watch(
    ->
      $rootScope.DS.getCurrentPractice()
    ->
      $scope.init()
  )

  $scope.init()