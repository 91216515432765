window.tasksModule.directive "tPatientPortal", 
['$rootScope', '$log', '$state', 'TasksService',
($rootScope, $log, $state, TasksService) ->
  restrict: "E"

  link: (scope, element, attrs) ->
    scope.$log = $log.getInstance('tPatientPortal directive', true, false, true)
    scope.$log.log 'tPatientPortal'

  templateUrl: 'portal/tasks/tasks/t-patient-portal/t-patient-portal-directive-template.html'
]