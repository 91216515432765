window.portalModule.factory 'EmailValidationService', ($log, Restangular, AuthService) ->
  $log = $log.getInstance('EmailValidationService')

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().DIRECT_ENDPOINT_URL)

  _emailValidationService = restAngular.all("message")

  validate: (emailAddress) ->
    return _emailValidationService.customGET('validate', {address: emailAddress})