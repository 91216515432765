window.portalModule.controller 'ChooseDateTimeController', ($rootScope, $scope, $state, $stateParams, $log, ProvidersService) ->
  $log = $log.getInstance('ChooseDateTimeController')
  $log.log 'choose date time controller loaded.'
  $scope.headerText = 'Select an appointment time'
  $scope.profileGuid = $stateParams.profileGuid
  $scope.facilityGuid = $stateParams.facilityGuid

  ProvidersService.getProvidersForCurrentPractice().then (providers) ->
    $scope.provider = _.find providers, (provider, key) -> provider.profile_guid is $stateParams.profileGuid
    $log.log '$scope.provider', $scope.provider

    # back filling the new appointment request object just in case the user skips the previous (provider) panel and
    # deep links into this one. This would allow us to skip the choose provider screen if the user has a default provider (for example)
    $scope.$parent.newAppointmentRequest.profileGuid = $stateParams.profileGuid
    $scope.$parent.newAppointmentRequest.facilityGuid = $stateParams.facilityGuid

    $log.log '$scope.$parent.newAppointmentRequest', $scope.$parent.newAppointmentRequest