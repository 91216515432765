window.portalModule.factory 'AgreementsService', ($rootScope, $log, Restangular, AuthService) ->
  $log = $log.getInstance('AgreementsService')

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().AGREEMENT_ENDPOINT_URL)

  _agreementsService = restAngular.all("agreement_statuses")

  findAll: (agreementType) ->
    return _agreementsService.get('', {guids: AuthService.getPhrUserGuid(), guid_type: 'Profile', agreement_type:agreementType})

  postUserTerms: (agreement, decision) ->
    $log.log 'post user terms'
    updatedAgreement = agreement
    if decision is 'accept'
      updatedAgreement.accepted = true
      updatedAgreement.status = 'accepted'
    else if decision is 'declined'
      updatedAgreement.accepted = false
      updatedAgreement.status = 'declined'
    updatedAgreement.guid = AuthService.getPhrUserGuid()
    updatedAgreement.guid_type = 'Profile'

    return _agreementsService.post({agreement_statuses:updatedAgreement})

  postSmsConsent: (agreement, decision, user) ->
    $log.log 'post sms consent'
    updatedAgreement = agreement
    if decision is 'accept'
      updatedAgreement.accepted = true
      updatedAgreement.status = 'accepted'
    else if decision is 'declined'
      updatedAgreement.accepted = false
      updatedAgreement.status = 'declined'
    updatedAgreement.guid = AuthService.getPhrUserGuid()
    updatedAgreement.guid_type = 'Profile'
    updatedAgreement.extra_data.phone = user.mobile

    $log.log updatedAgreement
    return _agreementsService.post({agreement_statuses:updatedAgreement})


    #          https://int-my.patientfusion.com/veto/agreement_statuses
    #          accepted: true
    #          acknowledgment_date: null
    #          agreement_guid: "a977566e-3488-40a1-83ea-936137b90774"
    #          agreement_id: 6
    #          agreement_type: "SmsConsent"
    #          declineable: true
    #          effective_date: "2014-09-25"
    #          extra_data: {phone: "809-555-5555"}
    #          guid: "f80dfb73-994a-4202-8b58-a46a51c23fa8"
    #          guid_type: "Profile"
    #          status: "accepted"
    #          summary_url: "https://veto.s3.amazonaws.com/int/uploads/agreement/document/6/SMS_Terms.html"
    #          url: "https://veto.s3.amazonaws.com/int/uploads/agreement/document/6/SMS_Terms.html"

    # https://int-my.patientfusion.com/contacts/19968
    #      allow_sms: null
    #      email: "pftesters@dev.practicefusion.com"
    #      first_name: "Freynard"
    #      home_phone: null
    #      id: 19968
    #      last_name: "Hola"
    #      mobile_phone: "809-555-5555"
