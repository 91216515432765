# model for FeedItems
# can use this class to extend the returned JSON
window.portalModule.factory 'FeedItem', ($log, $filter) ->

  # Using indexOf instead of includes for Safari, IE, and Opera
  isConfirmed: ->
    this.record_state.indexOf('confirmed') isnt -1

  # Using indexOf instead of includes for Safari, IE, and Opera
  isPending: ->
    this.record_state.indexOf('requested') isnt -1

  isCreationRequest: ->
    this.record_state.indexOf('creation') isnt -1

  isProviderProposed: ->
    this.record_state is 'confirmed_provider_proposed'

  isRescheduleRequest: ->
    this.feed_item_type is 'reschedule_requests'

  isCancellationRequest: ->
    this.feed_item_type is 'cancellation_requests'

  getAppointmentTypeText: ->
    if this.isCreationRequest()
      'appointment request'
    else 'appointment'

  shouldShowRescheduleDisclaimer: ->
    this.record_state == 'confirmed_reschedule_requested' or this.record_state == 'confirmed_cancellation_requested' or this.record_state == 'confirmed'

  getDetails: ->
    switch this.record_state
      when 'confirmed'
        {
          icon: 'fa-check-circle',
          text: 'Confirmed',
          colorClass: 'color-green01'
        }
      when 'creation_requested'
        {
          icon: 'fa-minus-circle',
          text: 'Pending confirmation',
          colorClass: 'color-grey05'
          disclaimer: {
            leadingText: 'The appointment may require approval from the doctor\'s office.'
            text: ''
            trailingText: ''
          }
        }
      when 'creation_denied'
        {
          icon: 'fa-minus-circle',
          text: 'Declined',
          colorClass: 'color-red01'
        }
      when 'creation_expired'
        {
          icon: 'fa-minus-circle',
          text: 'Expired',
          colorClass: 'color-grey05'
        }
      when 'confirmed_proposal_expired'
        {
          icon: 'fa-minus-circle',
          text: 'Expired',
          colorClass: 'color-grey05'
        }
      when 'creation_canceled'
        {
          icon: 'fa-minus-circle',
          text: 'Canceled',
          colorClass: 'color-red01'
        }
      when 'confirmed_reschedule_approved'
        {
          icon: 'fa-check-circle',
          text: 'Confirmed',
          colorClass: 'color-green01'
        }
      when 'reschedule_requested'
        {
          icon: 'fa-minus-circle',
          text: 'Pending confirmation',
          colorClass: 'color-grey05'
          disclaimer: {
            leadingText: 'The original appointment request will be '
            text: 'updated immediately'
            trailingText: ' once you request a new appointment time.'
          }
        }
      when 'confirmed_reschedule_requested'
        {
          icon: 'fa-minus-circle',
          text: 'Reschedule requested',
          colorClass: 'color-grey05'
          info: {
            leadingText: 'for '
            datetime: $filter('appointmentDatetimeLocal')(this.information.requested_start_date_time)
            trailingText: 'Awaiting response from doctor\'s office'
          }
        }
      when 'confirmed_reschedule_denied'
        {
          icon: 'fa-check-circle',
          text: 'Confirmed',
          colorClass: 'color-green01'
          info: {
            leadingText: $filter('appointmentDatetimeLocal')(this.information.cancellation_requested_at_date_time)
          }
        }
      when 'confirmed_cancellation_requested'
        {
          icon: 'fa-minus-circle',
          text: 'Cancellation requested'
          colorClass: 'color-grey05'
          info: {
            leadingText: if this.charges_may_apply then 'Awaiting response from doctor\'s office' else ''
            datetime: ''
            trailingText: ''
          }
        }
      when 'canceled_cancellation_approved'
        {
          icon: 'fa-minus-circle',
          text: 'Canceled',
          colorClass: 'color-red01'
        }
      when 'canceled_reschedule_requested'
        {
          icon: 'fa-minus-circle',
          text: 'Canceled',
          colorClass: 'color-red01'
        }
      when 'canceled'
        {
          icon: 'fa-minus-circle',
          text: 'Canceled',
          colorClass: 'color-red01'
        }
      when 'confirmed_cancellation_denied'
        {
          icon: 'fa-check-circle',
          text: 'Confirmed',
          colorClass: 'color-green01'
        }
      when 'confirmed_provider_proposed'
        {
          info: {
            leadingText: 'Your original appointment request is no longer available'
            datetime: ''
            trailingText: ''
          }
        }
      when 'confirmed_proposal_rejected'
        {
          icon: 'fa-minus-circle',
          text: 'Declined',
          colorClass: 'color-red01'
        }