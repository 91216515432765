window.globalHelpersModule.directive "confirmedProviderProposed", 
['$rootScope', '$log', '$timeout', '$filter', '$state', 'AuthService', 'ProposalService', 'toastr',
($rootScope, $log, $timeout, $filter, $state, AuthService, ProposalService, toastr) ->
  restrict: "E"
  scope:
    feedItem: "="
  link: (scope, element, attrs) ->

    scope.confirmProviderProposal = (feedItem) ->
      ProposalService.transitionRequest(feedItem, 'accept').then (response) ->
        toastr.success('You accepted the appointment change suggested from doctor\'s office. The appointment change is confirmed.')
        $rootScope.$broadcast 'fetch-feed-items'

  templateUrl: 'portal/appointments/feed-items/confirmed_provider_proposed/confirmed_provider_proposed-directive-template.html'
]