window.portalModule.directive 'passwordStrengthLengthIndicator', ->
  restrict: 'E'
  replace: true

  scope:
    password: '='
    visible: '='
    passwordStrength: '='

  link: (scope, element, attrs) ->
    scope.states = {
      default:
        colors: ['default', 'default', 'default', 'default', 'default']
        helperText: '8 characters minimum'
      veryWeak:
        colors: ['weak', 'default', 'default', 'default', 'default']
        helperText: '8 characters minimum'
      weak:
        colors: ['weak', 'weak', 'default', 'default', 'default']
        helperText: '8 characters minimum'
      good:
        colors: ['good', 'good', 'good', 'default', 'default']
        helperText: 'Good'
      strong:
        colors: ['strong', 'strong', 'strong', 'strong', 'strong']
        helperText: 'Strong'
    }

    scope.analyzeStrength = ->
      possibleScores = ['default', 'veryWeak', 'weak', 'good', 'strong']
      strength = scope.passwordStrength
      length = scope.password.length
      score = strength
      scope.helperText = null

      if !scope.password
        return 'default'
      if strength < 3 and length > 7
        scope.helperText = 'Weak'
        return 'good'
      if strength == 0 and length > 0
        return 'veryWeak'
      if strength > 2 and length < 8
        return 'weak'
      return possibleScores[score]

    scope.setIndicatorState = () ->
      currentState = scope.analyzeStrength()
      scope.blocks = scope.states[currentState]['colors'].map (state) ->
        return { 'strength': state }
      if !scope.helperText
        scope.helperText = scope.states[currentState].helperText
      scope.currentState = if currentState is 'weak' or currentState is 'veryWeak' then 'weak' else currentState

    scope.$watch('password', -> scope.setIndicatorState())


  templateUrl: 'portal/ccdas/password-strength-length-indicator/password-strength-length-indicator-directive-template.html'
