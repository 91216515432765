window.portalModule.directive "downloadXmlBtn", 
['$rootScope', '$log', '$sce', '$filter', 'AuthService', 'ModalService', 'CCDAService', 'PastVisitsService', 'AuditrService',
($rootScope, $log, $sce, $filter, AuthService, ModalService, CCDAService, PastVisitsService, AuditrService) ->
  restrict: "E"
  replace: true
  scope:
    ccdaDocuments : "="
  link: (scope, element, attrs) ->
    # Because modal is dependent on ccdaDocuments being loaded and available via rootScope, add watcher
    # for ccdaDocuments which is loaded asynchronously from parent controller (i.e. Labs Controller)
    scope.$watch 'ccdaDocuments', (newValue, oldValue) ->
      if newValue != oldValue
        $rootScope.DS.ccdas = scope.ccdaDocuments

    scope.recordTypes = CCDAService.getRecordTypes()
    scope.isGenerateXmlRecordsVisible = false

    scope.launchDownloadXMLModal = ->
      modalOptions =
        formActionUrl: $sce.trustAsResourceUrl AuthService.getConfigData().CCDA_ENDPOINT_URL + "/api/v1/ccda_documents/download"
        isGenerateXmlRecordsVisible: scope.isGenerateXmlRecordsVisible
        recordTypes: angular.copy scope.recordTypes
        selectedRecordTypes: []
        selectedCCDA: null
        headerText: "Download XML Records"
        subHeaderText: "Select XML record to download"
        actionBtnText: "Download XML Record"
        generateActionBtnText: "Generate XML Record"

      ModalService.showModal({templateUrl: "portal/ccdas/download-xml-btn/download-xml-modal.html"}, modalOptions).then (result) ->
        console.log result
        #if result.result is 'downloadXml' # or 'generateXml'
          # NOTE: request happens via regular form submission as its not possible to download a file via an AJAX request
        if result.result is 'generateXml'
          CCDAService.generateXMLRecord(result).then (response) ->
            # add result to the beginning of the Datastore array
            $rootScope.DS.ccdas.unshift(response.data)
            $log.log 'generateXml', response.data
            # scope.isGenerateXmlSuccess = true # show the success alert
            # TODO: hook up alerts to success and error alerts

            # reopen the download modal
            scope.launchDownloadXMLModal()

  templateUrl: 'portal/ccdas/download-xml-btn/download-xml-btn-directive-template.html'
]