import logo from '../../../../images/base/logo-brandmark.svg';

window.portalModule.controller 'ImmunizationsController', ($scope, $state, $stateParams, $log, $filter, AuthService, ImmunizationsService, FEATURE_BITS) ->
  $scope.logo = logo
  $log = $log.getInstance('ImmunizationsController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'immunizations controller loaded.'
  $scope.isHealthRecordsOverviewOn = FEATURE_BITS.isHealthRecordsOverviewOn

  ImmunizationsService.findAll().then (response) ->
    $scope.immunizations = response.data
    $log.log '$scope.immunizations', $scope.immunizations
