window.globalHelpersModule.directive 'dateRangePicker', ->
  restrict: 'E'
  replace: true
  link: (scope, element, attrs) ->
    if not attrs.isOverlayVisible
      scope.isOverlayVisible = false
    if not attrs.range
      scope.range =
        start: null
        end: null
    scope.today = moment(new Date())
    scope.startDay = null
    scope.endDay = null
    scope.errors =
      start: null
      end: null
    scope.shortcuts = [
      { number: 7, unit: 'days', day: scope.today.clone().subtract(7, 'days') },
      { number: 30, unit: 'days', day: scope.today.clone().subtract(30, 'days') },
      { number: 60, unit: 'days', day: scope.today.clone().subtract(60, 'days') },
      { number: 90, unit: 'days', day: scope.today.clone().subtract(90, 'days') },
      { number: 1, unit: 'year', day: scope.today.clone().subtract(1, 'years') }
    ]

    scope.onOverlayVisible = ->
      scope.previous =
        startDay: if scope.startDay then scope.startDay.clone() else null
        endDay: if scope.endDay then scope.endDay.clone() else null
      scope.setMonth scope.today.clone().startOf('month')
    scope.toggleOverlay = ->
      scope.isOverlayVisible = !scope.isOverlayVisible
      if not scope.isOverlayVisible
        scope.setRange(scope.previous.startDay, scope.previous.endDay)
        scope.errors =
          start: null
          end: null
        scope.onCloseOverlay()
    scope.save = ->
      if not scope.range.start
        scope.errors.start = 'Enter a date'
      if not scope.range.end
        scope.errors.end = 'Enter a date'
      if scope.errors.start or scope.errors.end
        return
      scope.onSelectDateRange()
      scope.range.str = "#{scope.startDay.format('MM/DD/YYYY')} - #{scope.endDay.format('MM/DD/YYYY')}"
      scope.isOverlayVisible = false
      scope.onCloseOverlay()

    scope.setMonth = (firstDay) ->
      scope.monthText = firstDay.format('MM/YYYY')
      scope.month = firstDay.month()
      scope.year = firstDay.year()
      endOfMonth = firstDay.clone().endOf('month')
      currentDay = firstDay.clone().startOf('week')
      scope.isNextMonthDisabled = firstDay.isSame(scope.today, 'month')
      scope.weeks = until currentDay.isAfter(endOfMonth)
        for [0...7]
          day = currentDay
          currentDay = currentDay.clone().add(1, 'days')
          day
    scope.changeMonth = (next) ->
      # month is zero based, so we don't need to subtract one for previous
      month = if next then scope.month + 2 else scope.month
      year = scope.year
      if month is 13
        month = 1
        year++
      if month is 0
        month = 12
        year--
      scope.setMonth moment("#{year}-#{month}-1")
    scope.selectDay = (day) ->
      if scope.startDay
        if scope.endDay
          scope.startDay = day
          scope.range.start = day.format('MM/DD/YYYY')
          scope.endDay = null
          scope.range.end = null
        else
          scope.endDay = day
          scope.range.end = day.format('MM/DD/YYYY')
      else
        scope.startDay = day
        scope.range.start = day.format('MM/DD/YYYY')
    scope.setRange = (startDay, endDay) ->
      scope.startDay = startDay
      scope.endDay = endDay
      scope.range.start = if startDay then startDay.format('MM/DD/YYYY') else null
      scope.range.end = if endDay then endDay.format('MM/DD/YYYY') else null

    momentIfValid = (str) ->
      if str
        day = moment(str)
        if day.isValid()
          if day.isBefore(scope.today) or day.isSame(scope.today, 'day')
            return day
      return null
    scope.validate = (key, errorOnInvalid) ->
      dayStr = scope.range[key]
      day = momentIfValid(dayStr)
      error = 'Date not valid'
      scopeKey = "#{key}Day"
      if day
        isStart = key is 'start'
        startDay = if isStart then day else momentIfValid(scope.range.start)
        endDay = if isStart then momentIfValid(scope.range.end) else day
        if startDay and endDay
          if endDay.isBefore(startDay)
            scope.errors[key] = error
            return scope[scopeKey] = null
          scope.errors.start = null
          scope.errors.end = null
          scope.startDay = startDay
          scope.endDay = endDay
        scope.setMonth day.clone().startOf('month')
      else if errorOnInvalid and dayStr
        scope.errors[key] = error
      else
        scope.errors[key] = null
      scope[scopeKey] = day
    scope.$watch('range.start', -> scope.validate('start', false))
    scope.$watch('range.end', -> scope.validate('end', false))
    scope.$watch('isOverlayVisible', -> scope.onOverlayVisible() if scope.isOverlayVisible)
    if scope.isOverlayVisible
      scope.onOverlayVisible()

  scope:
    isOverlayVisible: '='
    range: '='
    onSelectDateRange: '&onSelectDateRange'
    onCloseOverlay: '&onCloseOverlay'

  templateUrl: 'portal/health-records/date-range-picker/date-range-picker-directive-template.html'
