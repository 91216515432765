window.tasksModule.directive "tAppointment", 
['$log', 'toastr', 'ProposalService', 'FeedItemService', 'TasksService',
($log, toastr, ProposalService, FeedItemService, TasksService) ->
  restrict: "E"
  scope:
    task: '='
  link: (scope, element, attrs) ->
    scope.$log = $log.getInstance('tAppointment directive', true, false, true)
    scope.$log.log 'tAppointment'

    FeedItemService.get(scope.task.subject_guid, scope.task.properties.request_type).then (response) ->
      scope.feedItem = response.data
      scope.appointmentDateTime = if scope.feedItem.isProviderProposed() then scope.feedItem.information.proposed_start_date_time else scope.feedItem.start_date_time
      scope.$log.log response.data

    scope.setTaskState = (state) ->
      # update the task on the server
      $log.log 'setTaskState: tAppointment', state
      TasksService.setState(scope.task, state).then (response) ->
        scope.$emit 'task-updated'

  templateUrl: 'portal/tasks/tasks/t-appointment/t-appointment-directive-template.html'
]