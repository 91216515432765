window.tasksModule.factory 'RegistrationService',
['$log', 'Restangular', 'AuthService',
($log, Restangular, AuthService) ->
  $log = $log.getInstance('RegistrationService')

  restAngular = Restangular.withConfig (Configurer) ->
    Configurer.setBaseUrl(AuthService.getConfigData().PHR_USER_ENDPOINT_URL_v1)

  _registrationService = restAngular.all("")

  getEnrollment: (enrollmentGuid) ->
    return _registrationService.one('enrollments', enrollmentGuid).get()

  updateEnrollment: (enrollmentGuid) ->
    return _registrationService.one('enrollments', enrollmentGuid).put()
]