globalHelpersModule = angular.module('GlobalHelpersModule')
globalHelpersModule.directive "dateField", ['$filter', ($filter) ->
  require: "ngModel"
  link: (scope, element, attrs, ngModelController) ->
    ngModelController.$parsers.push (data) ->
      #View -> Model
      # check chrome's default format for the html5 date input field
      date = Date.parseExact(data, "yyyy-MM-dd")
      if date == null
        # if that doesn't exist, check the actual input
        date = Date.parseExact(data, "MM/dd/yyyy")


      # if the date field is not a valid date
      # then set a 'date' error flag by calling $setValidity
      ngModelController.$setValidity "date", date != null
      return $filter("date") date, "MM/dd/yyyy"

    ngModelController.$formatters.push (data) ->
      #Model -> View
      $filter("date") data, "MM/dd/yyyy"
]