window.globalHelpersModule.directive "timeGrid", 
['$rootScope', '$log', '$state', '$timeout', '$filter', 'SchedulingService', 'BreakpointService',
($rootScope, $log, $state, $timeout, $filter, SchedulingService, BreakpointService) ->
  restrict: "E"
  scope:
    headerText: '=',
    profileGuid: '=',
    facilityGuid: '=',
    ngModel: '='
  link: (scope, element, attrs) ->
    scope.selected = null
    scope.dayRangeLength = 5 # updated below via breakpoint events
    scope.dateRange = null
    scope.currentStartDate = moment()
    scope.currentBreakpoint = null # track to prevent extra api calls

    scope.showPreviousRangeBtn = ->
      moment() < scope.currentStartDate

    # build the date range objects and output the format the api expects
    scope.getDateRangeWithFormat = ->
      startDate = angular.copy(scope.currentStartDate)
      if startDate < moment() then startDate = moment()
      endDate = startDate.clone()
      endDate.add(scope.dayRangeLength, 'days').startOf('day')
      return [startDate.format('YYYYMMDDHHMM'), endDate.format('YYYYMMDDHHMM')]

    scope.getSchedule = (startDate, endDate) ->
      # get the provider's schedule
      # profileGuid, practiceGuid, facilityGuid, startDate, endDate
      SchedulingService.getSchedule(
        scope.profileGuid,
        $rootScope.DS.getCurrentPractice().practice_guid,
        scope.facilityGuid,
        startDate,
        endDate
      ).then (response) ->
        scope.week = response
        scope.dateRange = $filter('stringDatesToRangeForTimeGrid')(response, scope.dayRangeLength)

        # dynamically set height of columns in grid so border is drawn
        # timeout waits for page to draw and scroll height to be calculated
        $timeout ->
          timeGridBodyScrollHeight = element.find('.time-grid-body')[0].scrollHeight
          element.find('.times').height(timeGridBodyScrollHeight)
        , 0

    scope.getPreviousRange = ->
      scope.currentStartDate.startOf('day').subtract(scope.dayRangeLength, 'days')
      if scope.currentStartDate < moment() then scope.ccurrentStartDate = moment()
      dates = scope.getDateRangeWithFormat()
      scope.getSchedule(dates[0], dates[1])

    scope.getNextRange = ->
      scope.currentStartDate.startOf('day').add(scope.dayRangeLength, 'days')
      dates = scope.getDateRangeWithFormat()
      scope.getSchedule(dates[0], dates[1])

    scope.chooseDateTime = (event, day, time) ->
      scope.selected = [day, time]
      scope.ngModel = [day, time]

    scope.isActive = (day, time) ->
      _.isEqual scope.selected, [day, time]

    # use our enquire directive to update the days to show in the time grid based on app breakpoint
    scope.$on 'setup', (event, payload) ->
      scope.setDayRangeLength(payload)
    scope.$on 'match', (event, payload) ->
      scope.setDayRangeLength(payload)

    scope.setDayRangeLength = (payload) ->
      if payload is '$isMobile' or payload is 'xs'
        scope.dayRangeLength = 2
      else
        scope.dayRangeLength = 5

      # update time grid from 5 -> 2 or 2 -> 5 (if statement throttles extra calls to /available api)
      if payload isnt scope.currentBreakpoint and scope.currentBreakpoint isnt null
        dates = scope.getDateRangeWithFormat()
        scope.getSchedule(dates[0], dates[1])

      scope.currentBreakpoint = payload

    scope.init = ->
      dates = scope.getDateRangeWithFormat()
      scope.getSchedule(dates[0], dates[1])
      # make sure the selected state matches the model on load
      scope.selected = scope.ngModel
      # make sure to set dayRangeLength upon component load
      scope.setDayRangeLength(BreakpointService.getBreakpoint())

    # initialize the directive
    scope.init()

  template:
    """
      <div class="time-grid-wrapper" spinner>
        <div class="actions">
          <div class="provider-name">{{headerText}}</div>
          <div class="date-pagination" data-element="date-pagination">
            <a href="javascript:void(0)" ng-click="getPreviousRange()" ng-show="showPreviousRangeBtn()" data-element="previous-range-btn"><i class="fa fa-angle-left"></i></a>
            <div class="date-range" data-element="date-range">{{dateRange}}</div>
            <a href="javascript:void(0)" ng-click="getNextRange()" data-element="next-range-btn"><i class="fa fa-angle-right"></i></a>
          </div>
        </div>
        <div class="time-grid" data-time="{{selected}}" role="grid">
          <div class="time-grid-header five-cols" role="row" ng-if="week">
            <div class="col-sm-1 day"
              role="rowheader"
              ng-repeat="day in week | limitTo:dayRangeLength">
              {{::day.CalendarDate | dayOfTheWeekLocal:'includeDate'}}
            </div>
          </div>
          <div class="time-grid-body five-cols" role="row">
            <div class="times col-sm-1"
              role="gridcell"
              ng-repeat="day in week | limitTo:dayRangeLength">
              <a href="javascript:void(0)"
                ng-if="day.IsOpen"
                ng-class="{'is-active': isActive(day.CalendarDate, time)}"
                ng-click="chooseDateTime($event, day.CalendarDate, time)"
                ng-repeat="time in day.CalendarTimes"
                data-element="appointment-time">{{::time | timeGridTime}}</a>
              <a href="javascript:void(0)" class="is-closed" ng-if="!day.IsOpen">Closed</a>
            </div>
          </div>
        </div>
      </div>
    """
]