window.portalModule.factory 'NavigationService', ($rootScope, $log, FEATURE_BITS) ->
  getNavigationStructure: ->
    canAccessPatientData = $rootScope.DS.canAccessPatientData
    return [
      {
        name: 'Home'
        value: 'tasks.active'
        icon: 'icon-grid'
        root: true
        parent: false
        isActive: true
        isVisible: true
      },
      {
        name: 'Health Records'
        value: 'health-records'
        icon: 'icon-vitals'
        root: true
        parent: true
        isOpen: false
        isActive: false
        isVisible: true
        children: this.getHealthRecordChildren()
      },
      this.getAppointmentsNavigationNode(canAccessPatientData),
      {
        name: 'Messages'
        value: 'messages'
        icon: 'icon-mail'
        root: true
        parent: false
        hidden: true
        isActive: false
        isVisible: false
      },
      {
        name: 'Providers'
        value: 'providers'
        icon: 'icon-cross'
        root: true
        parent: false
        isActive: false
        isVisible: canAccessPatientData
      },
      {
        name: 'Profile'
        value: 'profile'
        icon: 'icon-person'
        root: true
        parent: false
        isActive: false
        isVisible: canAccessPatientData
      },
      {
        name: 'Account settings'
        value: 'account-settings'
        icon: 'icon-settings'
        root: true
        parent: false
        isActive: false
        isVisible: true
      },
      {
        name: 'Log out'
        value: 'log-out'
        icon: 'no-icon'
        root: true
        parent: false
        isActive: false
        isLogout: true
        isVisible: true
      }
    ]

  getCorporateLinks: ->
    return [
      {
        name: 'Help'
        value: 'help'
        icon: ''
        url: 'https://help.patientfusion.com/s/'
      },
      {
        name: 'Terms'
        value: 'terms'
        icon: ''
        url: 'https://patientfusion.com/legal/terms'
      },
      {
        name: 'Privacy'
        value: 'privacy'
        icon: ''
        url: 'https://patientfusion.com/legal/privacy'
      }
    ]

  getHealthRecordChildren: ->
    tabs = [{
      name: if FEATURE_BITS.isHealthRecordsOverviewOn then 'Labs & Imaging' else 'Lab test results'
      value: 'labs'
      icon: ''
      root: false
      parent: false
      isActive: false
      isVisible: true
    },
    {
      name: 'Diagnoses'
      value: 'diagnoses'
      icon: ''
      root: false
      parent: false
      isActive: false
      isVisible: true
    },
    {
      name: 'Medications'
      value: 'medications'
      icon: ''
      root: false
      parent: false
      isActive: false
      isVisible: true
    },
    {
      name: 'Immunizations'
      value: 'immunizations'
      icon: ''
      root: false
      parent: false
      isActive: false
      isVisible: true
    },
    {
      name: 'Allergies'
      value: 'allergies'
      icon: ''
      root: false
      parent: false
      isActive: false
      isVisible: true
    },
    {
      name: 'Procedures'
      value: 'procedures'
      icon: ''
      root: false
      parent: false
      isActive: false
      isVisible: true
    },
    {
      name: 'Care plans'
      value: 'care-plans'
      icon: ''
      root: false
      parent: false
      isActive: false
      isVisible: true
    },
    {
      name: 'Past visits'
      value: 'past-visits'
      icon: ''
      root: false
      parent: false
      isActive: false
      isVisible: true
    },
    {
      name: 'Vitals'
      value: 'vitals'
      icon: ''
      root: false
      parent: false
      isActive: false
      isVisible: true
    },
    {
      name: 'Social history'
      value: 'social-history'
      icon: ''
      root: false
      parent: false
      isActive: false
      isVisible: true
    }]

    if FEATURE_BITS.isHealthRecordsOverviewOn
         tabs.unshift {
          name: 'Overview'
          value: 'overview'
          icon: ''
          root: false
          parent: false
          isActive: false
          isVisible: true
        }
    return tabs

  getAppointmentsNavigationNode: (canAccessPatientData) ->
    # setup appointment nav based on feature bit
    if FEATURE_BITS.biDirectionalAppointments
      appointments = {
        name: 'Appointments'
        value: 'appointments.upcoming'
        icon: 'icon-calendar-day'
        root: true
        parent: false
        isOpen: false
        isActive: false
        hasUpdates: true
        isVisible: canAccessPatientData
      }
    else
      appointments = {
        name: 'Appointments'
        value: 'appointments'
        icon: 'icon-calendar-day'
        root: true
        parent: true
        isOpen: false
        isActive: false
        isVisible: canAccessPatientData
        children: [
          {
            name: 'Upcoming'
            value: 'appointments-upcoming'
            icon: ''
            root: false
            parent: false
            isActive: false
            isVisible: true
          },
          {
            name: 'Past'
            value: 'appointments-past'
            icon: ''
            root: false
            parent: false
            isActive: false
            isVisible: true
          }
        ]
      }

    return appointments

  deactivateSelected: (navigation) ->
    for item in navigation
      item.isActive = false
      item.isOpen = false
      $log.log item.isActive
      if item.children?
        for child in item.children
          child.isActive = false
