window.portalModule.controller 'UpcomingAppointmentsController', ($scope, $state, $stateParams, $log, FeedItemService) ->
  $log = $log.getInstance('UpcomingAppointmentsController')
  $log.log 'upcoming appointments controller loaded.'
  $scope.hasDownloadAndTransmitLinks = false
  $scope.customPageHeader = 'Appointments'
  $scope.filter = 'upcoming'

  # listen for an event to refresh the feed items (reload the data)
  $scope.$on 'fetch-feed-items', (event, payload) ->
    $log.log event
    $scope.init()

  $scope.init = ->
    FeedItemService.upcoming().then (response) ->
      $scope.appointmentFeed = response.data
      $log.log '$scope.appointments', $scope.appointmentFeed

    FeedItemService.getStates('upcoming').then (response) ->
      $scope.states = response

  $scope.init()