window.globalHelpersModule.directive "auditr", 
['$rootScope', '$log', '$timeout', '$state', 'AuditrService', 'ModalService', 'PhrPatientService', 
($rootScope, $log, $timeout, $state, AuditrService, ModalService, PhrPatientService) ->
  restrict: "E"
  link: (scope, element, attrs) ->

  template:
    """
      <div class="col-sm-3 hidden-xs" data-element="overview-activity-viewer" ng-if="$root.DS.canAccessPatientData">
        <a id="tracking-data"
          href="javascript:void(0);"
          class="pull-right"
          ui-sref="portal.activity"
          style="padding: 15px; margin-top: 5px;">View all activity &raquo;</a>
      </div>

      <div class="col-sm-12 visible-xs" data-element="overview-activity-viewer" ng-if="$root.DS.canAccessPatientData">
        <a class="btn orange01 col-sm-12 full-width" 
          href="javascript:void(0);" 
          ui-sref="portal.activity">View all activity &raquo;</a>
      </div>
    """
]
