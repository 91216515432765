window.globalHelpersModule.directive 'healthRecordsCard', ->
  restrict: 'E'
  replace: true
  link: (scope, element, attrs) ->
    scope.isExpanded = false
    scope.showSeeMoreOrLess = scope.items.length > 5

    setVisibleItems = ->
      scope.visibleItems = if scope.isExpanded then scope.items else scope.items.slice(0, 5)
    scope.toggleIsExpanded = ->
      scope.isExpanded = !scope.isExpanded
      setVisibleItems()

    setVisibleItems()

  scope:
    cardTitle: '='
    items: '='
    isLabsCard: '='
    isCarePlanCard: '='
    patientName: '='
    patientRecordNumber: '='

  templateUrl: 'portal/health-records/health-records-card/health-records-card-directive-template.html'
