# this simple service allows us to query for the currently active breakpoint of our responsive app
appointmentsModule = angular.module('GlobalHelpersModule').factory 'BreakpointService', ($log) ->

  # alias could be: xs, sm, md, lg or any over breakpoint grid prefix from Bootstrap 3
  isBreakpoint: (alias) ->
    return $('#detect-breakpoints .device-' + alias).is(':visible')

  # returns xs, sm, md, or lg
  getBreakpoint: ->
    currentBreakpoint = undefined
    $visibleElement = $('#detect-breakpoints .breakpoint:visible')
    breakpointStringsArray = [['device-xs', 'xs'], ['device-sm', 'sm'], ['device-md', 'md'], ['device-lg', 'lg']]
    _.each breakpointStringsArray, (breakpoint) ->
      if $visibleElement.hasClass(breakpoint[0])
        currentBreakpoint = breakpoint[1]
    return currentBreakpoint

  # determine the character count to truncate at for specific breakpoints
  truncateAt: () ->
    currentBreakpoint = this.getBreakpoint()
    characters = 28 if currentBreakpoint == 'xs'
    characters = 36 if currentBreakpoint == 'sm'
    characters = 40 if currentBreakpoint == 'md'
    characters = 48 if currentBreakpoint == 'lg'
    return characters