export function expireAuthToken() {
    const date = new Date('01/01/2000');
    document.cookie = `${window.gon.config.PHR_AUTH_TOKEN_KEY}=;expires=${date.toUTCString()};path=/;`;
}

export function extractAuthToken() {
    const authTokenPart = document.cookie.split(';').find(segment => (
        segment.indexOf(window.gon.config.PHR_AUTH_TOKEN_KEY) > -1
    ));

    const [key, authToken] = (authTokenPart || '').split('=');

    return authToken;
}

export function setAuthCookie(token) {
    document.cookie = `phr_auth_token=${token}`;
}