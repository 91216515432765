window.portalModule.directive "recordSelector", 
['$rootScope', '$log', '$state', 'AuthService', ($rootScope, $log, $state, AuthService) ->
  restrict: "E"

  link: (scope, element, attrs) ->
    scope.$log = $log.getInstance('recordSelector directive')

    scope.switchRecord = (phrPatient, $index) ->
      # set a new phrPatientGuid in the gon object so that when DataStoreService re-initializes ($watch on currentPhrPatientIndex)
      # the app loads with the new patient record
      AuthService.setPhrPatientGuid(phrPatient.phrPatientGuid)
      # changing this fires off a watcher in DataStore
      $rootScope.DS.currentPhrPatientIndex = $index
      # reload the current route
      $state.reload()

  templateUrl: 'portal/navigation/record-selector/record-selector-directive-template.html'
]