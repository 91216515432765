import logo from '../../../images/base/logo-brandmark.svg';

window.portalModule.controller 'AccountSettingsController', ($scope, $state, $stateParams, $log, AuthService) ->
  $log = $log.getInstance('AccountSettingsController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'account-settings controller loaded.'
  $scope.logo = logo

  $scope.customPageHeader = "Settings"
  $scope.hasDownloadAndTransmitLinks = false
