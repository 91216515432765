window.portalModule.factory 'ImmunizationsService', ($rootScope, $log, Restangular, AuthService, CacheFactory) ->
  $log = $log.getInstance('ImmunizationsService')

  # create a cache
  immunizationsCache = CacheFactory('immunizationsCache', {
    deleteOnExpire: 'aggressive',
    cacheFlushInterval: 1800000, # this cache will check for expired items 30 minutes
    storageMode: 'memory'
  })

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().CLINICAL_ENDPOINT_URL)
    Configurer.setDefaultHttpFields({cache: immunizationsCache})

  _immunizationsService = restAngular.all("immunizations")

  findAll: ->
    return _immunizationsService.customGET('', {patient_practice_guid: $rootScope.DS.getCurrentPatientPracticeGuid()})
