window.portalModule.controller 'ConfirmedProviderProposedInfoModalController', ($rootScope, $scope, $state, $timeout, $log, $uibModalInstance, feedItem, ProposalService, toastr, InfoModalService) ->
  $log = $log.getInstance('ConfirmedProviderProposedInfoModalController')
  $log.log 'confirmed provider proposed info modal controller loaded.'

  # Variables
  # -----------------------------------

  $scope.headerText = "Doctor's office has suggested a change for your appointment"
  $scope.feedItem = feedItem.data
  $log.log 'feedItem', $scope.feedItem
  bypassTransitionAway = false

  # Setup
  # -----------------------------------
  # this service determines the default background state to load if a modal doesn't have one (page refresh)
  # in that case it also determines where to tranition to when the user closes the modal
  InfoModalService.setup($scope.feedItem)

  # Actions
  # -----------------------------------
  $scope.confirmProviderProposal = (feedItem) ->
    ProposalService.transitionRequest(feedItem, 'accept').then (response) ->
      toastr.success('You accepted the appointment change suggested from doctor\'s office. The appointment change is confirmed.')
      $rootScope.$broadcast 'fetch-feed-items'
      $scope.close()

  $scope.cancel = ->
    bypassTransitionAway = true
    $uibModalInstance.dismiss 'cancel'
    # We need to close ourselves before going to the cancel modal
    # so we dont stack modals and can route to the right route
    # This means bypassing the transitionAway method
    $state.go('portal.appointments.upcoming.cancel', {feedItemGuid:$scope.feedItem.appointment_request_guid, feedItemType:$scope.feedItem.feed_item_type, feedItemStateToken:feedItem.request_state_token})

  $scope.close = ->
    $log.log 'cancel called'
    $uibModalInstance.dismiss "cancel"

  # when you click outside the modal go to the parent state
  $uibModalInstance.result.finally (response) ->
    unless bypassTransitionAway
      InfoModalService.transitionAway()

  # Transistion away if the feedItem is in the incorrect state
  $uibModalInstance.rendered.finally (response) ->
    unless $scope.feedItem.record_state == 'confirmed_provider_proposed'
      $uibModalInstance.dismiss "cancel"