window.portalModule.factory 'PracticeService', ($rootScope, $log, Restangular, CacheFactory, AuthService) ->
  $log = $log.getInstance('PracticeService')

  # create a cache
  practiceCache = CacheFactory('practiceCache', {
    deleteOnExpire: 'aggressive',
    cacheFlushInterval: 1800000, # this cache will check for expired items 30 minutes
    storageMode: 'memory'
  })

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().PRACTICE_ENDPOINT_URL + '/api/v2')
    Configurer.setDefaultHttpFields({cache: practiceCache})

  _practiceService = restAngular.all("")

  # returns practices as a hash, keyed on practiceGuid
  getPractices: ->
    _practiceService.get("practices").then (response) ->
      _.keyBy response.data, (practice) ->
        practice.practice_guid.toLowerCase()