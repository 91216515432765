import logo from '../../../../images/base/logo-brandmark.svg';

window.portalModule.controller 'CarePlansController', ($scope, $state, $stateParams, $log, $filter, AuthService, CarePlansService, FEATURE_BITS) ->
  $scope.logo = logo
  $log = $log.getInstance('CarePlansController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'care-plans controller loaded.'
  $scope.isHealthRecordsOverviewOn = FEATURE_BITS.isHealthRecordsOverviewOn

  CarePlansService.findAll().then (response) ->
    $scope.carePlans = response.data
    $log.log '$scope.carePlans', $scope.carePlans
