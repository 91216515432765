globalHelpersModule = angular.module('GlobalHelpersModule')
globalHelpersModule.directive 'spinner', ['$rootScope', ($rootScope) ->
  restrict: 'A'
  link: (scope, element, attrs) ->
    $rootScope.$watch('loading', ->
      # add 'insertedSpinner' to element scope if you're inserting and
      # removing the spinner yourself (default: looks at global $rootScope.loading variable)
      if not scope.insertedSpinner
        if $rootScope.loading
          scope.insertSpin()
        else
          scope.removeSpin()
    )

    scope.insertSpin = ->
      element.addClass('loading')
      element.find('.scrim').remove()
      element.prepend('<div class="scrim"></div>')


    scope.removeSpin = ->
      element.removeClass('loading')
      element.find('.scrim').remove()
]