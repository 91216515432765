window.globalHelpersModule.directive "taskFilter", 
['$rootScope', '$log', '$timeout', '$filter', '$state', 'TasksService',
($rootScope, $log, $timeout, $filter, $state, TasksService) ->
  restrict: "E"
  scope:
    filterState: '@'
  link: (scope, element, attrs) ->

    TasksService.count().then (response) ->
      scope.taskCount = response.data

    scope.getBtnText = (state) ->
      if state is 'ACTIVE' then return 'All active tasks'
      if state is 'COMPLETED' then return 'Completed'
      if state is 'IGNORED' then return 'Ignored'

    scope.btnText = scope.getBtnText(scope.filterState)

    scope.$on 'update-task-filter-count', ->
      TasksService.count().then (response) -> scope.taskCount = response.data

  templateUrl: 'portal/tasks/task-filter/task-filter-directive-template.html'
]