window.rootModule = angular.module('RootModule', []);
window.rootModule.controller 'RootController', ($scope, $location, $state, $log, AuthService) ->
  $log = $log.getInstance('RootController')

  environment = AuthService.getEnvironment()

  if environment == 'development' && !AuthService.hasValidAuthToken()
    token = AuthService.getConfigData().phrAuthToken
    if token
      AuthService.setAuthToken(token)
    else
      auth0Response = $location.search()

      if auth0Response?.access_token?
        AuthService.setAuthToken(auth0Response.access_token);
        AuthService.initializeAuthenticatedSession();

      $state.go('dev_landing_page', {})
  else
    route = AuthService.getRouteState()
    params = AuthService.getRouteParams()

    if route
      $state.go(route, params)
    else if AuthService.getDefaultRouteEnabled()
      $state.go('portal.tasks.active', {})