import logo from '../../../images/base/logo-brandmark.svg';

window.portalModule.controller 'ProfileController', ($rootScope, $scope, $state, $stateParams, $log, AuthService, PhrPatientService, InsurancePlansService) ->
  $log = $log.getInstance('ProfileController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'profile controller loaded.'
  $scope.logo = logo

  $scope.hasDownloadAndTransmitLinks = false
  $scope.phrPatient = $rootScope.DS.getCurrentPhrPatient()

  PhrPatientService.get($scope.phrPatient.phrPatientGuid).then (response) ->
    $scope.phrPatient = response.data
    $log.log '$scope.phrPatient', $scope.phrPatient
    $scope.customPageHeader = $scope.phrPatient.firstName + ' ' + $scope.phrPatient.lastName + '\'s Profile'

  # set custom page header title
  if $scope.phrPatient.firstName is "" or $scope.phrPatient.lastName is ""
    $scope.customPageHeader = "Patient Profile"
  else
    $scope.customPageHeader = $scope.phrPatient.firstName + ' ' + $scope.phrPatient.lastName + '\'\s Profile'

  InsurancePlansService.find().then (response) ->
    $scope.insurances = response
    $log.log '$scope.insurances', $scope.insurances