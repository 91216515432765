import logo from '../../../images/base/logo-brandmark.svg';

window.portalModule.controller 'MessagesController', ($scope, $filter, $state, $stateParams, $log, AuthService, ModalService, ProvidersService, MessageService, toastr) ->
  $log = $log.getInstance('MessagesController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'messages controller loaded.'

  $scope.init = ->
    $scope.logo = logo
    $scope.customPageHeader = "Messages"
    $scope.hasDownloadAndTransmitLinks = false
    $scope.newMessageBody = ''
    $scope.newMessageSubject = ''
    $scope.stateName = $state.current.name
    $scope.reply = {}
    $scope.loading = false
    $scope.isMessagingEnabled = MessageService.isMessagingEnabled()

    ProvidersService.getProvidersForCurrentPractice("all").then (providers) ->
      $scope.providers = providers
      $log.log '$scope.providers', $scope.providers
      $scope.providersHigherEditLevel = _.values(providers).filter (provider) -> provider.ehr_edit_level > 1
      $log.log '$scope.providersHigherEditLevel', $scope.providersHigherEditLevel
      $scope.refreshThreads()

  $scope.refreshThreads = ->
    MessageService.getThreads().then (threads) ->
      providerGuids = Object.keys($scope.providers)
      $scope.threads = threads.data && threads.data.filter (thread) ->
        providerGuids.indexOf(thread.attributes.provider_profile_guid.toLowerCase()) >= 0
      $log.log '$scope.threads', $scope.threads
      if $scope.threads.length > 0
        $scope.selectedIndex = 0
        $scope.loadMessagesForThread($scope.threads[$scope.selectedIndex], $scope.selectedIndex)
      else
        $scope.currentThreadMessages = []

  $scope.launchNewMessageModal = ->
    modalOptions =
      headerText: "New Message"
      providers: $scope.providersHigherEditLevel
      selectedProvider: $scope.providersHigherEditLevel[0]
      messageBody: $scope.newMessageBody
      messageSubject: $scope.newMessageSubject
      cancelBtnText: "Discard"
      actionBtnText: "Send"

    ModalService.showModal({templateUrl: "portal/messages/messages-modal-template.html"}, modalOptions).then (result) ->
      # "ok" is a string passed in by clicking the ok button in the modal HTML
      if result.result == "ok"
        # the result is formatted like: {result:'ok', provider:modalOptions.selectedProvider, messageBody:modalOptions.messageBody, messageSubject: modalOptions.messageSubject}
        providerProfileGuid = result.provider.profile_guid
        subject = result.messageSubject
        if subject
          subject = subject.trim()
        if result.attachedFile
          message =
            providerProfileGuid: providerProfileGuid
            practiceGuid: $scope.DS.getCurrentPractice().practice_guid
            messageBody: result.messageBody
            messageSubject: subject
            linkUrl: result.linkUrl
            attachedFile: result.attachedFile
          MessageService.sendMessageWithAttachment(message).then ->
            toastr.success('Message sent')
          .catch ->
            toastr.warning('Failed to send message')
        else
          MessageService.createThread(result.provider, result.messageBody, subject, result.linkUrl).then (thread) ->
            $scope.refreshThreads()

  $scope.loadMessagesForThread = (thread, index) ->
    $scope.selectedIndex = index
    $scope.currentThreadMessages = []
    $scope.discardReplyMessage()
    MessageService.getMessagesForThread(thread.id).then (messages) ->
      currentThreadMessages = messages.data
      previousSubject = ''
      reRx = /RE:\s?/
      currentThreadMessages.forEach((message) ->
        if message.attributes.attached_links && message.attributes.attached_links.length > 0
          message.attached_links = message.attributes.attached_links.map((link) ->
            return {
              url: link,
              urlWithProtocol: if link.indexOf('http') == 0 then link else 'http://' + link
            }
          )

        currentSubject = message.attributes.subject
        if currentSubject && currentSubject != previousSubject && currentSubject.replace(reRx, '') != previousSubject
          previousSubject = currentSubject
          message.uniqueSubject = currentSubject
      )
      $scope.currentThreadMessages = messages.data

  $scope.openMessageThread = (thread, index) ->
    $scope.isMessageView = true
    $scope.loadMessagesForThread(thread, index)

  $scope.closeMessageThread = ->
    $scope.isMessageView = false

  $scope.discardReplyMessage = ->
    $scope.reply.body = ''
    $scope.reply.linkUrl = null
    $scope.reply.attachedFile = null
    $scope.reply.error = null
    $scope.reply.successMessage = null

  $scope.sendReplyMessage = (isInvalid) ->
    $log.log $scope.threads[$scope.selectedIndex]
    $scope.reply.successMessage = null
    if isInvalid
      alert('Please enter a message.')
      return
    else
      providerProfileGuid = $scope.threads[$scope.selectedIndex].attributes.provider_profile_guid
      subject = $scope.currentThreadMessages[$scope.currentThreadMessages.length - 1].attributes.subject
      if $scope.reply.attachedFile
        message =
          providerProfileGuid: providerProfileGuid
          practiceGuid: $scope.DS.getCurrentPractice().practice_guid
          threadGuid: $scope.threads[$scope.selectedIndex].id
          messageBody: $scope.reply.body
          messageSubject: subject
          linkUrl: $scope.reply.linkUrl
          attachedFile: $scope.reply.attachedFile
        $scope.loading = true
        MessageService.sendMessageWithAttachment(message).then ->
          $scope.discardReplyMessage()
          $scope.reply.successMessage = 'Message sent'
        .catch ->
          toastr.warning('Failed to send message')
        .finally ->
          $scope.loading = false
      else
        MessageService.createMessage($scope.threads[$scope.selectedIndex].id, $scope.reply.body, subject, $scope.reply.linkUrl).then ->
          $scope.refreshThreads()
        $scope.discardReplyMessage()

  $scope.downloadFile = (file) ->
    MessageService.getFileUrl(file).then (fileUrl) ->
      modalOptions = { fileUrl }
      ModalService.showModal({templateUrl: 'portal/messages/messages-download-file-modal-template.html', windowClass: 'download-file-modal'}, modalOptions).catch -> {}
    .catch ->
      toastr.warning('Failed to download file')

  $scope.init()
