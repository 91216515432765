# original author: http://subashselvaraj.com/2014/09/28/angularjs-fallback-image-directive/
# additional functionality by: lstone
globalHelpersModule = angular.module('GlobalHelpersModule')
globalHelpersModule.directive "actualSrc", ->
  link: postLink = (scope, element, attrs) ->
    # load the fallback image if one doesn't load correctly
    # trigger the 'fallback-image-loaded' event so our app can trigger other functionality
    # as a result of a failed image load i.e. showing a link or info box
    attrs.$observe "actualSrc", (newVal, oldVal) ->
      if newVal?
        img = new Image()
        img.src = attrs.actualSrc

        angular.element(img).bind "load", ->
          element.attr "src", attrs.actualSrc

        angular.element(img).bind "error", ->
          scope.$emit('fallback-image-loaded', attrs.actualSrc)
          # note: may need to call scope.$apply() where you're listening for this event if you update scope objects