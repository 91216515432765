window.portalModule.factory 'PhrUserService', ($rootScope, $log, $timeout, Restangular, AuthService) ->
  $log = $log.getInstance('PhrUserService')

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().PHR_USER_ENDPOINT_URL)

  _phrUserService = restAngular.all("")

  get: ->
    return _phrUserService.get('users') # account settings

  getGrants: ->
    return _phrUserService.get('grants') # consents and agreements

  deleteGrant: (grantId) ->
    return _phrUserService.one("grants/" + grantId).remove()

  patch: (contact) ->
    return _phrUserService.one('contacts').patch({contacts:contact})

  patchAccountSettings: (contact) ->
    phrUserGuid = contact.phrUserGuid
    delete contact.phrUserGuid
    return _phrUserService.one('users', phrUserGuid).patch(contact)

  patchContactMobilePhone: (mobilePhone) ->
    contacts = {}
    contacts.mobilePhone = mobilePhone
    this.patch(contacts)