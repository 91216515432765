window.portalModule.directive "verifyAccessModal", 
['$window', 'ModalService', 'VerifyAccessService', 'PhrPatientService', 'PracticeService',
($window, ModalService, VerifyAccessService, PhrPatientService, PracticeService) ->
  restrict: "E"

  link: (scope, element, attrs) ->
    scope.$on 'tasks:launch_verify_access_modal', (event, task) ->
      scope.launchVerifyAccessModal(task)

    scope.launchVerifyAccessModal = (task) ->
      properties = task.properties
      modalOptions =
        patientName: "#{properties.patient_first_name} #{properties.patient_last_name}"
        enrollmentGuid: task.subject_guid
        smsPreference: null
        errorMessage: null
        isSubmitting: null
        phoneNumber: null
        accessCode: null
        dob: null
      modalDefaults =
        backdrop: 'static'
        keyboard: true
        modalFade: true
        controller: ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) ->
          $scope.insertedSpinner = true
          $scope.modalOptions = modalOptions
          $scope.close = (result) ->
            $uibModalInstance.dismiss 'cancel'

          $scope.continue = (nextState) ->
            if nextState
              $scope.currentState = nextState

          $scope.verifyInput = () ->
            dateRegex = /^\d{2}\/\d{2}\/\d{4}$/
            phoneRegex = /^\d{10}$/
            if $scope.modalOptions.dob and not $scope.modalOptions.dob.match dateRegex
              $scope.modalOptions.errorMessage = 'Date of birth must be in MM/DD/YYYY format.'
              $scope.modalOptions.isSubmitting = false
              return false
            if $scope.modalOptions.phoneNumber and not $scope.modalOptions.phoneNumber.match phoneRegex
              $scope.modalOptions.errorMessage = 'Phone number must be in XXXXXXXXXX format.'
              $scope.modalOptions.isSubmitting = false
              return false
            return true

          $scope.verifyAccessCode = () ->
            $scope.modalOptions.errorMessage = null
            $scope.modalOptions.isSubmitting = true
            if $scope.verifyInput()
              $scope.insertSpin()
              VerifyAccessService.verifyAccessCode($scope.modalOptions.enrollmentGuid, $scope.modalOptions.dob, $scope.modalOptions.accessCode).then () ->
                $window.location.reload()
              , (e) ->
                $scope.removeSpin()
                if e.data && e.data.errors[0] && e.data.errors[0].detail && e.data.errors[0].detail.code == "enrollment_request_has_expired"
                  $scope.modalOptions.errorMessage = 'The invitation to access your records has expired. Please contact your healthcare provider and request that they resend the invitation.'
                else
                  $scope.modalOptions.errorMessage = 'Verification failed. Information entered is incorrect.'
                $scope.modalOptions.isSubmitting = false

          $scope.requestSecurityCode = () ->
            $scope.modalOptions.isSubmitting = true
            $scope.modalOptions.errorMessage = null
            if $scope.verifyInput()
              VerifyAccessService.requestSecurityCode($scope.modalOptions.enrollmentGuid, $scope.modalOptions.dob, $scope.modalOptions.phoneNumber, $scope.modalOptions.smsPreference).then () ->
                $scope.modalOptions.isSubmitting = false
                $scope.currentState = 'input-security-code'
              , (e) ->
                $scope.modalOptions.errorMessage = 'Verification failed. Information entered is incorrect.'
                $scope.modalOptions.isSubmitting = false

          $scope.verifySecurityCode = () ->
            $scope.modalOptions.isSubmitting = true
            $scope.modalOptions.errorMessage = null
            $scope.insertSpin()
            VerifyAccessService.verifySecurityCode($scope.modalOptions.enrollmentGuid, $scope.modalOptions.securityCode).then () ->
              $window.location.reload()
            , (e) ->
              $scope.removeSpin()
              $scope.modalOptions.isSubmitting = false
              if e.data && e.data.errors[0] && e.data.errors[0].detail && e.data.errors[0].detail.code == "enrollment_request_has_expired"
                $scope.modalOptions.errorMessage = 'The invitation to access your records has expired. Please contact your healthcare provider and request that they resend the invitation.'
              else
                $scope.modalOptions.errorMessage = 'Security code is invalid'

          $scope.back = (prevState) ->
            $scope.modalOptions.dob = null
            $scope.modalOptions.accessCode = null
            $scope.modalOptions.phoneNumber = null
            $scope.modalOptions.smsPreference = null
            $scope.modalOptions.errorMessage = null
            $scope.currentState = prevState
        ]
        windowClass: 'verify-access-modal'
        templateUrl: 'portal/tasks/modals/verify-access-modal/verify-access-modal.html'

      ModalService.showModal(modalDefaults, modalOptions)
]