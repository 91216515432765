window.portalModule.factory 'AppointmentsService', ($rootScope, $log, Restangular, CacheFactory, AuthService) ->
  $log = $log.getInstance('AppointmentsService')

  # create a cache
  appointmentsCache = CacheFactory('appointmentsCache', {
    deleteOnExpire: 'aggressive',
    cacheFlushInterval: 1800000, # this cache will check for expired items 30 minutes
    storageMode: 'memory'
  })

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().APPOINTMENT_ENDPOINT_URL + '/api/v1')
    Configurer.setDefaultHttpFields({cache: appointmentsCache})

  _appointmentsService = restAngular.all("appointments")

  get: (action) ->
    return _appointmentsService.customGET(action, {id: $rootScope.DS.getCurrentPatientPracticeGuid()})

  # deprecating after bi-directional appt launch
  past: ->
    return _appointmentsService.customGET('past', {id: $rootScope.DS.getCurrentPatientPracticeGuid()})

  # deprecating after bi-directional appt launch
  upcoming: ->
    return _appointmentsService.customGET('upcoming', {id: $rootScope.DS.getCurrentPatientPracticeGuid()})

  getStates: (selectedState) ->
    states = [
      {name: 'upcoming', itemCount: '4', uiSref: 'portal.appointments.upcoming', selected: true},
      {name: 'past', itemCount: '4', uiSref: 'portal.appointments.past', selected: false},
      {name: 'canceled', itemCount: '4', uiSref: 'portal.appointments.canceled', selected: false}
    ]

    for state in states
      if state.name is selectedState
        state.selected = true
      else
        state.selected = false

    return states

#  appointment = {
#    "appointmentIntakes":{
#      "patientPracticeGuid":"41F2D9CC-1640-4954-926E-6BEB42336FA5",
#      "appointment":{
#        "startDateTimeUtc":"2014-12-05T19:00:00.000Z",
#        "endDateTimeUtc":"2014-12-05T19:30:00.000Z",
#        "appointmentType":"New Patient Visit",
#        "status":"Pending",
#        "patientPracticeGuid":"41F2D9CC-1640-4954-926E-6BEB42336FA5",
#        "practiceGuid":"2CA506D0-6F0B-47F1-B981-934B5B09DD88",
#        "practiceName":"Lawrence Stone Practice",
#        "providerProfileGuid":"988A9577-7AFB-467C-821A-FD22F5AA988D",
#        "providerFirstName":"Lawrence",
#        "providerLastName":"Stone",
#        "facilityGuid":"C4143B74-15D2-42C7-9404-FE111BEE6155",
#        "facilityName":"Lawrence Stone Practice",
#        "facilityAddress":{
#          "address1":"YOUR ADDRESS HERE",
#          "city":"YOUR CITY HERE",
#          "state":"CA",
#          "postalCode":"XXXXX",
#          "country":"United States of America"
#        },
#        "phrUserProfileGuid":"28969733-455C-4894-90B3-FF3981D386AE",
#        "scheduledEventGuid":"D6C4E70C-5FB9-48EF-920B-10403083D9D0"
#      },
#      "formResponse":{
#        "formResponseGuid":"2469c1a0-9378-4efe-a4d2-d76f86fe5788",
#        "phrUserGuid":"28969733-455C-4894-90B3-FF3981D386AE",
#        "patientPracticeGuid":"41F2D9CC-1640-4954-926E-6BEB42336FA5",
#        "appointmentGuid":"D6C4E70C-5FB9-48EF-920B-10403083D9D0",
#        "practiceGuid":"2CA506D0-6F0B-47F1-B981-934B5B09DD88",
#        "phrPatientGuid":"918C20C5-1F51-43FA-B7CF-D77A00048322",
#        "updatedAt":"2014-12-04T19:53:10.713+00:00",
#        "form":{
#          "id":"5a735e28-b4f5-409e-a18c-234a782c7114",
#          "title":"First Intake Form test on Intake Endpoint",
#          "providerGuid":"ec973239-17a6-4855-8167-ead2f37591ca",
#          "components":[
#            {
#              "id":"96b5a9a2-52ee-4493-9a20-0939bc53580f",
#              "component":"medications",
#              "editable":true,
#              "index":0,
#              "label":"Medications",
#              "description":"Current medications, add new",
#              "placeholder":"collects any updates to the current medications and allows to add new medication",
#              "required":false,
#              "validation":"/.*/"
#            },
#            {
#              "id":"4901f28f-2060-4b4d-b79a-0870b9abbfa1",
#              "component":"allergies",
#              "editable":true,
#              "index":1,
#              "label":"Allergies",
#              "description":"Current \u0026 past allergies",
#              "placeholder":"collects current \u0026 past allergies",
#              "required":false,
#              "validation":"/.*/"
#            },
#            {
#              "id":"e4cdeeb2-6db1-4fae-8893-6a53a12eeb0e",
#              "component":"symptoms",
#              "editable":true,
#              "index":2,
#              "label":"Symptoms",
#              "description":"Symptoms of patient",
#              "placeholder":"collects Symptoms of patient",
#              "required":false,
#              "validation":"/.*/"
#            },
#            {
#              "id":"bb669faf-3c22-4a5d-ad74-bd81476c7993",
#              "component":"race",
#              "editable":true,
#              "index":3,
#              "label":"Race",
#              "description":"Race",
#              "placeholder":"collects Race",
#              "required":false,
#              "validation":"/.*/"
#            },
#            {
#              "id":"ec5db314-bf39-42ce-9cfb-f18e877ce5bf",
#              "component":"ethnicity",
#              "editable":true,
#              "index":4,
#              "label":"Ethnicity",
#              "description":"Ethnicity",
#              "placeholder":"collects Ethnicity",
#              "required":false,
#              "validation":"/.*/"
#            },
#            {
#              "id":"7be309d0-7257-4e95-8a57-fc20db08015f",
#              "component":"preferredLanguage",
#              "editable":true,
#              "index":5,
#              "label":"Preferred Language",
#              "description":"Preferred language",
#              "placeholder":"collects Preferred language",
#              "required":false,
#              "validation":"/.*/"
#            },
#            {
#              "id":"6d256d52-3231-4a13-9989-2109352d6603",
#              "component":"insurance",
#              "editable":true,
#              "index":6,
#              "label":"Insurance",
#              "description":"Insurance company name, Insurance plan, Insurance ID, Effective date, Type",
#              "placeholder":"collects Insurance company name, Insurance plan, Insurance ID, Effective date, Type",
#              "required":false,
#              "validation":"/.*/"
#            },
#            {
#              "id":"55eafe45-8c01-4031-943c-2a272bd92620",
#              "component":"smokingStatus",
#              "editable":true,
#              "index":7,
#              "label":"Smoking status",
#              "description":"Smoking",
#              "placeholder":"collects Smoking information",
#              "required":false,
#              "validation":"/.*/"
#            },
#            {
#              "id":"702a0614-77be-4e79-a867-586122a4f2dc",
#              "component":"emergencyContactInfo",
#              "editable":true,
#              "index":8,
#              "label":"Emergency contact information",
#              "description":"Name, Relationship with patient, Address, Email, Phone",
#              "placeholder":"collects Street address, Zip, State, Country, Email, Phone",
#              "required":false,
#              "validation":"/.*/"
#            },
#            {
#              "id":"24d439f7-f6c3-4e7b-aec0-3887a8e887e6",
#              "component":"contactInfo",
#              "editable":true,
#              "index":9,
#              "label":"Patient contact information",
#              "description":"Address, Email, Phone",
#              "placeholder":"collects Name, Relationship, Street address, Zip, State, Country, Email, Phone",
#              "required":false,
#              "validation":"/.*/"
#            },
#            {
#              "id":"13d68b62-e360-4081-8681-b038284edd88",
#              "component":"personalInfo",
#              "editable":true,
#              "index":10,
#              "label":"Patient personal information",
#              "description":"Patient name, Date of birth, Sex",
#              "placeholder":"collects First name, Last name, Date of birth, gender",
#              "required":false,
#              "validation":"/.*/"
#            },
#            {
#              "id":"aa398027-cd1d-497d-9e61-ee7a57eedaed",
#              "component":"select",
#              "editable":true,
#              "index":11,
#              "label":"Dropdown",
#              "description":"",
#              "placeholder":"",
#              "options":[
#                "value one",
#                "value two"
#              ],
#              "required":false,
#              "validation":"/.*/"
#            },
#            {
#              "id":"4532a9eb-a0b2-4116-a696-dbe2122bdd8e",
#              "component":"radio",
#              "editable":true,
#              "index":12,
#              "label":"Radio button",
#              "description":"",
#              "placeholder":"",
#              "options":[
#                "value one",
#                "value two"
#              ],
#              "required":false,
#              "validation":"/.*/"
#            },
#            {
#              "id":"597a211c-244f-437a-b4c5-568442d9082d",
#              "component":"checkbox",
#              "editable":true,
#              "index":13,
#              "label":"Checkbox",
#              "description":"",
#              "placeholder":"",
#              "options":[
#                "value one",
#                "value two"
#              ],
#              "required":false,
#              "validation":"/.*/"
#            },
#            {
#              "id":"62533cf0-6182-4ba5-b576-69331af0c6e5",
#              "component":"textArea",
#              "editable":true,
#              "index":14,
#              "label":"Answer in multiple lines",
#              "description":"",
#              "placeholder":"",
#              "required":false,
#              "validation":"/.*/"
#            },
#            {
#              "id":"306eb287-8752-4f31-8f9d-282fad9f4657",
#              "component":"textInput",
#              "editable":true,
#              "index":15,
#              "label":"Answer in one line",
#              "description":"",
#              "placeholder":"",
#              "required":false,
#              "validation":"/.*/"
#            }
#          ]
#        },
#        "questionResponses":[
#
#        ]
#      },
#      "insurancePlans":[
#
#      ]
#    }
#  }




#
# window.portalModule.factory 'AppointmentsService', ($log) ->
#
#  find: (id) ->
#    if id is 'none'
#      return {}
#    return  {
#      appointmentType: "Follow-Up Visit"
#      displayPiLink: true
#      endDateTimeUtc: "2015-01-28T21:30:00+00:00"
#      facilityAddress:
#        address1: "YOUR ADDRESS HERE"
#        city: "YOUR CITY HERE"
#        country: "United States of America"
#        postalCode: "XXXXX"
#        state: "CA"
#      facilityGuid: "C4143B74-15D2-42C7-9404-FE111BEE6155"
#      facilityName: "Lawrence Stone Practice"
#      patientPracticeGuid: "41F2D9CC-1640-4954-926E-6BEB42336FA5"
#      phrUserProfileGuid: ""
#      practiceGuid: "2CA506D0-6F0B-47F1-B981-934B5B09DD88"
#      practiceName: "Lawrence Stone Practice"
#      providerFirstName: "Lawrence"
#      providerGuid: "EC973239-17A6-4855-8167-EAD2F37591CA"
#      providerLastName: "Stone"
#      providerProfileGuid: "988A9577-7AFB-467C-821A-FD22F5AA988D"
#      scheduledEventGuid: "EEE0BB5B-B43E-4F59-8BA9-9A552FD48738"
#      startDateTimeUtc: "2015-01-28T21:00:00+00:00"
#      status: "Pending"
#    }
#
#  upcoming: ->
#    upcomingAppointments = [
#      {
#        'scheduledEventGuid': '42FC42E9-A66A-4E96-AA71-4C6EB82B465D'
#        'startDateTimeUtc': '2015-02-06T00:00:00+00:00'
#        'endDateTimeUtc': '2015-02-06T01:00:00+00:00'
#        'appointmentType': 'ewrewfwef'
#        'status': 'InLobby'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'submittedAt': '2015-02-05T18:11:56+00:00'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': '2EFEE9FC-FE9B-4191-87BF-62E803B495D2'
#        'startDateTimeUtc': '2015-02-11T17:00:01+00:00'
#        'endDateTimeUtc': '2015-02-11T17:44:59+00:00'
#        'appointmentType': 'javascript:alert("Hello!");'
#        'status': 'InLobby'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'submittedAt': '2015-01-21T00:44:45+00:00'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': 'EDF640A3-8DE5-4136-B028-27C4359EBD28'
#        'startDateTimeUtc': '2016-01-13T17:00:01+00:00'
#        'endDateTimeUtc': '2016-01-13T17:59:59+00:00'
#        'appointmentType': 'Follow-Up Visit'
#        'status': 'Pending'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'submittedAt': '2015-01-22T21:56:23+00:00'
#        'displayPiLink': true
#      }
#    ]
#
#    return upcomingAppointments
#
#  past: ->
#    pastAppointments = [
#      {
#        'scheduledEventGuid': 'F47DBF77-D4DD-4F49-8E36-301EE17E6BE2'
#        'startDateTimeUtc': '2015-01-08T20:00:00+00:00'
#        'endDateTimeUtc': '2015-01-08T21:00:00+00:00'
#        'appointmentType': 'Follow-Up Visit'
#        'status': 'Pending'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': '71EDABA9-8D50-4B83-AC1C-C1C426B391F0'
#        'startDateTimeUtc': '2015-01-08T21:15:01+00:00'
#        'endDateTimeUtc': '2015-01-08T21:29:59+00:00'
#        'appointmentType': 'ewrewfwef'
#        'status': 'Pending'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': '31762544-D526-4EC8-B30C-677195810751'
#        'providerProfileGuid': '0F3D8EA8-8F1E-45BD-B497-763390B47859'
#        'providerFirstName': 'Francis'
#        'providerLastName': 'Toan'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': ''
#        'displayPiLink': false
#      }
#      {
#        'scheduledEventGuid': '7567BA2C-BD38-4C36-9BF6-0351CBB2728E'
#        'startDateTimeUtc': '2015-01-08T22:00:00+00:00'
#        'endDateTimeUtc': '2015-01-08T23:00:00+00:00'
#        'appointmentType': 'ewrewfwef'
#        'status': 'Pending'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'submittedAt': '2015-01-07T20:04:19+00:00'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': '519D7AB2-ED50-4F5A-AE06-EDA8E09C1E45'
#        'startDateTimeUtc': '2015-01-08T23:00:01+00:00'
#        'endDateTimeUtc': '2015-01-08T23:59:59+00:00'
#        'appointmentType': '111111111111'
#        'status': 'Pending'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'submittedAt': '2015-01-22T23:36:30+00:00'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': 'D82B9D9B-0EDE-41FF-9AA7-58821E00437A'
#        'startDateTimeUtc': '2015-01-09T00:00:00+00:00'
#        'endDateTimeUtc': '2015-01-09T01:00:00+00:00'
#        'appointmentType': 'Nursing Only'
#        'status': 'InLobby'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'submittedAt': '2015-01-07T23:42:11+00:00'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': 'C17E0488-2E7B-4D3A-B69A-1CDB01D8E3AB'
#        'startDateTimeUtc': '2015-01-09T00:00:01+00:00'
#        'endDateTimeUtc': '2015-01-09T00:44:59+00:00'
#        'appointmentType': 'javascript:alert("Hello!");'
#        'status': 'Pending'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': '99C4149A-98F2-4680-84A1-3DADFF9982AF'
#        'providerProfileGuid': 'C32B7CA7-3423-4915-95B5-B6FDCA57699E'
#        'providerFirstName': 'Homer-jon firstname'
#        'providerLastName': 'Simpson the second O\'Brian'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': ''
#        'displayPiLink': false
#      }
#      {
#        'scheduledEventGuid': '2BFC892A-45A4-4B8A-8D8A-E1D6D2A7E7E2'
#        'startDateTimeUtc': '2015-01-09T01:00:00+00:00'
#        'endDateTimeUtc': '2015-01-09T02:00:00+00:00'
#        'appointmentType': 'ewrewfwef'
#        'status': 'InLobby'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'submittedAt': '2015-01-08T23:04:37+00:00'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': 'BEFD31B5-42F2-4BBC-B5E4-F38B8A453DD8'
#        'startDateTimeUtc': '2015-01-09T21:00:00+00:00'
#        'endDateTimeUtc': '2015-01-09T22:00:00+00:00'
#        'appointmentType': 'ewrewfwef'
#        'status': 'InLobby'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'submittedAt': '2015-01-08T01:01:50+00:00'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': 'BD58D9B8-A68F-4C2D-AC56-9F57019687F9'
#        'startDateTimeUtc': '2015-01-13T19:00:00+00:00'
#        'endDateTimeUtc': '2015-01-13T19:30:00+00:00'
#        'appointmentType': 'New Patient Visit'
#        'status': 'InLobby'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'submittedAt': '2015-01-08T01:55:25+00:00'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': '9E6FFCFE-B78B-402A-BEF0-691A8CFD14CD'
#        'startDateTimeUtc': '2015-01-13T19:30:00+00:00'
#        'endDateTimeUtc': '2015-01-13T20:00:00+00:00'
#        'appointmentType': '<img src="http://bit.ly/WUxwqJ" />'
#        'status': 'InLobby'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': 'A1F761FB-4EDE-4097-A526-43A531A3EB2B'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': 'BA2DF261-86D3-40FD-9BD0-180011E93CF9'
#        'startDateTimeUtc': '2015-01-14T19:30:00+00:00'
#        'endDateTimeUtc': '2015-01-14T20:00:00+00:00'
#        'appointmentType': 'Tablet schedule type that is pretty long'
#        'status': 'InLobby'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'submittedAt': '2015-01-08T04:05:00+00:00'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': '2BF795F6-C9DD-4BD5-BA4D-02348FA95F4E'
#        'startDateTimeUtc': '2015-01-21T04:00:00+00:00'
#        'endDateTimeUtc': '2015-01-21T05:00:00+00:00'
#        'appointmentType': 'ewrewfwef'
#        'status': 'InLobby'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'submittedAt': '2015-01-21T01:01:44+00:00'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': 'EE62BD7E-A434-42D7-8474-ACA0C627CBC0'
#        'startDateTimeUtc': '2015-01-22T23:30:00+00:00'
#        'endDateTimeUtc': '2015-01-23T00:00:00+00:00'
#        'appointmentType': 'ewrewfwef'
#        'status': 'Pending'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'submittedAt': '2015-01-14T21:26:17+00:00'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': '99434E38-D50B-4B2E-8F74-CE54657A4B2F'
#        'startDateTimeUtc': '2015-01-28T20:30:00+00:00'
#        'endDateTimeUtc': '2015-01-28T21:00:00+00:00'
#        'appointmentType': 'ewrewfwef'
#        'status': 'Pending'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': '89315C5A-8FA8-47F2-9C47-53ECBC55385C'
#        'startDateTimeUtc': '2015-01-29T18:30:00+00:00'
#        'endDateTimeUtc': '2015-01-29T19:00:00+00:00'
#        'appointmentType': '<img src="http://bit.ly/WUxwqJ" />'
#        'status': 'Pending'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'submittedAt': '2015-01-23T18:05:28+00:00'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': 'BE262355-0EF8-410D-BD9A-BEBF5934A1D3'
#        'startDateTimeUtc': '2015-01-29T19:00:00+00:00'
#        'endDateTimeUtc': '2015-01-29T19:30:00+00:00'
#        'appointmentType': 'Follow-Up Visit'
#        'status': 'Pending'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': ''
#        'displayPiLink': false
#      }
#      {
#        'scheduledEventGuid': 'F24E91C1-E812-44FC-88EC-C4061AE891E5'
#        'startDateTimeUtc': '2015-01-29T20:30:00+00:00'
#        'endDateTimeUtc': '2015-01-29T21:30:00+00:00'
#        'appointmentType': 'sdfsdfefse'
#        'status': 'Pending'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'submittedAt': '2015-01-14T21:32:22+00:00'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': 'D9D45CA1-6530-468B-8546-3DC25B6127E4'
#        'startDateTimeUtc': '2015-01-29T21:30:00+00:00'
#        'endDateTimeUtc': '2015-01-29T22:00:00+00:00'
#        'appointmentType': 'ewrewfwef'
#        'status': 'Pending'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': '1C3A6CA1-7C88-4764-9D50-95F1BDE5ABEA'
#        'startDateTimeUtc': '2015-01-29T22:00:00+00:00'
#        'endDateTimeUtc': '2015-01-29T22:30:00+00:00'
#        'appointmentType': 'ewrewfwef'
#        'status': 'Pending'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': '319B26FE-4057-4B66-A5BA-B94FB1FD8A5C'
#        'startDateTimeUtc': '2015-01-30T08:00:00+00:00'
#        'endDateTimeUtc': '2015-01-30T09:00:00+00:00'
#        'appointmentType': 'ewrewfwef'
#        'status': 'Pending'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': '729817CD-3FFE-43D7-9EF3-BD8AB6C4514D'
#        'submittedAt': '2015-01-23T08:18:17+00:00'
#        'displayPiLink': true
#      }
#      {
#        'scheduledEventGuid': '37C99F5A-7550-43E0-9903-83B7A6225673'
#        'startDateTimeUtc': '2015-01-30T23:00:00+00:00'
#        'endDateTimeUtc': '2015-01-31T00:00:00+00:00'
#        'appointmentType': '111111111111'
#        'status': 'Pending'
#        'patientPracticeGuid': 'CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D'
#        'practiceGuid': 'C9387F45-BF07-4D9B-B2F1-F910E5478754'
#        'practiceName': 'The Best Practice EVER'
#        'providerGuid': 'E28CD84D-3598-4FA3-8343-CF9A91BC38C0'
#        'providerProfileGuid': '94CD9067-296D-4ACB-A9ED-76F71164F5DC'
#        'providerFirstName': 'erik'
#        'providerLastName': 'McPoppin'
#        'facilityGuid': 'F4C271EF-8396-41A6-9649-0F1CA52120C6'
#        'facilityName': '@501Folsom'
#        'facilityAddress':
#          'address1': '499 Folsom Street'
#          'address2': ''
#          'address3': ''
#          'city': 'San Francisco'
#          'state': 'CA'
#          'postalCode': '94105'
#          'country': 'United States of America'
#        'phrUserProfileGuid': ''
#        'displayPiLink': false
#      }
#    ]
#
#    return pastAppointments