window.tasksModule.factory 'VerifyAccessService', ($log, Restangular, AuthService) ->
  $log = $log.getInstance('VerifyAccessService')

  restAngular = Restangular.withConfig (Configurer) ->
    Configurer.setBaseUrl(AuthService.getConfigData().PHR_USER_ENDPOINT_URL_v1)

  _verifyAccessService = restAngular.all("")

  verifyAccessCode: (enrollmentGuid, dob, code) ->
    body = {
      access_code: code,
      birth_date: dob
    }
    return _verifyAccessService.one('enrollments', enrollmentGuid).all("access_code").customPUT(body);

  requestSecurityCode: (enrollmentGuid, dob, phone, preference) ->
    body = {
      sms_call_preference: preference,
      phone_number: phone
      birth_date: dob
    }
    return _verifyAccessService.one('enrollments', enrollmentGuid).all("send_security_code").customPOST(body);

  verifySecurityCode: (enrollmentGuid, securityCode) ->
    return _verifyAccessService.one('enrollments', enrollmentGuid).all("security_code").customPUT({ security_code: securityCode });
