# this provides in memory storage of data between page refreshes
window.portalModule.factory 'DataStore', ($rootScope, $log, AuthService, CacheFactory, PhrPatientService, PhrUserService, UtilityService, FEATURE_BITS) ->
  $log = $log.getInstance('DataStore')
  $rootScope.DS = null

  # initialized within the portal abstract controller.
  # that $scope is passed in here along with the phrPatients resolved in the routes
  init: ($scope, phrPatients, patientPractices, practices, user) ->
    # first, see if we need to explicitly set a phrPatientGuid to load up the app with
    patientData = []
    canAccessPatientData = phrPatients.data.length
    if canAccessPatientData
        patientData = phrPatients.data
        index = _.findIndex(patientData, { phrPatientGuid: AuthService.getPhrPatientGuid() })
        if index isnt -1
          $scope.currentPhrPatientIndex = index
        else
          # set the current phrPatient by detecting 'Self' relationship
          for phr_patient, i in patientData
            if phr_patient.relationshipToUser is 'Self'
              $scope.currentPhrPatientIndex = i
              break

          # if 'Self' is not available choose the first one
          if $scope.currentPhrPatientIndex == undefined
            $scope.currentPhrPatientIndex = 0
    # lets create a root scope node for important app-wide data: DS = DataStore
    $rootScope.DS = {
      phrPatients: patientData
      patientPractices: patientPractices
      practices: practices
      user: user.users
      currentPhrPatientIndex: $scope.currentPhrPatientIndex
      canAccessPatientData: canAccessPatientData

      # we continually poll for changes to the phr patients' (isPpraEnabled)
      # only called when polling phr_patients returns patient data
      setPhrPatients: (updatedPhrPatients) ->
        @phrPatients = updatedPhrPatients

      getCurrentPhrPatient: ->
        if @canAccessPatientData
            return @phrPatients[@currentPhrPatientIndex]
        return {}

      isCurrentPpraEnabled: ->
        # NOTE: This currently assumes a 1:1 phrPatient -> patientPractice
        if @canAccessPatientData
            return @getCurrentPhrPatient().patientPractices[0].isPpraEnabled
        return false

      getCurrentPractice: ->
        # NOTE: This currently assumes a 1:1 phrPatient -> patientPractice
        if @canAccessPatientData
            practiceGuid = @getCurrentPhrPatient().patientPractices[0].practiceGuid
            return @getPracticeByGuid(practiceGuid)
        return {}

      getCurrentPatientPracticeGuid: ->
        # NOTE: This currently assumes a 1:1 phrPatient -> patientPractice
        if @canAccessPatientData
            return @getCurrentPhrPatient().patientPractices[0].patientPracticeGuid
        else null

      getCurrentPatientPractice: ->
        if @canAccessPatientData
            currentPatientPracticeGuid = @getCurrentPatientPracticeGuid()
            indexedRecords = _.keyBy @patientPractices, (patientPracticeRecord) ->
              patientPracticeRecord.patientPracticeGuid.toLowerCase()

            currentPatientPracticeRecordArray = _.filter indexedRecords, (value, key) ->
              UtilityService.compareGuids(key, currentPatientPracticeGuid)
              return currentPatientPracticeRecordArray[0]
        return []

      getPatientPractices: ->
        if @canAccessPatientData
            return _.keyBy @patientPractices, (patientPractice) ->
              patientPractice.patientPracticeGuid.toLowerCase()
        return []

      getPracticeByGuid: (practiceGuid) ->
        # removes need for brittle .toLowercase() on practiceGuid
        practiceGuidMatch = _.filter _.keys(@practices), (practiceKey) -> UtilityService.compareGuids(practiceKey, practiceGuid)
        return @practices[practiceGuidMatch]

      getProviderByProfileGuid: (profileGuid) ->
        return

      getPhrPatients: ->
        return @phrPatients

      getFeatureBitValue: (bit) ->
        return FEATURE_BITS[bit]
    }

    $log.log '$rootScope.DS', $rootScope.DS
    $scope.hasPatientRecordAuthorization = $rootScope.DS.isCurrentPpraEnabled()

    # when the currentPhrPatientIndex changes we need to update the application
    $scope.$watch 'DS.currentPhrPatientIndex', (newValue, oldValue) ->
      if newValue != oldValue
        $log.log '$rootScope.DS.currentPhrPatientIndex changed!', 'from', oldValue, 'to', newValue
        $log.log 'clearing all caches'
        $log.log 'loading new patient record'
        $scope.hasPatientRecordAuthorization = $rootScope.DS.isCurrentPpraEnabled()
        CacheFactory.clearAll()

    $log.log '$scope.hasPatientRecordAuthorization', $scope.hasPatientRecordAuthorization
