window.phr.config((RestangularProvider, $httpProvider, logExProvider, toastrConfig, $sceDelegateProvider, $stickyStateProvider) ->
  # enable ui-router stickyState logging (parallel states)
  # $stickyStateProvider.enableDebug(true)

  # Custom logging is handled via the logEx angular package: https://github.com/lwhiteley/AngularLogExtender/wiki/01.-Intro
  # logExProvider.disableDefaultColors(true)
  # logExProvider.overrideLogMethodColors({error: 'color:red;', info: 'color:purple;', log: 'color:blue;'})
  # logExProvider.restrictLogMethods(['log', 'info'])
  isLoggingEnabled = gon.env isnt 'production'

  logExProvider.enableLogging(isLoggingEnabled)
  logExProvider.useDefaultLogPrefix(false)
  logExProvider.overrideLogPrefix (className) ->
    $injector = angular.injector(["ng"])
    $filter = $injector.get("$filter")
    separator = " -> "
    # format = "MMMM-dd-yyyy-h:mm:ssa"
    format = "h:mm:ssa"
    now = $filter("date")(new Date(), format)
    "" + now + ((if not angular.isString(className) then "" else "::" + className)) + separator

  # configuring the site toasts (notifications that show up and fade away)
  angular.extend toastrConfig,
    allowHtml: false
    autoDismiss: true
    closeButton: true
    closeHtml: '<button>&times;</button>'
    containerId: 'toast-container'
    extendedTimeOut: 1000
    iconClasses:
      error: 'toast-error'
      info: 'toast-info'
      success: 'toast-success'
      warning: 'toast-warning'
    maxOpened: 0
    messageClass: 'toast-message'
    newestOnTop: true
    onHidden: null
    onShown: null
    positionClass: 'toast-center'
    preventDuplicates: false
    progressBar: false
    tapToDismiss: true
    target: 'body'
    templates:
      toast: 'directives/toast/toast.html'
      progressbar: 'directives/progressbar/progressbar.html'
    timeOut: 5000
    titleClass: 'toast-title'
    toastClass: 'toast'

#  RestangularProvider.addRequestInterceptor (data, operation, what) ->
#    return data
#
#  RestangularProvider.addResponseInterceptor (data, operation, what) ->
#    return data

)