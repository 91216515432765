window.portalModule.controller 'RescheduleModalController', ($rootScope, $scope, $state, $stateParams, $log, $uibModalInstance, feedItem, toastr, RescheduleService) ->
  $log = $log.getInstance('RescheduleModalController')
  $log.log 'reschedule modal controller loaded.'

  # Variables
  # -----------------------------------

  $scope.headerText = "Request to reschedule appointment"
  $scope.rescheduleRequest = {}
  $scope.feedItem = feedItem.data
  $log.log 'feedItem', $scope.feedItem

  $scope.disclaimer = $scope.feedItem.getDetails()?.disclaimer
  $scope.rescheduleRequest.scheduledEventGuid = $scope.feedItem.scheduled_event_guid # add the scheduledEventGuid to rescheduleRequest object
  $scope.appointmentDateTimeUtc = if $scope.feedItem.isCreationRequest() then $scope.feedItem.information.requested_start_date_time else $scope.feedItem.start_date_time
  $scope.rescheduleBtnDisabled = false

  # Actions
  # -----------------------------------

  $scope.reschedule = (rescheduleRequest) ->
    $scope.rescheduleBtnDisabled = true
    $log.log 'rescheduleRequest', rescheduleRequest

    if $scope.feedItem.isPending() && !$scope.feedItem.isCancellationRequest()
      $log.log 'Update the appointment request'
      RescheduleService.putRequest($scope.feedItem, rescheduleRequest).then (response) ->
        updateUiAndClose()
      , (response) ->
        handleError(response)
        
    else if $scope.feedItem.isProviderProposed()
      $log.log 'Update the provider proposed appointment request'
      RescheduleService.updateProviderProposed($scope.feedItem, rescheduleRequest).then (response) ->
        updateUiAndClose()
      , (response) ->
        handleError(response)

    else
      $log.log 'Update the confirmed appointment'
      RescheduleService.postAppointment($scope.feedItem, rescheduleRequest).then (response) ->
        updateUiAndClose()
      , (response) ->
        handleError(response)

  updateUiAndClose = ->
    $scope.rescheduleBtnDisabled = false
    $rootScope.$broadcast 'fetch-feed-items'
    toastr.success('Your appointment reschedule request has been sent to the doctor\'s office.')
    $scope.close()

  handleError = (response) ->
    $scope.rescheduleBtnDisabled = false
    toastr.error('There was an error with your request. Please try again.')

  $scope.close = ->
    $log.log 'cancel called'
    $uibModalInstance.dismiss "cancel"
    # go to the parent state on close
    $state.go('^')

  # when you click outside the modal go to the parent state
  $uibModalInstance.result.finally (response) ->
    $state.go('^')

  # Ensure the request is in the same state when opening the modal
  $uibModalInstance.rendered.finally (response) ->
    if !_.isEmpty($stateParams.feedItemStateToken) && $scope.feedItem.request_state_token != $stateParams.feedItemStateToken
      $rootScope.$broadcast 'fetch-feed-items'
      toastr.warning('This appointment cannot be rescheduled as it has changed. Your appointments are out of sync. Syncing the appointments...')
      $scope.close()
