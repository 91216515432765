window.phr.config(
  ['$stateProvider', '$urlRouterProvider', '$locationProvider', 'FEATURE_BITS',
  ($stateProvider, $urlRouterProvider, $locationProvider, FEATURE_BITS) ->
    # NOTE: make sure ALL routes end with a '/' forward slash:
    # https://github.com/angular-ui/ui-router/wiki/Frequently-Asked-Questions#how-to-make-a-trailing-slash-optional-for-all-routes

    # Root - Auth takes place here
    # -----------------------------------
    $stateProvider.state('root', {
      url: '/',
      templateUrl: 'root/index.html'
      controller: 'RootController'
      data: {
        pageTitle: ''
      }
    })

    $stateProvider.state('dev_landing_page', {
      url:'/dev_landing_page'
      templateUrl: 'landing-page/index.html'
      data: {
        pageTitle: 'Dev Landing'
      }
    })

    $stateProvider.state('register', {
      url: '/register/:enrollmentGuid'
      controller: 'EnrollmentRegistrationController'
      resolve: {
        enrollmentGuid: ['$stateParams', ($stateParams) -> return $stateParams.enrollmentGuid]
      }
    })

    $stateProvider.state('enrollment_task', {
      url: '/register/task/:enrollmentGuid'
      controller: 'EnrollmentTaskController'
      resolve: {
        enrollmentGuid: ['$stateParams', ($stateParams) -> return $stateParams.enrollmentGuid]
      }
    })

    # Portal
    # -----------------------------------
    $stateProvider.state('portal', {
      abstract: true
      sticky: true
      url: '/portal/'
      data: {
        pageTitle: 'Patient Portal'
      }
      templateUrl: 'portal/index.html' # navigation lives in here
      controller: 'PortalController'
      resolve: {
        PhrPatientService: 'PhrPatientService'
        phrPatients: (PhrPatientService) ->
          return PhrPatientService.getPhrPatients()
        patientPractices: (PhrPatientService) ->
          return PhrPatientService.getPatientPractices()
        PracticeService: 'PracticeService'
        practices: (PracticeService) ->
          return PracticeService.getPractices()
        PhrUserService: 'PhrUserService'
        user: (PhrUserService) ->
          return PhrUserService.get()
      }

    # home
    }).state('portal.tasks', {
      abstract: true
      url: 'tasks/'
      templateUrl: 'portal/tasks/index.html'
      controller: 'TasksController'
      params: { phr_patient_guid: undefined }

    }).state('portal.tasks.active', {
      url: 'active'
      templateUrl: 'portal/tasks/states/active/index.html'
      controller: 'TasksActiveController'

    }).state('portal.tasks.completed', {
      url: 'completed'
      templateUrl: 'portal/tasks/states/completed/index.html'
      controller: 'TasksCompletedController'

    }).state('portal.tasks.ignored', {
      url: 'ignored'
      templateUrl: 'portal/tasks/states/ignored/index.html'
      controller: 'TasksIgnoredController'

    }).state('portal.oab-task', {
      url: 'tasks/:task_guid/'
      templateUrl: 'portal/tasks/tasks/t-oab-information/index.html'
      controller: 'OABDetailsController'

    # overview
    }).state('portal.overview', {
      url: 'overview/'
      templateUrl: 'portal/health-records/overview/index.html'
      controller: 'OverviewController'

    # labs
    }).state('portal.labs', {
      url: 'labs/'
      templateUrl: 'portal/health-records/labs/index.html'
      controller: 'LabsController'

    # diagnoses
    }).state('portal.diagnoses', {
      url: 'diagnoses/'
      templateUrl: 'portal/health-records/diagnoses/index.html'
      controller: 'DiagnosesController'

    # medications
    }).state('portal.medications', {
      url: 'medications/'
      templateUrl: 'portal/health-records/medications/index.html'
      controller: 'MedicationsController'

    # immunications
    }).state('portal.immunizations', {
      url: 'immunizations/'
      templateUrl: 'portal/health-records/immunizations/index.html'
      controller: 'ImmunizationsController'

    # allergies
    }).state('portal.allergies', {
      url: 'allergies/'
      templateUrl: 'portal/health-records/allergies/index.html'
      controller: 'AllergiesController'

    # procedures
    }).state('portal.procedures', {
      url: 'procedures/'
      templateUrl: 'portal/health-records/procedures/index.html'
      controller: 'ProceduresController'

    # care-plans
    }).state('portal.care-plans', {
      url: 'care-plans/'
      templateUrl: 'portal/health-records/care-plans/index.html'
      controller: 'CarePlansController'

    # past-visits
    }).state('portal.past-visits', {
      url: 'past-visits/'
      templateUrl: 'portal/health-records/past-visits/index.html'
      controller: 'PastVisitsController'

    # vitals
    }).state('portal.vitals', {
      url: 'vitals/'
      templateUrl: 'portal/health-records/vitals/index.html'
      controller: 'VitalsController'

    # social-history
    }).state('portal.social-history', {
      url: 'social-history/'
      templateUrl: 'portal/health-records/social-history/index.html'
      controller: 'SocialHistoryController'
    })
    # v1 appointments-upcoming
    $stateProvider.state('portal.appointments-upcoming', {
      url: 'appointments/upcoming/'
      templateUrl: 'portal/appointments/v1/upcoming/index.html'
      controller: 'DeprecatedUpcomingAppointmentsController'

    # v1 appointments-past
    }).state('portal.appointments-past', {
      url: 'appointments/past/'
      templateUrl: 'portal/appointments/v1/past/index.html'
      controller: 'DeprecatedPastAppointmentsController'
    })

    # bi-directional upcoming appointments
    $stateProvider.state('portal.appointments', {
      url: 'appointments/'
      abstract: true
      templateUrl: 'portal/appointments/index.html'
      controller: 'AppointmentsController'

      # bi-directional upcoming appointments
      }).state('portal.appointments.upcoming', {
        url: 'filter/upcoming/'
        templateUrl: 'portal/appointments/states/index.html'
        controller: 'UpcomingAppointmentsController'

      # bi-directional appointment booking modal wrapper
      }).state('portal.appointments.upcoming.book', {
        abstract: true
        url: 'book/'
        onEnter: (ModalService) ->
          ModalService.showModal({
            controller: 'BookAppointmentModalController'
            templateUrl: "portal/appointments/modals/book-appointment/book-appointment-modal.html"
          })

        # booking modal view 1: choose provider
        }).state('portal.appointments.upcoming.book.provider', {
          url: 'provider/'
          views:
            'Book@':
              templateUrl: "portal/appointments/modals/book-appointment/_choose-provider.html"
              controller: 'ChooseProviderController'

        # booking modal view 2: choose time
        }).state('portal.appointments.upcoming.book.datetime', {
          url: 'provider/:profileGuid/facility/:facilityGuid/date-time/'
          views:
            'Book@':
              templateUrl: "portal/appointments/modals/book-appointment/_choose-date-time.html"
              controller: 'ChooseDateTimeController'

        # booking modal view 3: submit request for appointment
        }).state('portal.appointments.upcoming.book.request', {
          url: 'provider/:profileGuid/facility/:facilityGuid/date-time/request'
          views:
            'Book@':
              templateUrl: "portal/appointments/modals/book-appointment/_submit-request.html"
              controller: 'SubmitAppointmentRequestController'

      # bi-directional appointments reschedule
      }).state('portal.appointments.upcoming.reschedule', {
        url: ':feedItemGuid/:feedItemType/reschedule/?feedItemStateToken'
        onEnter: (ModalService, $stateParams) ->
          ModalService.showModal({
            controller: 'RescheduleModalController'
            templateUrl: "portal/appointments/modals/reschedule/reschedule-modal.html"
            resolve: {
              feedItem: ['FeedItemService', (FeedItemService) ->
                return FeedItemService.get($stateParams.feedItemGuid, $stateParams.feedItemType)
              ]
            }
          })

      # bi-directional appointments cancel
      }).state('portal.appointments.upcoming.cancel', {
        url: ':feedItemGuid/:feedItemType/cancel/?feedItemStateToken'
        onEnter: (ModalService, $stateParams) ->
          ModalService.showModal({
            controller: 'CancelModalController'
            templateUrl: "portal/appointments/modals/cancel/cancel-modal.html"
            resolve: {
              feedItem: ['FeedItemService', (FeedItemService) ->
                return FeedItemService.get($stateParams.feedItemGuid, $stateParams.feedItemType)
              ]
            }
          })

      # bi-directional past appointments
      }).state('portal.appointments.past', {
        url: 'filter/past/'
        templateUrl: 'portal/appointments/states/index.html'
        controller: 'PastAppointmentsController'

      # bi-directional canceled appointments
      }).state('portal.appointments.canceled', {
        url: 'filter/canceled/'
        templateUrl: 'portal/appointments/states/index.html'
        controller: 'CanceledAppointmentsController'
      })

    # messages
    $stateProvider.state('portal.messages', {
      url: 'messages/'
      templateUrl: 'portal/messages/index.html'
      controller: 'MessagesController'

    # providers
    }).state('portal.providers', {
      url: 'providers/'
      templateUrl: 'portal/providers/index.html'
      controller: 'ProvidersController'

    # profile
    }).state('portal.profile', {
      url: 'profile/'
      templateUrl: 'portal/profile/index.html'
      controller: 'ProfileController'

    # account-settings
    }).state('portal.account-settings', {
      url: 'account-settings/'
      templateUrl: 'portal/account-settings/index.html'
      controller: 'AccountSettingsController'

    # view all activity
    }).state('portal.activity', {
      url: 'activity/'
      templateUrl: 'portal/activity/index.html'
      controller: 'ActivityController'


    # Modals - omnipresent modals that can launch from any other state (parallel states)
    # -----------------------------------
    }).state('modals', {
      abstract: true
      url: '/modals/'
      data: {
        pageTitle: 'Patient Portal'
      }
      views:
        'modals':
          template: '<div ui-view=""></div>' # must provide a ui-view outlet to inject the children

    # bi-directional appointments confirmed reschedule requested info
    }).state('modals.confirmed_reschedule_requested-info', {
      url: ':feedItemGuid/:feedItemType/info/confirmed-reschedule-requested'
      onEnter: (ModalService, $stateParams, $state) ->
        ModalService.showModal({
          controller: 'ConfirmedRescheduleRequestedInfoModalController'
          templateUrl: "portal/appointments/feed-items/confirmed_reschedule_requested/confirmed_reschedule_requested-info-modal.html"
          resolve: {
            feedItem: ['FeedItemService', (FeedItemService) ->
              return FeedItemService.get($stateParams.feedItemGuid, $stateParams.feedItemType)
            ]
          }
        })

    # bi-directional appointments cancellation requested info
    }).state('modals.confirmed_cancellation_requested-info', {
      url: ':feedItemGuid/:feedItemType/info/confirmed-cancellation-requested'
      onEnter: (ModalService, $stateParams) ->
        ModalService.showModal({
          controller: 'ConfirmedCancellationRequestedInfoModalController'
          templateUrl: "portal/appointments/feed-items/confirmed_cancellation_requested/confirmed_cancellation_requested-info-modal.html"
          resolve: {
            feedItem: ['FeedItemService', (FeedItemService) ->
              return FeedItemService.get($stateParams.feedItemGuid, $stateParams.feedItemType)
            ]
          }
        })

    # bi-directional appointments reschedule denied info
    }).state('modals.confirmed_reschedule_denied-info', {
      url: ':feedItemGuid/:feedItemType/info/confirmed-reschedule-denied'
      onEnter: (ModalService, $stateParams) ->
        ModalService.showModal({
          controller: 'ConfirmedRescheduleDeniedInfoModalController'
          templateUrl: "portal/appointments/feed-items/confirmed_reschedule_denied/confirmed_reschedule_denied-info-modal.html"
          resolve: {
            feedItem: ['FeedItemService', (FeedItemService) ->
              return FeedItemService.get($stateParams.feedItemGuid, $stateParams.feedItemType)
            ]
          }
        })

    # bi-directional appointments reschedule approved info
    }).state('modals.confirmed_reschedule_approved-info', {
      url: ':feedItemGuid/:feedItemType/info/confirmed-reschedule-approved'
      onEnter: (ModalService, $stateParams) ->
        ModalService.showModal({
          controller: 'ConfirmedRescheduleApprovedInfoModalController'
          templateUrl: "portal/appointments/feed-items/confirmed_reschedule_approved/confirmed_reschedule_approved-info-modal.html"
          resolve: {
            feedItem: ['FeedItemService', (FeedItemService) ->
              return FeedItemService.get($stateParams.feedItemGuid, $stateParams.feedItemType)
            ]
          }
        })

    # bi-directional appointments cancellation denied info
    }).state('modals.confirmed_cancellation_denied-info', {
      url: ':feedItemGuid/:feedItemType/info/confirmed-cancellation-denied'
      onEnter: (ModalService, $stateParams) ->
        ModalService.showModal({
          controller: 'ConfirmedCancellationDeniedInfoModalController'
          templateUrl: "portal/appointments/feed-items/confirmed_cancellation_denied/confirmed_cancellation_denied-info-modal.html"
          resolve: {
            feedItem: ['FeedItemService', (FeedItemService) ->
              return FeedItemService.get($stateParams.feedItemGuid, $stateParams.feedItemType)
            ]
          }
        })

    # bi-directional upcoming confirmed provider proposed info
    }).state('modals.confirmed_provider_proposed-info', {
      url: ':feedItemGuid/:feedItemType/info/confirmed-confirmed_provider_proposed'
      onEnter: (ModalService, $stateParams) ->
        ModalService.showModal({
          controller: 'ConfirmedProviderProposedInfoModalController'
          templateUrl: "portal/appointments/feed-items/confirmed_provider_proposed/confirmed_provider_proposed-info-modal.html"
          resolve: {
            feedItem: ['FeedItemService', (FeedItemService) ->
              return FeedItemService.get($stateParams.feedItemGuid, $stateParams.feedItemType)
            ]
          }
        })

    # bi-directional canceled appointments cancellation approved info
    }).state('modals.canceled_cancellation_approved-info', {
      url: ':feedItemGuid/:feedItemType/info/canceled-cancellation-approved'
      onEnter: (ModalService, $stateParams) ->
        ModalService.showModal({
          controller: 'CanceledCancellationApprovedInfoModalController'
          templateUrl: "portal/appointments/feed-items/canceled_cancellation_approved/canceled_cancellation_approved-info-modal.html"
          resolve: {
            feedItem: ['FeedItemService', (FeedItemService) ->
              return FeedItemService.get($stateParams.feedItemGuid, $stateParams.feedItemType)
            ]
          }
        })

    # bi-directional upcoming appointment creation_requested_denied info
    }).state('modals.creation_requested_denied-info', {
      url: ':feedItemGuid/:feedItemType/info/creation-requested-denied'
      onEnter: (ModalService, $stateParams) ->
        ModalService.showModal({
          controller: 'CreationDeniedInfoModalController'
          templateUrl: "portal/appointments/feed-items/creation_denied/creation_denied-info-modal.html"
          resolve: {
            feedItem: ['FeedItemService', (FeedItemService) ->
              return FeedItemService.get($stateParams.feedItemGuid, $stateParams.feedItemType)
            ]
          }
        })
    })

    # For any unmatched url, redirect to /home
    $urlRouterProvider.otherwise('/')

    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false
    })

    # https://github.com/angular-ui/ui-router/wiki/Frequently-Asked-Questions#how-to-make-a-trailing-slash-optional-for-all-routes
    # creates a rule on $urlRouterProvider that maps all urls that are missing a
    # trailing slash to the same url but with the trailing slash appended.
    $urlRouterProvider.rule ($injector, $location) ->
      path = $location.url()

      # check to see if the path already has a slash where it should be
      if (path[path.length - 1] == '/' || path.indexOf('/?') > -1)
        return

      if (path.indexOf('?') > -1)
        return path.replace('?', '/?')

      if (path.indexOf('/access_token') > -1)
        return path.replace('/access_token', '/?access_token')

      return path + '/'
])
