window.portalModule.factory 'LabsService', ($rootScope, $log, Restangular, AuthService, CacheFactory) ->
  $log = $log.getInstance('LabsService')

  # create a cache
  labsCache = CacheFactory('labsCache', {
    deleteOnExpire: 'aggressive',
    cacheFlushInterval: 1800000, # this cache will check for expired items 30 minutes
    storageMode: 'memory'
  })

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().CLINICAL_ENDPOINT_URL)
    Configurer.setDefaultHttpFields({cache: labsCache})

  _labsService = restAngular.all("lab_results")

  findAll: ->
    return _labsService.customGET('', {patient_practice_guid: $rootScope.DS.getCurrentPatientPracticeGuid()})