window.portalModule.factory 'FeedItemService', ($rootScope, $log, $state, Restangular, AuthService, CacheFactory, FeedItem) ->
  $log = $log.getInstance('FeedItemService')

  # Configuration
  # -----------------------------------

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().APPOINTMENT_ENDPOINT_URL + '/api/v2')
    Configurer.addResponseInterceptor (data, operation, what) ->
      if operation is 'get'
        if _.isArray(data.data)
          for item in data.data
            # extend the objects with the FeedItem factory
            angular.extend(item, FeedItem)
        else
          angular.extend(data.data, FeedItem)

        return data
      return data

    Configurer.setErrorInterceptor (response, deferred, responseHandler) ->
      if response.status == 404
        # Bail out and go to the upcoming tab
        $state.go('portal.appointments.upcoming')
      return response # make sure to return response or calls will fail

  _feedItemService = restAngular.all("feed_items")

  # Service calls
  # -----------------------------------

  # GET feed item count and states for dropdown in feed items index view
  getStates: (selectedState) ->
    _feedItemService.customGET('count', {id: $rootScope.DS.getCurrentPatientPracticeGuid()}).then (response) ->
      data = response.data
      states = [
        {name: 'upcoming', itemCount: data.upcoming, uiSref: 'portal.appointments.upcoming', selected: true},
        {name: 'past', itemCount: data.past, uiSref: 'portal.appointments.past', selected: false},
        {name: 'canceled', itemCount: data.canceled, uiSref: 'portal.appointments.canceled', selected: false}
      ]

      for state in states
        state.selected = state.name is selectedState ? true : false
      return states

  # GET all upcoming feed items
  upcoming: ->
    return _feedItemService.customGET('upcoming', {id: $rootScope.DS.getCurrentPatientPracticeGuid()})

  # GET all past feed items
  past: ->
    return _feedItemService.customGET('past', {id: $rootScope.DS.getCurrentPatientPracticeGuid()})

  # GET all canceled feed items
  canceled: ->
    return _feedItemService.customGET('canceled', {id: $rootScope.DS.getCurrentPatientPracticeGuid()})

  # GET one feed item
  get: (feedItemGuid, feedItemType) ->
    if !_.isEmpty(feedItemType)
      return _feedItemService.one('', feedItemGuid).customGET('', {feed_type: feedItemType})
