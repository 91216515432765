window.portalModule.factory 'CarePlansService', ($rootScope, $log, Restangular, AuthService, CacheFactory) ->
  $log = $log.getInstance('CarePlansService')

  # create a cache
  carePlansCache = CacheFactory('carePlansCache', {
    deleteOnExpire: 'aggressive',
    cacheFlushInterval: 1800000, # this cache will check for expired items 30 minutes
    storageMode: 'memory'
  })

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().CLINICAL_ENDPOINT_URL)
    Configurer.setDefaultHttpFields({cache: carePlansCache})

  _carePlansService = restAngular.all("")

  findAll: ->
    return _carePlansService.customGET('careplans', {patient_practice_guid: $rootScope.DS.getCurrentPatientPracticeGuid()})