# our getAvailability API passes back time in string format (9:00:00 AM) which isn't what we want
# this filter formats time strings from that service to (9:00 am)
angular.module('GlobalHelpersModule').filter "timeGridTime", ->
  (time) ->
    if time?
      timeString = time.split(' ')[0]
      ampm = time.split(' ')[1]
      hours = timeString.split(':')[0]
      minutes = timeString.split(':')[1]

      return hours + ':' + minutes + ' ' + ampm.toLowerCase()
    else
      ""