angular.module('GlobalHelpersModule').filter "stringDatesToRangeForTimeGrid", ->
  (dateTimesRangeArray, dayRangeLength=5) ->

    if dateTimesRangeArray?
      # construct a js date object from the start date
      startDate = dateTimesRangeArray[0].CalendarDate
      startMonth = startDate.split('/')[0] - 1
      startDay = startDate.split('/')[1]
      startYear = startDate.split('/')[2]
      jsStartDate = new Date(startYear, startMonth, startDay)

      # construct a js date object from the end date (determined by dayRangeLength)
      endDate = dateTimesRangeArray[dayRangeLength - 1].CalendarDate
      endDay = endDate.split('/')[1]

      return moment(jsStartDate).format('MMM D') + ' - ' + endDay + ', ' + startYear
    else
      ""