window.globalHelpersModule.directive 'attachFile', ->
  restrict: 'E'
  replace: true
  link: (scope, element, attrs) ->
    scope.inputId = scope.idPrefix + '-attach-file-input'
    element.find('input[type="file"]').bind('change', (event) ->
      file = event.target.files[0]
      if !file
        return
      extension = file.name.substr(file.name.lastIndexOf('.') + 1)
      if extension
        extension = extension.toLowerCase()
      event.target.value = ''
      scope.$apply(->
        if scope.rawFileTypes.indexOf(extension) == -1
          scope.error({ error: { message: 'File type not allowed. Acceptable file types: ' + scope.rawFileTypes.join(', ') + '.' } })
        else if file.size > 1024 * 1024 * 25
          scope.error({ error: { message: 'The file exceeds the 25MB limit.', timeout: 2000 } })
        else
          scope.selectFile({ file: file })
      )
    )
    scope.rawFileTypes = ['xml', 'txt', 'gif', 'jpg', 'jpeg', 'png', 'pdf', 'm4a', 'wav', 'mp3', 'wma', 'aiff', 'mpf', 'mov']
    scope.accept = scope.rawFileTypes.map((type) -> '.' + type).join(', ')
    scope.click = ->
      if !scope.isAttachAllowed
        scope.error({ error: { message: 'Each message is limited to one file attachment.', timeout: 2000 } })

  scope: {
    selectFile: '&onSelectFile'
    error: '&onError'
    isAttachAllowed: '='
    idPrefix: '='
  }

  templateUrl: 'portal/messages/attach-file/attach-file-directive-template.html'
