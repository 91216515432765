window.portalModule.directive 'termsModal',
['$log', 'ModalService', 'AgreementsService', 'AgreementsOrderService',
($log, ModalService, AgreementsService, AgreementsOrderService) ->
  restrict: "E"
  replace: true
  template: '<div></div>'

  link: (scope, element, attrs) ->
    scope.$on 'agreements:launch_terms_modal', (event, payload) ->
      $log.log 'agreements:launch_terms_modal', event, payload
      scope.launchTermsModal(payload)

    scope.launchTermsModal = (payload) ->
      modalDefaults =
        backdrop: 'static'
        keyboard: true
        modalFade: true
        templateUrl: "portal/tasks/modals/terms-modal/terms-modal.html"

      modalOptions =
        vetoUrlText: 'See full agreement'
        vetoUrl: payload.url
        vetoSummaryUrl: payload.summaryUrl
        declineable: payload.agreement.declineable
        acceptBtnText: 'I Agree'
        declineBtnText: 'I Decline'

      ModalService.showModal(modalDefaults, modalOptions).then (result) ->
        if result is 'accept'
          AgreementsService.postUserTerms(payload.agreement, 'accept')
        else if result is 'declined'
          AgreementsService.postUserTerms(payload.agreement, 'declined')
]

