window.portalModule.directive "facility", 
['$rootScope', '$log', '$timeout', '$filter', '$state', 'AuthService',
($rootScope, $log, $timeout, $filter, $state, AuthService) ->
  restrict: "E"
  scope:
    facility: '='
  link: (scope, element, attrs) ->
    scope.getAddress = ->
      addressArray = [
        scope.facility?.address.address1,
        scope.facility?.address.address2,
        scope.facility?.address.address3,
        scope.facility?.address.city,
        scope.facility?.address.state

      ]
      return _.compact(addressArray).join(', ') + ' ' + scope.facility?.address?.zip

    scope.getPhone = ->
      return $filter('tel')(scope.facility?.phone_number)

  templateUrl: 'portal/appointments/feed-items/facility-address/facility-address-directive-template.html'
]