window.portalModule.factory 'ProvidersService', ($rootScope, $log, Restangular, CacheFactory, AuthService) ->
  $log = $log.getInstance('ProvidersService')

  # Configuration
  # -----------------------------------

  # create a cache
  providersCache = CacheFactory('providersCache', {
    deleteOnExpire: 'aggressive',
    cacheFlushInterval: 1800000, # this cache will check for expired items 30 minutes
    storageMode: 'memory'
  })

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().PRACTICE_ENDPOINT_URL + '/api/v2')
    Configurer.setDefaultHttpFields({cache: providersCache})

  _providersService = restAngular.all("providers")

  # Service calls
  # -----------------------------------

  getProvidersForCurrentPractice: (scope = "") ->
    params = {practice_guid: $rootScope.DS.getCurrentPractice().practice_guid}
    # by default, provider service returns active providers with higher edit level only i.e.ehr_edit_level > 1
    params.all = "1" if scope == "all" # requesting all active providers regardless of ehr_edit_level
    _providersService.customGET('', params).then (response) ->
      _.keyBy response.data, (provider) ->
        provider.profile_guid.toLowerCase()