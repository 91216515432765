angular.module('GlobalHelpersModule').filter "allergyOnset", ($filter) ->
  (allergy) ->
    if _.isEmpty allergy.onsetType
      if $filter('stringToJsDate')(allergy.adverseEventDate) != null
        ''
      else
        'Not entered'
    else if allergy.onsetType == "0"
      'Unknown'
    else if allergy.onsetType == "1"
      'Childhood'
    else if allergy.onsetType == "2"
      'Adulthood'