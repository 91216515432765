import logo from '../../../images/base/logo-brandmark.svg';

window.portalModule.controller 'ProvidersController', ($rootScope, $scope, $state, $stateParams, $log, AuthService, PracticeService, ProvidersService) ->
  $log = $log.getInstance('ProvidersController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'providers controller loaded.'
  $scope.customPageHeader = 'Providers'
  $scope.logo = logo

  $scope.practice = $rootScope.DS.getCurrentPractice()

  ProvidersService.getProvidersForCurrentPractice().then (providers) ->
    $scope.providers = providers
    $log.log '$scope.practice', $scope.practice
    $log.log '$scope.providers', $scope.providers

  $scope.hasDownloadAndTransmitLinks = false