angular.module('GlobalHelpersModule').filter "decodeHtmlEntities", ->
  (str) ->

    element = document.createElement('div')

    if str and typeof str == 'string'
      # ehr to phr messages are double encoded so we double decode here
      for i in [0..1]
        # strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '')
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '')
        element.innerHTML = str
        str = element.textContent
      element.textContent = ''
    return str
