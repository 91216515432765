window.globalHelpersModule.directive "immunization", 
['$rootScope', '$log', '$timeout', '$state',
($rootScope, $log, $timeout, $state) ->
  restrict: "E"

  link: (scope, element, attrs) ->
    scope.isOpen = true # is drawer open?

    # open or close the drawers based on IS_MOBILE
    scope.$watch 'IS_MOBILE', (newValue, oldValue) ->
      if newValue isnt undefined
        scope.isOpen = !newValue

    # enable / disable toggle buttons if IS_MOBILE
    scope.toggleDrawer = (test) ->
      if scope.IS_MOBILE
        scope.isOpen = !scope.isOpen
      else
        scope.isOpen = true

  templateUrl: 'portal/health-records/immunizations/immunization-directive-template.html'
]