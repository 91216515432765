# instantiate our application named 'phr' and include dependencies via injection
phrModules = [
  'ngFx',
  'ngAnimate',
  'log.ex.uo',
  'ui.router',
  'ct.ui.router.extras',
  'ngResource',
  'ngSanitize',
  'restangular',
  'angular-cache',
  'ui.bootstrap',
  'ui.utils',
  'templates',
  'timer',
  'toastr',
  'ngOptionsDisabled',
  'infinite-scroll',
  'angular-jwt',
  'zxcvbn',
  'GlobalHelpersModule',
  'RootModule',
  'PortalModule',
  'TasksModule',
]

if window.gon?.env == 'development'
  phrModules.push('LandingPageModule')

phr = angular.module('phr', phrModules)

# adding phr app to window so we can reference in other files for configuration and routing of application.
# have to do this because coffeescript wraps things in a closure automatically
window.phr = phr
