# model for Tasks
window.tasksModule.factory 'Task', ($log, $rootScope) ->
  COMPLETED = 'Completed'
  IGNORED = 'Ignored'
  STARTED = 'Started'
  NOT_STARTED = 'Not Started'

  # task states
  isNew: ->
    this.state is NOT_STARTED

  isActive: ->
    this.isNew() or this.isInProgress()

  isInProgress: ->
    this.state is STARTED

  isComplete: ->
    this.state is COMPLETED

  isIgnored: ->
    this.state is IGNORED

  isExpired: ->
    !_.isEmpty(this.expires_at) && moment().isAfter(this.expires_at);




