window.globalHelpersModule.directive 'labsCardItem', ->
  restrict: 'E'
  replace: true
  link: (scope, element, attrs) ->
    scope.orderDate = moment.utc(scope.labTest.orderDate).format('MM/DD/YYYY')
    scope.isExpanded = false
    scope.toggleIsExpanded = ->
      scope.isExpanded = !scope.isExpanded
    addr = scope.labTest.facilityAddress
    scope.noFacilityAddress = addr.name && !addr.streetAddress1 && !addr.streetAddress2 && !addr.city && !addr.stateCode && !addr.zipCode

  scope:
    labTest: '='
    patientName: '='
    patientRecordNumber: '='

  templateUrl: 'portal/health-records/labs-card-item/labs-card-item-directive-template.html'
