window.portalModule.directive "downloadPdfBtn", 
['$rootScope', '$log', '$sce', '$timeout', 'AuthService', 'ModalService', 'CCDAService', 'PastVisitsService',
($rootScope, $log, $sce, $timeout, AuthService, ModalService, CCDAService, PastVisitsService) ->
  restrict: "E"
  replace: true

  link: (scope, element, attrs) ->
    scope.recordTypes = CCDAService.getRecordTypes()
    PastVisitsService.findAll().then (response) ->
      scope.pastVisits = response

    scope.launchDownloadPDFModal = ->
      modalOptions =
        patientPracticeGuid: $rootScope.DS.getCurrentPatientPracticeGuid()
        formActionUrl: $sce.trustAsResourceUrl AuthService.getConfigData().PATIENT_RECORD_ENDPOINT_URL + "/patient_record"
        recordTypes: angular.copy scope.recordTypes
        headerText: "Download PDF Records"
        subHeaderText: "Select content for including in PDF"
        actionBtnText: "Download PDF Record"
        selectedRecordTypes: []
        pastVisits: scope.pastVisits
        selectedPastVisits: []

      ModalService.showModal({templateUrl: "portal/ccdas/download-pdf-btn/download-pdf-modal.html"}, modalOptions).then (result) ->
        # "ok" is a string passed in by clicking the ok button in the modal HTML
        console.log result
        # NOTE: request happens via regular form submission as its not possible to download a file via an AJAX request

#          {
#            patient_practice_guid: 'C40C4B72-081B-4D9D-8014-9A174CD11E6A'
#            vitals:1
#            medications:1
#            allergies:1
#            diagnoses:1
#            procedures:1
#            care_plans:1
#            immunizations:1
#            lab_results:1
#            'visit_ids[]':926231
#          }

  templateUrl: 'portal/ccdas/download-pdf-btn/download-pdf-btn-directive-template.html'
]