window.portalModule.factory 'PastVisitsService', ($rootScope, $log, Restangular, AuthService, CacheFactory) ->
  $log = $log.getInstance('PastVisitsService')

  # create a cache
  pastVisitsCache = CacheFactory('pastVisitsCache', {
    deleteOnExpire: 'aggressive',
    cacheFlushInterval: 1800000, # this cache will check for expired items 30 minutes
    storageMode: 'memory'
  })

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().CLINICAL_ENDPOINT_URL)
    Configurer.setDefaultHttpFields({cache: pastVisitsCache})
    Configurer.addResponseInterceptor (data, operation, what) ->
      if operation is 'get' and what is 'transcripts'
        newList = []
        for visit in data.data
          if visit.signedDate != null
            newList.push(visit)
        return newList
      else
        return data

  _pastVisitsService = restAngular.all("")

  findAll: ->
    return _pastVisitsService.customGET('transcripts', {patientPracticeGuid: $rootScope.DS.getCurrentPatientPracticeGuid()})

  getVisit: (transcriptGuid) ->
    return _pastVisitsService.one('visits', transcriptGuid).customGET('', {patientPracticeGuid: $rootScope.DS.getCurrentPatientPracticeGuid()})