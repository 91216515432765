window.portalModule.directive "skipToMainContent", ['$log', ($log) ->
  restrict: "E"

  link: (scope, element, attrs) ->
    scope.$log = $log.getInstance('skipToMainContent directive')

    scope.skipToMainContent = () ->
      angular.element('#page').focus()
      return true # <-- important to not return dom element (angular will complain)

  templateUrl: 'portal/navigation/skip-to-main-content/skip-to-main-content-directive-template.html'
]