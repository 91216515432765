window.portalModule.factory 'SchedulingService', ($rootScope, $log, Restangular, AuthService, CacheFactory) ->
  $log = $log.getInstance('SchedulingService')

  # Configuration
  # -----------------------------------
  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().SCHEDULE_AVAILABILITY_ENDPOINT_URL)

  _schedulingService = restAngular.all("schedule")

  # Service calls
  # -----------------------------------
  getSchedule: (providerGuid, practiceGuid, facilityGuid, startDateString, endDateString) ->
    $log.log 'getSchedule', providerGuid, practiceGuid, facilityGuid, startDateString, endDateString
    return _schedulingService
      .one('', providerGuid)
      .one('', practiceGuid)
      .one('', facilityGuid)
      .one('', startDateString)
      .one('', endDateString).customGET('available')

  # Helpers
  # -----------------------------------
  formatDateTime: (dateTime) ->
    if !_.isEmpty(dateTime) and _.isArray(dateTime)
      date = moment(dateTime.join(' '))
      return date.format('YYYY/MM/DD HH:mm')