angular.module('GlobalHelpersModule').filter "sanitizeSytemDate", ->
  (input) ->
    input = input or ""

    # sanitize system dates
    if moment(input) < moment("1902-01-01")
      input = null
    else if moment(input) > moment("9000-01-01")
      input = null
    else
      input