window.portalModule.factory 'CCDAV2Service', ($rootScope, $log, Restangular, AuthService) ->
  $log = $log.getInstance('CCDAV2Service')

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl("#{AuthService.getConfigData().CCDA_ENDPOINT_URL}/api/v2")

  _ccdasV2Service = restAngular.all("ccda_documents")

  transmit: (options, checkedComponents) ->
    dates = options.range
    request =
      components: checkedComponents.join(',')
      email_address: options.emailAddress
      is_encrypted: false
    if dates.start
      request.start_date = dates.start
    if dates.end
      request.end_date = dates.end
    if options.isEncrypted
      request.is_encrypted = options.isEncrypted
      request.password = options.password
    request.phr_patient_guid = $rootScope.DS.getCurrentPhrPatient().phrPatientGuid
    return _ccdasV2Service.customPOST(request, 'transmit')
