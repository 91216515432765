window.portalModule.factory 'PhrPatientService', ($rootScope, $log, $timeout, Restangular, AuthService, CacheFactory) ->
  $log = $log.getInstance('PhrPatientService')

  # Configuration
  # -----------------------------------

  timeout = null
  pollingMS = 60000 # every minute

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().PHR_PATIENT_ENDPOINT_URL)

  _phrPatientService = restAngular.all("")

  # Service calls
  # -----------------------------------

  # set up a polling method to update isPPRAEnabled
  $rootScope.pollAndUpdatePhrPatients = ->
    $timeout.cancel(timeout)
    timeout = $timeout ->
      # get new phrPatients and update the DataStoreService
      _phrPatientService.get('phr_patients').then (response) ->
        $log.log 'pollAndUpdatePhrPatients', response.data
        $rootScope.DS.setPhrPatients(response.data) unless _.isEmpty(response.data)
        $rootScope.pollAndUpdatePhrPatients()
    , pollingMS
    return

  getPhrPatients: ->
    $rootScope.pollAndUpdatePhrPatients()
    return _phrPatientService.get('phr_patients')

  getPatientPractices: ->
    _phrPatientService.get('patient_practices').then (response) ->
      _.keyBy response.data, (patientPractice) ->
        patientPractice.patientPracticeGuid.toLowerCase()

  get: (phrPatientGuid) ->
    return _phrPatientService.one('phr_patients', phrPatientGuid).get()

# example phr_patients response
#{
#   "data": [{
#    "firstName": "Fargo",
#    "lastName": "MacFargo",
#    "patientPractices": [
#        {
#            "isPpraEnabled": false,
#            "patientPracticeGuid": "7C3ED16D-B048-4C8D-B8AD-8306B240A7A0",
#            "practiceGuid": "FBF238A3-D39B-4AB8-9549-147666B4E92E"
#        }
#    ],
#    "phrPatientGuid": "73BAA0E7-56F5-496E-8E02-06AE7D1468DB"
#   }]
#}

# example phr_patients/:guid response
#{
#    "data": {
#        "dateOfBirth": "1954-04-14",
#        "ethnicityName": "Not Hispanic or Latino",
#        "firstName": "Fargo",
#        "languageName": null,
#        "lastName": "MacFargo",
#        "patientPractices": [
#            {
#                "patientPracticeGuid": "7C3ED16D-B048-4C8D-B8AD-8306B240A7A0",
#                "practiceGuid": "FBF238A3-D39B-4AB8-9549-147666B4E92E"
#            }
#        ],
#        "phrPatientGuid": "73BAA0E7-56F5-496E-8E02-06AE7D1468DB",
#        "raceNames": "White"
#    }
#}
