window.tasksModule.controller 'TasksCompletedController', ($scope, $state, $stateParams, $filter, $log, AuthService, TasksService, FEATURE_BITS) ->
  $log = $log.getInstance('TasksCompletedController', true, false, true)
  $log.log 'tasks completed controller loaded.'
  $scope.bidiEnabled = FEATURE_BITS.biDirectionalAppointments

  $scope.init = ->
    TasksService.findAll('completed').then (response) ->
      $scope.tasks = response.data
      $scope.showPlaceholderTask = if $scope.tasks.length is 0 then true else false

  # reload the tasks when one is updated
  $scope.$on 'task-updated', ->
    # tell the task-filter directive to update
    $scope.$broadcast 'update-task-filter-count'
    $scope.init()

  $scope.init()
