window.tasksModule.directive 'verifyAccessButton', 
[ '$window', '$rootScope', 'ModalService', 'VerifyAccessService', 'PhrPatientService', 'PracticeService', 
($window, $rootScope, ModalService, VerifyAccessService, PhrPatientService, PracticeService) ->
  restrict: 'E'
  replace: true
  scope:
    task : '='
  link: (scope, element, attrs) ->
    scope.launchModal = (task) ->
      $rootScope.$broadcast 'tasks:launch_verify_access_modal', task

  templateUrl: 'portal/tasks/tasks/t-verify-access/verify-access-button/verify-access-button-directive-template.html'
]
