window.portalModule.factory 'VitalsService', ($rootScope, $log, Restangular, AuthService, CacheFactory) ->
  $log = $log.getInstance('VitalsService')

  # create a cache
  vitalsCache = CacheFactory('vitalsCache', {
    deleteOnExpire: 'aggressive',
    cacheFlushInterval: 1800000, # this cache will check for expired items 30 minutes
    storageMode: 'memory'
  })

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().CLINICAL_ENDPOINT_URL)
    Configurer.setDefaultHttpFields({cache: vitalsCache})

  _vitalsService = restAngular.all("vitals")

  findAll: ->
    return _vitalsService.customGET('', {patient_practice_guid: $rootScope.DS.getCurrentPatientPracticeGuid()})
