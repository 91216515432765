window.portalModule.controller 'CancelModalController', ($rootScope, $scope, $state, $stateParams, $log, $uibModalInstance, feedItem, ProposalService, CancellationService, toastr) ->
  $log = $log.getInstance('CancelModalController')
  $log.log 'cancel modal controller loaded.'

  # Variables
  # -----------------------------------

  $scope.cancellationRequest = {}
  $scope.feedItem = feedItem.data
  $log.log 'feedItem', $scope.feedItem
  $scope.disclaimer = $scope.feedItem.getDetails()?.disclaimer
  $scope.cancellationRequest.scheduledEventGuid = $scope.feedItem.scheduled_event_guid # add the guids to cancellationRequest object
  $scope.appointmentDateTimeUtc = if $scope.feedItem.isCreationRequest() then $scope.feedItem.information.requested_start_date_time else $scope.feedItem.start_date_time
  $scope.cancelBtnDisabled = false

  # Actions
  # -----------------------------------

  $scope.cancel = (cancellationRequest) ->
    $scope.cancelBtnDisabled = true
    $log.log 'cancellationRequest', cancellationRequest

    # proposed appointment
    if $scope.feedItem.isProviderProposed()
      $log.log 'Decline the proposal and cancel the appointment'
      ProposalService.transitionRequest($scope.feedItem, 'reject').then (response) ->
        updateUiAndClose('You have declined the provider\'s proposal.')
      , (response) ->
        handleError(response)

    # appointment request
    else if $scope.feedItem.isCreationRequest()
      $log.log 'Cancel a pending appointment request'
      CancellationService.deleteRequest($scope.feedItem.appointment_request_guid).then (response) ->
        updateUiAndClose('Your appointment request has been canceled.')
      , (response) ->
        handleError(response)

    # confirmed appointment
    else
      $log.log 'Create a cancellation request'
      CancellationService.createCancellationRequest(cancellationRequest).then (response) ->
        updateUiAndClose('Your cancellation request has been sent to the doctor\'s office.')
      , (response) ->
        handleError(response)

  $scope.close = ->
    $log.log 'cancel called'
    $uibModalInstance.dismiss "cancel"

  # when you click outside the modal go to the parent state
  $uibModalInstance.result.finally (response) ->
    $state.go('^')

  updateUiAndClose = (toastMessage) ->
    $scope.cancelBtnDisabled = false
    $rootScope.$broadcast 'fetch-feed-items'
    toastr.success(toastMessage)
    $scope.close()

  # Ensure the request is in the same state when opening the modal
  $uibModalInstance.rendered.finally (response) ->
    if !_.isEmpty($stateParams.feedItemStateToken) && $scope.feedItem.request_state_token != $stateParams.feedItemStateToken
      $rootScope.$broadcast 'fetch-feed-items'
      toastr.warning('This appointment cannot be canceled as it has changed. Your appointments are out of sync. Syncing the appointments...')
      $scope.close()

  handleError = (response) ->
    $scope.cancelBtnDisabled = false
    toastr.error('There was an error with your request. Please try again.')
