window.portalModule.controller 'DeprecatedUpcomingAppointmentsController', ($scope, $state, $stateParams, $log, AuthService, AppointmentsService) ->
  $log = $log.getInstance('DeprecatedUpcomingAppointmentsController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'appointments-upcoming controller loaded.'

  $scope.customPageHeader = "Appointments"
  AppointmentsService.upcoming().then (response) ->
    $scope.upcoming = response.data
    $log.log '$scope.upcoming', response

  $scope.hasDownloadAndTransmitLinks = false
