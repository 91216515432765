window.globalHelpersModule.directive 'carePlansCardItem', ['$timeout', ($timeout) ->
  restrict: 'E'
  replace: true
  link: (scope, element, attrs) ->
    scope.isExpanded = false

    scope.toggleIsExpanded = ->
      if scope.showExpandArrow
        scope.isExpanded = !scope.isExpanded

    scope.setArrowDisplay = ->
      element = angular.element(".care-plan-card-preview p")[scope.index]
      if element
        scope.showExpandArrow = element.offsetHeight > 40

    $timeout(scope.setArrowDisplay, 0);

  scope:
    carePlan: '='
    index: '='

  templateUrl: 'portal/health-records/care-plans-card-item/care-plans-card-item-directive-template.html'
]