window.portalModule.factory 'MessageService', ($rootScope, $log, $timeout, Restangular, AuthService, $q) ->
  $log = $log.getInstance('MessageService')

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().MESSAGE_ENDPOINT_URL)

  _messageService = restAngular.all("")

  getThreads: ->
    return _messageService.get('threads')

  getMessagesForThread: (threadGuid) ->
    return _messageService.one('threads', threadGuid).customGET('messages')

  createThread: (provider, messageBody, messageSubject, linkUrl) ->
    subject = if messageSubject?.length then messageSubject else null
    attributes = {
      'provider_profile_guid': provider.profile_guid,
      'body_text': messageBody,
      'subject': subject,
      'patient_practice_guid' : $rootScope.DS.getCurrentPatientPracticeGuid()
    }
    if linkUrl
      attributes.attached_links = [linkUrl]
    request = {
      data: {
        type: 'threads',
        attributes: attributes
      }
    }
    return _messageService.all('threads').post(request)

  createMessage: (threadGuid, messageBody, messageSubject, linkUrl) ->
    subject = if messageSubject?.length then messageSubject else null
    attributes = {
      'body_text': messageBody,
      'subject': subject,
      'patient_practice_guid' : $rootScope.DS.getCurrentPatientPracticeGuid()
    }
    if linkUrl
      attributes.attached_links = [linkUrl]
    request = {
      data: {
        type: 'messages',
        attributes: attributes
      }
    }
    return _messageService.one('threads', threadGuid).all('messages').post(request)

  sendMessageWithAttachment: (message) ->
    return _messageService.customGET('attachment_upload_credentials', { provider_profile_guid: message.providerProfileGuid, patient_practice_guid: $rootScope.DS.getCurrentPatientPracticeGuid() }).then (response) =>
      awsData = response.data.attributes
      return @packMessage(message, awsData.correlation_id).then (packedMessage) ->
        AWS.config.update(
          region: awsData.region || 'us-west-1'
          credentials: new AWS.Credentials(awsData.access_key_id, awsData.secret_access_key, awsData.session_token)
        )
        s3 = new AWS.S3(
          apiVersion: '2006-03-01'
          params: { Bucket: awsData.bucket_name }
        )
        return $q (resolve, reject) ->
          s3.upload(
            Key: awsData.file_location
            Body: JSON.stringify(packedMessage)
            ContentType: 'application/json'
            ServerSideEncryption: 'AES256'
          , (err, data) ->
            if err
              reject(err)
            else
              resolve(data)
          )

  packMessage: (message, correlationId) ->
    subject = if message.messageSubject?.length then message.messageSubject else null
    return @packFile(message.attachedFile).then (attachedFile) ->
      data =
        correlation_id: correlationId
        thread_guid: message.threadGuid
        practice_guid: message.practiceGuid
        attributes:
          phr_user_guid: AuthService.getPhrUserGuid()
          provider_profile_guid: message.providerProfileGuid
          body_text: message.messageBody
          subject: subject
          attached_files: [attachedFile]
      if (message.linkUrl)
        data.attributes.attached_links = [message.linkUrl]
      return { data: data }

  packFile: (file) ->
    return $q (resolve) ->
      attachedFile =
        name: file.name
        size: file.size
        type: file.type
      reader = new FileReader();
      reader.onload = (fileContents) ->
        buffer = new Uint8Array(fileContents.target.result)
        binary = ''
        length = buffer.byteLength
        binary += String.fromCharCode(buffer[i]) for i in [0..length - 1]
        attachedFile['base64'] = btoa(binary)
        resolve(attachedFile)
      reader.readAsArrayBuffer(file)

  getFileUrl: (file) ->
    return _messageService.customGET('attachment_download_credentials', { file_guid: file.file_guid }).then (response) ->
      awsData = response.data.attributes
      AWS.config.update(
        region: awsData.region || 'us-west-1'
        credentials: new AWS.Credentials(awsData.access_key_id, awsData.secret_access_key, awsData.session_token)
      )
      s3 = new AWS.S3(
        apiVersion: '2006-03-01'
        params: { Bucket: awsData.bucket_name }
      )
      params =
        Key: awsData.file_location
        Bucket: awsData.bucket_name
        ResponseContentDisposition: "attachment; filename='#{file.file_name}'"
      return s3.getSignedUrl('getObject', params)

  isMessagingEnabled: ->
    return $rootScope.DS.getCurrentPractice()?.is_messaging_enabled
