window.globalHelpersModule.directive "bookAppointmentBtn", 
['$rootScope', '$log', '$timeout', '$filter', '$state', 'FEATURE_BITS'
($rootScope, $log, $timeout, $filter, $state, FEATURE_BITS) ->
  restrict: "E"
  replace: true

  link: (scope, element, attrs) ->
    # if state string contains 'portal.appointments'
    if /portal\.appointments/i.test($state.current.name)
      scope.currentStateIsAppointments = true
    else
      scope.currentStateIsAppointments = false

    scope.getSref = ->
      if FEATURE_BITS.biDirectionalAppointments
        return 'portal.appointments.upcoming.book.provider({})'
      else
        return 'portal.providers({})'

  templateUrl: 'portal/appointments/book-appointment-btn/book-appointment-btn-directive-template.html'
]