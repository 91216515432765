import logo from '../../../../images/base/logo-brandmark.svg';

window.portalModule.controller 'LabsController', ($rootScope, $scope, $filter, $state, $stateParams, $log, AuthService, LabsService, FEATURE_BITS, HealthRecordOverviewService) ->
  $log = $log.getInstance('LabsController')
  $scope.logo = logo

  $scope.environment = AuthService.getEnvironment()
  $log.log 'labs controller loaded.'
  $scope.isHealthRecordsOverviewOn = FEATURE_BITS.isHealthRecordsOverviewOn

  $scope.setLabs = (labs) ->
    $scope.labs = $filter('orderBy')(labs, 'orderSubmittedDatetime')
    $scope.labs = $filter('reverse')($scope.labs, 'true')
    $scope.currentTest = $scope.labs[0]?.tests[0]
    $scope.currentLab = $scope.labs[0]

  if $scope.isHealthRecordsOverviewOn
    $scope.customPageHeader = 'Labs & Imaging Results'
    $scope.load = (forceRefresh) ->
      HealthRecordOverviewService.findAll(forceRefresh).then (response) ->
        $scope.patientDetails = response.patientDetails
        $scope.setLabs response.labsAndImaging
    $scope.$watch 'DS.currentPhrPatientIndex', (newValue, oldValue) -> $scope.load(true) if newValue isnt oldValue
    $scope.load()
  else
    LabsService.findAll().then (response) ->
      $scope.setLabs response.data
      $log.log '$scope.labs', $scope.labs

  $scope.isMobileRightPanelActivated = false

  $scope.selectTest = (lab, test) ->
    # $log.log 'selectTest', 'lab', lab, 'test', test
    $scope.currentTest = test
    $scope.currentLab = lab
    $scope.isMobileRightPanelActivated = true

  $scope.setRightPanelActivated = (state) ->
    $scope.isMobileRightPanelActivated = state
