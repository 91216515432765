import './assets/components/global';
import './assets/components/portal';
import './assets/components/root';
import './assets/components/services';
import './assets/components/models';
import './assets/stylesheets/app.sass';
require('./assets/javascripts');

// _.contains is removed in newer lodash, map to _.includes for restangular
_.contains = _.includes;

if (window.gon && window.gon.env === 'development') {
    require('./assets/components/landing-page');
}