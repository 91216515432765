# right now only patching mobile number IF user gives sms consent
window.portalModule.directive "smsModal", 
['$rootScope', '$log', 'ModalService', 'AgreementsService', 'PhrUserService',
($rootScope, $log, ModalService, AgreementsService, PhrUserService) ->
  restrict: "E"
#  replace: true
  template: '<div></div>'

  link: (scope, element, attrs) ->
    scope.$on 'agreements:launch_sms_modal', (event, payload) ->
      scope.launchSMSModal(payload)

    scope.launchSMSModal = (payload) ->
      modalDefaults =
        backdrop: 'static'
        keyboard: true
        modalFade: true
        templateUrl: "portal/tasks/modals/sms-modal/sms-modal.html"

      modalOptions =
        user: {mobile: $rootScope.DS.user.phone, firstName: $rootScope.DS.user.firstName, lastName: $rootScope.DS.user.lastName}
        vetoUrlText: 'Read more'
        vetoUrl: payload.url
        vetoSummaryUrl: payload.summaryUrl
        declineable: payload.agreement.declineable
        headerText: "Would you like to receive appointment reminders, health record updates, and other health care messages via SMS?"
        actionBtnText: "Continue"

      ModalService.showModal(modalDefaults, modalOptions).then (user) ->
        $log.log user.consent
        if user.consent is 'true'
          AgreementsService.postSmsConsent(payload.agreement, 'accept', user).then (response) ->
            # $log.log 'attempt to patch user mobilePhone'
            PhrUserService.patchContactMobilePhone(user.mobile)
        else if user.consent is 'false'
          AgreementsService.postSmsConsent(payload.agreement, 'declined', user).then (response) ->
            return
]