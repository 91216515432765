import logo from '../../../../images/base/logo-brandmark.svg';

window.portalModule.controller 'PastVisitsController', ($scope, $state, $stateParams, $log, $filter, AuthService, PastVisitsService, FEATURE_BITS) ->
  $scope.logo = logo
  $log = $log.getInstance('PastVisitsController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'past-visits controller loaded.'
  $scope.isHealthRecordsOverviewOn = FEATURE_BITS.isHealthRecordsOverviewOn

  PastVisitsService.findAll().then (response) ->
    $scope.pastVisits = response
    $log.log '$scope.pastVisits', $scope.pastVisits
