import logo from '../../../../images/base/logo-brandmark.svg';

window.portalModule.controller 'MedicationsController', ($scope, $state, $stateParams, $log, $filter, AuthService, MedicationsService, FEATURE_BITS) ->
  $scope.logo = logo
  $log = $log.getInstance('MedicationsController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'medications controller loaded.'
  $scope.isHealthRecordsOverviewOn = FEATURE_BITS.isHealthRecordsOverviewOn

  MedicationsService.getMedications().then (response) ->
    $scope.currentMedications = response.data
    $log.log '$scope.currentMedications', $scope.currentMedications

  MedicationsService.getMedications('historical').then (response) ->
    $scope.pastMedications = response.data
    $log.log '$scope.pastMedications', $scope.pastMedications
