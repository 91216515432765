window.portalModule.factory 'CancellationService', ($rootScope, $log, Restangular, AuthService, CacheFactory, FeedItem) ->
  $log = $log.getInstance('CancellationService')

  # Configuration
  # -----------------------------------

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().APPOINTMENT_ENDPOINT_URL + '/api/v2')

  _cancellationService = restAngular.all('')

  # Actions
  # -----------------------------------

  # POST a cancellation request
  createCancellationRequest: (cancellationRequest) ->
    request = {}
    request.data = {
      type: 'cancellation_requests'
      attributes: {
        practice_guid: $rootScope.DS.getCurrentPractice().practice_guid
        patient_reason: cancellationRequest.otherReason || cancellationRequest.reason
      }
    }
    return _cancellationService.one('appointments', cancellationRequest.scheduledEventGuid).post('cancellation_requests', request)


  # DELETE an appointment request
  deleteRequest: (appointmentRequestGuid) ->
    return _cancellationService.one('creation_requests', appointmentRequestGuid).remove()