globalHelpersModule = angular.module('GlobalHelpersModule')
globalHelpersModule.directive 'debugInfoPanel', 
['$rootScope', '$log', '$document', 'FEATURE_BITS', 'AuthService',
($rootScope, $log, $document, FEATURE_BITS, AuthService) ->
  restrict: 'E'

  link: (scope, element, attrs) ->
    scope.environment = AuthService.getEnvironment()
    scope.code = [51, 49, 13] # type: #, !, enter
    scope.userInput = []
    scope.show = false
    scope.bits = FEATURE_BITS

    $document.on 'keydown', (e) ->
      # skip if it focused in input tag
      if e.target.tagName != "input"
        scope.userInput.push(e.keyCode)
        if scope.userInput.length > scope.code.length
          scope.userInput.shift()
          # $log.log scope.userInput, scope.code
        if _.isEqual scope.code, scope.userInput
          scope.toggle()

    scope.toggle = ->
      scope.show = !scope.show
      scope.$apply()

  template:
    """
      <div
        id="display-feature-bits-container"
        ng-if="show && environment != 'production'"
        class='fx-fade-down fx-speed-200'>
        <div class="inner">
          FEATURE BITS
          <ul>
            <li ng-repeat="(key, value) in bits">
              {{key}}: <strong>{{value}}</strong>
            </li>
          </ul>
        </div>
      </div>
    """
]