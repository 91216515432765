window.portalModule.controller 'EnrollmentRegistrationController',
['AuthService', 'enrollmentGuid', 'RegistrationService', '$log',
(AuthService, enrollmentGuid, RegistrationService, $log) ->
    $log = $log.getInstance('EnrollmentRegistrationController')
    signupUrl = AuthService.getConfigData().PF_LOGIN_URL

    RegistrationService.getEnrollment(enrollmentGuid).then((enrollment) ->
        if enrollment
            emailParam = '&email=' + window.encodeURIComponent(enrollment.email)
            taskCreationUrl = window.encodeURIComponent(
                AuthService.getConfigData().TASK_CREATION_URL +
                '/' + enrollment.phrEnrollmentRequestGuid
            )
            enrollmentHasExpired = moment.utc(enrollment.expiresAt).isBefore()
            profileGuidIsPopulated = !_.isEmpty(enrollment.phrUserProfileGuid)

            if enrollment.status.toLowerCase() is 'pending'
                if enrollmentHasExpired
                    window.location = signupUrl + '?error=EnrollmentExpired'
                else if profileGuidIsPopulated
                    # The enrollment redirect is cleared from Auth0 upon login.
                    # This provides a way to re-enroll by having the provider resend the invite.
                    window.location = signupUrl + '?error=EnrollmentContinue&redirect=' +
                        taskCreationUrl +
                        emailParam
                else
                    window.location = signupUrl +
                        '?defaultTab=signUp&redirect=' +
                        taskCreationUrl +
                        emailParam
            else if enrollment.status.toLowerCase() is 'completed'
                window.location = signupUrl +
                    '?success=EnrollmentComplete' +
                    emailParam
            else
                window.location = signupUrl + '?error=EnrollmentNotFound'
        else
            window.location = signupUrl + '?error=EnrollmentNotFound'
    ).catch ->
        window.location = signupUrl + '?error=EnrollmentNotFound'
]
