window.globalHelpersModule.directive 'healthRecordsCardItem', ->
  restrict: 'E'
  replace: true
  link: (scope, element, attrs) ->

  scope:
    itemTitle: '='
    details: '='

  templateUrl: 'portal/health-records/health-records-card-item/health-records-card-item-directive-template.html'
