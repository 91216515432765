window.globalHelpersModule.directive "creationRequested", 
['$rootScope', '$log', '$timeout', '$filter', '$state', 'AuthService',
($rootScope, $log, $timeout, $filter, $state, AuthService) ->
  restrict: "E"
  scope:
    feedItem: "="
  link: (scope, element, attrs) ->
    $log.log 'feedItem', scope.feedItem

  templateUrl: 'portal/appointments/feed-items/creation_requested/creation_requested-directive-template.html'
]