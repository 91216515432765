window.globalHelpersModule.directive 'composeMessage', 
['$rootScope', '$log', '$timeout', '$filter', '$state', 'ModalService', 'FEATURE_BITS',
($rootScope, $log, $timeout, $filter, $state, ModalService, FEATURE_BITS) ->
  restrict: 'E'
  replace: true
  link: (scope, element, attrs) ->
    if attrs.isReply
      scope.placeholder = 'Reply to message'
      scope.id = 'reply-message-body'
      scope.name = 'replyBody'
      scope.dataElement = 'reply-content'
      scope.idPrefix = 'reply'
    else
      scope.name = 'messageBody'
      scope.id = 'messageBody'
      scope.dataElement = 'new-message-body'
      scope.idPrefix = 'new-message'

    # Don't initialize the bootstrap tooltips for touch devices
    if `!('ontouchstart' in document.documentElement)`
      $timeout(->
        element.find('.compose-message-toolbar [data-toggle="tooltip"]').tooltip()
      , 100)

    scope.onError = (error) ->
      scope.error = error.message
      if scope.errorCallback
        $timeout.cancel(scope.errorCallback)
      if error.timeout
        scope.errorCallback = $timeout(->
          scope.error = null
        , error.timeout)

    scope.insertLink = ->
      if scope.linkUrl
        scope.onError({ message: 'Each message is limited to one hyperlink attachment.', timeout: 2000 })
        return
      modalOptions = {
        linkUrl: ''
        urlRegex: /^((http|https):\/\/)?[a-z0-9\-_]+(\.[a-z0-9\-_]+)+([a-z0-9\-\.,@\?^=%&;:/~\+#]*[a-z0-9\-@\?^=%&;/~\+#])/i
      }
      ModalService.showModal({templateUrl: 'portal/messages/messages-insert-link-modal-template.html', windowClass: 'insert-link-modal'}, modalOptions).then (result) ->
        if result.result == 'ok'
          scope.linkUrl = result.linkUrl
          scope.linkUrlWithProtocol = if scope.linkUrl.indexOf('http') == 0 then scope.linkUrl else 'http://' + scope.linkUrl

    scope.removeLink = ->
      scope.linkUrl = null

    scope.onSelectFile = (file) ->
      scope.error = null
      scope.attachedFile = file

    scope.removeFile = ->
      scope.attachedFile = null

  scope: {
    value: '='
    linkUrl: '='
    attachedFile: '='
    error: '='
  }

  templateUrl: 'portal/messages/compose-message/compose-message-directive-template.html'
]