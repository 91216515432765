window.portalModule.controller 'ChooseProviderController', ($rootScope, $scope, $stateParams, $log, ProvidersService) ->
  $log = $log.getInstance('ChooseProviderController')
  $log.log 'choose provider controller loaded.'

  $scope.headerText = "Which doctor would you like to see?"
  $scope.practice = $rootScope.DS.getCurrentPractice()

  ProvidersService.getProvidersForCurrentPractice().then (response) ->
    $scope.providers = response
    $log.log '$scope.practice', $scope.practice
    $log.log '$scope.providers', $scope.providers

  $scope.$watch 'selectedProvider', (newValue, oldValue) ->
    if newValue?
      provider = _.find $scope.providers, (provider, key) -> provider.profile_guid is newValue
      $scope.$parent.newAppointmentRequest.profileGuid = provider.profile_guid
      $scope.$parent.newAppointmentRequest.facilityGuid = provider.preferred_facility.facility_guid
  , true