window.portalModule.directive 'exportBtn', 
['$rootScope', 'ModalService', 'CCDAV2Service',
($rootScope, ModalService, CCDAV2Service) ->
  restrict: 'E'
  replace: true
  scope:
    disabled : '='
    range: '='
    changeDate: '&onChangeDate'
  link: (scope, element, attrs) ->
    scope.launchModal = ->
      modalOptions =
        range: scope.range
        emailAddress: ''
        emailMessage: ''
        isValid: false
      modalDefaults =
        backdrop: 'static'
        keyboard: true
        modalFade: true
        controller: ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) ->
          $scope.showStrengthLengthIndicator = false
          $scope.modalOptions = modalOptions
          $scope.modalOptions.downloadOrTransmit = null
          $scope.modalOptions.passwordStrength = null
          $scope.modalOptions.password = ''
          $scope.disableTransmitButton = false
          ccdSections =
          [{
            key: 'Allergies',
            title: 'Allergies & Intolerances'
          }, {
            key: 'Assessment and Plan',
            title: 'Assessment & Plan'
          }, {
            key: 'Encounters',
            title: 'Encounters',
          }, {
            key: 'Goals',
            title: 'Goals'
          }, {
            key: 'Health Concerns',
            title: 'Health Concerns'
          }, {
            key: 'Immunizations',
            title: 'Immunizations'
          }, {
            key: 'Medical Equipment',
            title: 'Medical Equipment'
          }, {
            key: 'Medications',
            title: 'Medications'
          }, {
            key: 'Problem List',
            title: 'Diagnoses'
          }, {
            key: 'Procedures',
            title: 'Procedures'
          }, {
            key: 'Reason for Referral',
            title: 'Referrals'
          },  {
            key: 'Lab Tests and Results',
            title: 'Results (Labs)'
          }, {
            key: 'Social History',
            title: 'Social History'
          }, {
            key: 'Vital Signs',
            title: 'Vital Signs'
          }]
          ccdSections.forEach((section, index) ->
            section.tabIndex = index + 5
            section.isSelected = true
          )
          $scope.ccdSections = ccdSections
          $scope.ccdSectionColumns = [ccdSections.slice(0, 5), ccdSections.slice(5, 10), ccdSections.slice(10, 14)]
          $scope.currentState = null
          $scope.modalOptions.transmitErrorMessage = null
          $scope.close = (result) ->
            $uibModalInstance.dismiss 'cancel'
          $scope.continue = (nextState) ->
            $scope.currentState = nextState
          $scope.changeDate = ->
            $uibModalInstance.dismiss 'cancel'
            scope.changeDate()
          $scope.onCheckboxChange = ->
            $scope.checkedComponents = (section.key for section in $scope.ccdSections when section.isSelected)
          $scope.triggerMissingPasswordError = ->
            $scope.showStrengthLengthIndicator = false
            if not $scope.modalOptions.password
              $scope.modalOptions.passwordError = true
              $scope.modalOptions.passwordErrorMessage = 'Please enter a password.'
            else if $scope.modalOptions.password.length < 8
              $scope.modalOptions.passwordConfirmError = true
              $scope.modalOptions.passwordErrorMessage = 'Password must be at least 8 characters.'
            else
              $scope.modalOptions.passwordError = null
              $scope.modalOptions.passwordErrorMessage = null

          $scope.setStrengthLengthIndicator = ->
            $scope.showStrengthLengthIndicator = true
          # Regex source: http://emailregex.com
          $scope.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
          $scope.validate = (shouldValidateEmailOrPassword) ->
            isEmpty = (str) ->
              not str or str.length is 0
            validateEmail = ->
              if isEmpty $scope.modalOptions.emailAddress
                return false
              if isEmpty $scope.modalOptions.emailAddressConfirm
                return false
              if shouldValidateEmailOrPassword or $scope.modalOptions.emailAddressError
                if not $scope.modalOptions.emailAddress.match $scope.emailRegex
                  $scope.modalOptions.emailAddressError = true;
                  $scope.modalOptions.emailAddressErrorMessage = 'Please enter a valid email address.'
                  return false
              if shouldValidateEmailOrPassword or $scope.modalOptions.emailAddressConfirmError
                if  $scope.modalOptions.emailAddress != $scope.modalOptions.emailAddressConfirm
                  $scope.modalOptions.emailAddressErrorMessage = 'Email does not match'
                  $scope.modalOptions.emailAddressConfirmError = true;
                  return false
              return true
            validateEncryptionPassword = ->
              password = $scope.modalOptions.password
              confirmPassword = $scope.modalOptions.passwordConfirm
              if modalOptions.isEncrypted
                if isEmpty password
                  return false
                if isEmpty confirmPassword
                  return false
                if password.length < 8
                  return false
                passwordConfirmed = password is confirmPassword
                if not passwordConfirmed
                  $scope.modalOptions.passwordConfirmError = true
                  $scope.modalOptions.passwordErrorMessage = 'Password does not match.'
                return passwordConfirmed
              else
                return true
            isValid = true
            validEmail = false
            validEncryptionPassword = false
            if $scope.checkedComponents.length
              if validateEmail()
                $scope.modalOptions.emailAddressError = null
                $scope.modalOptions.emailAddressConfirmError = null
                $scope.modalOptions.emailAddressErrorMessage = null
                validEmail = true
              if not $scope.modalOptions.isEncrypted
                $scope.modalOptions.password = ''
                $scope.modalOptions.passwordConfirm = null
              if validateEncryptionPassword()
                $scope.modalOptions.passwordError = null
                $scope.modalOptions.passwordConfirmError = null
                $scope.modalOptions.passwordErrorMessage = null;
                validEncryptionPassword = true
              isValid = validEmail and validEncryptionPassword
            else
              isValid = false
            $scope.modalOptions.isValid = isValid
          $scope.transmit = ->
            $scope.modalOptions.transmitErrorMessage = null
            if not $scope.validate true
              return
            $scope.disableTransmitButton = true
            CCDAV2Service.transmit($scope.modalOptions, $scope.checkedComponents).then (response) ->
              $scope.disableTransmitButton = false
              $scope.close()
            , (err) ->
              $scope.disableTransmitButton = false
              $scope.modalOptions.transmitErrorMessage = 'There was a problem sending your record. Please try again.'

          $scope.$watch('modalOptions.emailAddress', -> $scope.validate())
          $scope.$watch('modalOptions.emailAddressConfirm', -> $scope.validate())
          $scope.$watch('modalOptions.isEncrypted', -> $scope.validate())
          $scope.$watch('modalOptions.password', -> $scope.validate())
          $scope.$watch('modalOptions.passwordConfirm', -> $scope.validate())
          $scope.$watch('checkedComponents.length', -> $scope.validate())

          $scope.onCheckboxChange()
        ]
        templateUrl: 'portal/ccdas/export-btn/export-modal.html'
      ModalService.showModal(modalDefaults, modalOptions)

  templateUrl: 'portal/ccdas/export-btn/export-btn-directive-template.html'
]