# this is the parent abstract controller for the new appointment booking modal flow.
# all 3 child panels (controllers) inherit from this parent controller. this is why
# we are creating the newAppointmentRequest object here.
# children can call the parent values via $scope.$parent.myObject... .
window.portalModule.controller 'BookAppointmentModalController', ($scope, $state, $stateParams, $log, $uibModalInstance, FeedItemService) ->
  $log = $log.getInstance('BookAppointmentModalController')
  $log.log 'book appointment modal controller loaded.'

  $scope.headerText = "Request to reschedule appointment"
  $scope.newAppointmentRequest = {}
  $scope.newAppointmentRequest.isExistingPatient = true

  $scope.close = ->
    $log.log 'cancel called'
    $uibModalInstance.dismiss "cancel"
    # go to the parent state on close
    $state.go('portal.appointments.upcoming')

  # when you click outside the modal go to the parent state
  $uibModalInstance.result.finally (response) ->
    $state.go('portal.appointments.upcoming')
