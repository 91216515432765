import logo from '../../../../images/base/logo-brandmark.svg';

window.portalModule.controller 'OverviewController', ($scope, $state, $stateParams, $log, $filter, AuthService, HealthRecordOverviewService, FEATURE_BITS) ->
  $scope.logo = logo
  $log = $log.getInstance('OverviewController')
  $log.log 'Overview controller loaded.'
  $scope.environment = AuthService.getEnvironment()
  $scope.isHealthRecordsOverviewOn = FEATURE_BITS.isHealthRecordsOverviewOn
  $scope.showExportButton = true
  $scope.showHeaderDatePicker = true
  $scope.customPageHeader = 'Health Records Overview'
  $scope.loadErrorText = "Failed to load data."
  $scope.canClearDateRange = false
  $scope.isDatePickerOpen = false
  $scope.disableExportButton = false
  $scope.healthRecordMetaData =
    labsAndImaging:
      isLabsCard: true
      cardHeader: 'Labs & Imaging'
      column: 0
    diagnoses:
      cardHeader: 'Diagnoses'
      title: 'diagnosisName'
      details: ['performingCareTeamMemberName', 'startDateTimeUtc']
      sortBy: 'diagnosisName'
      column: 1
    medications:
      cardHeader: 'Medications'
      title: 'medicationName'
      details: ['startDateTimeUtc']
      sortBy: 'medicationName'
      column: 2
    immunizations:
      cardHeader: 'Immunizations'
      title: 'immunizationName'
      details: ['status', 'administeredDateTimeUtc']
      sortBy: 'administeredDateTimeUtc'
      column: 0
    allergies:
      cardHeader : 'Allergies'
      title: 'allergenName'
      details: ['severity', 'isActive']
      sortBy: 'allergenName'
      column: 1
    procedures:
      cardHeader: 'Procedures'
      title: 'procedureName'
      details: ['status', 'performedDateTimeUtc']
      sortBy: 'performedDateTimeUtc'
      column: 2
    carePlans:
      isCarePlanCard: true
      cardHeader: 'Care Plan'
      sortBy: 'associatedDateTimeUtc'
      column: 0
    visits:
      cardHeader: 'Visits'
      title: 'providerName'
      details: ['visitDateTimeUtc']
      sortBy: 'visitDateTimeUtc'
      column: 1
    vitals:
      cardHeader: 'Vitals'
      title: 'procedureName'
      details: ['status', 'performedDateTimeUtc']
      column: 2
    socialHistory:
      cardHeader: 'Social History'
      title: 'status',
      details: ['effectiveDateTimeUtc']
      sortBy: 'effectiveDateTimeUtc'
      column: 0
    healthConcerns:
      cardHeader: 'Health Concerns'
      title: 'concernName',
      details: ['dateTimeUtc']
      sortBy: 'dateTimeUtc'
      column: 0
    careTeam:
      cardHeader: 'Care Team'
      title: 'roles',
      details: ['providerName']
      sortBy: 'providerName'
      column: 1
    implantableDevices:
      cardHeader: 'Implantable Devices'
      title: 'deviceName',
      details: ['uniqueDeviceIdentifier', 'implantedDateTimeUtc']
      sortBy: 'implantedDateTimeUtc'
      column: 2
    goals:
      cardHeader: 'Goals'
      title: 'goalText'
      details: ['startDateTimeUtc']
      sortBy: 'startDateTimeUtc'
      column: 2

  $scope.vitalsMetadata =
    height:
      title: 'Height'
      order: 0
    weight:
      title: 'Weight'
      order: 1
    bloodPressure:
      title: 'Blood Pressure'
      order: 2
    bmi:
      title: 'BMI'
      order: 3
    heartRate:
      title: 'Heart Rate'
      order: 4
    respiratoryRate:
      title: 'Respiratory Rate'
      order: 5
    bodyTemperature:
      title: 'Body Temperature'
      order: 6
    pulseOximetry:
      title: 'Pulse Oximetry'
      order: 7
    inhaledOxygenConcentration:
      title: 'Inhaled Oxygen Concentration'
      order: 8

  $scope.load = ({ startDay, endDay, forceRefresh }) ->
    if $scope.hasPatientRecordAuthorization
        $scope.loading = true
        promise = if startDay and endDay then HealthRecordOverviewService.findAllInRange(startDay, endDay) else HealthRecordOverviewService.findAll(forceRefresh)
        promise.then (response) ->
          columns = [[],[],[]]
          patientDetails = response.patientDetails
          $scope.patientName = patientDetails.patientName
          $scope.patientRecordNumber = patientDetails.patientRecordNumber

          patientDetails = [{
            label: 'DOB'
            value: moment.utc(patientDetails.dateOfBirthUtc).format('MM/DD/YYYY')
          }, {
            label: 'Sex',
            value: patientDetails.sex
          }, {
            label: 'Race/Ethnicity'
            value: if patientDetails.raceAndEthnicity then patientDetails.raceAndEthnicity else 'Not provided'
          }, {
            label: 'Preferred Language'
            value: if patientDetails.preferredLanguage then patientDetails.preferredLanguage else 'Not provided'
          }]
          for type, accessInfo of $scope.healthRecordMetaData
            items = []
            data = response[type]

            if type is 'labsAndImaging'
              items = $scope.flattenLabTests(data)
            else if type is 'vitals'
              items = $scope.generateVitalItems(data)
            else if type is 'carePlans'
              data = $scope.sortArray(data, accessInfo.sortBy, type)
              items = $scope.generateCarePlanItems(data)
            else
              if accessInfo.sortBy
                data = $scope.sortArray(data, accessInfo.sortBy, type)
              for item in data
                title = item[accessInfo.title]
                if type is 'careTeam'
                  title = title.join(', ')
                  isPrimaryCareProvider = title.toLowerCase().indexOf("primary") isnt -1
                  if isPrimaryCareProvider
                    items.splice 0, 0,
                      title: title
                      details: $scope.formatDetails(item, accessInfo.details, type)
                    continue
                items.push
                  title: title
                  details: $scope.formatDetails(item, accessInfo.details, type)

            if type == 'careTeam'
              patientDetails.push
                label: 'Care Team'
                value: if items.length then $scope.joinCareTeamMembers(items) else 'Not provided'
                isCareTeamDetail: true

            columns[accessInfo.column].push
              title: accessInfo.cardHeader
              items: items
              isLabsCard: accessInfo.isLabsCard
              isCarePlanCard: accessInfo.isCarePlanCard

          $scope.loadingError = false
          $scope.patientDetails = patientDetails
          $scope.columns = columns
          $scope.updatedText = "Updated as of #{response.timestamp.format('MM/DD/YY [at] hh:mm A')}"
        .catch ->
          $scope.loadingError = true
        .finally ->
          $scope.canClearDateRange = startDay and endDay
          if $scope.canClearDateRange
            $scope.statusText = "#{(if $scope.loadingError then 'Failed to show' else 'Currently showing')} data for #{startDay} - #{endDay}"
          else
            $scope.statusText = if $scope.loadingError then 'Failed to load data' else 'Currently showing all data.'
          $scope.loading = false

  # sorting rules applied to diagnoses and medications
  $scope.dxAndMedSort = (array, key) ->
    return array.sort (a,b) ->
      noEndDates = not a['endDateTimeUtc'] and not b['endDateTimeUtc']
      bothEndDates = a['endDateTimeUtc'] and b['endDateTimeUtc']

      if noEndDates or bothEndDates
        if a['startDateTimeUtc'] is b['startDateTimeUtc']
          return $scope.alphabeticalStort(a, b, key)
        return $scope.sortByDate(a, b, 'startDateTimeUtc')
      if not a['endDateTimeUtc']
        return -1
      if not b['endDateTimeUtc']
        return 1
      return 0

  $scope.allergySort = (array, key) ->
    return array.sort (a,b) ->
      if a['isActive'] is b['isActive']
        return $scope.alphabeticalStort(a, b, key)
      else if a['isActive'] is true
        return -1
      else if b['isActive'] is true
        return 1
      else
        0

  $scope.sortArray = (array, key, type) ->
    if type is 'allergies'
      return $scope.allergySort(array, key)
    if type is 'diagnoses' or type is 'medications'
      return $scope.dxAndMedSort(array, key)
    if key.toLowerCase().indexOf("date") isnt -1
      return array.sort (a, b) ->
        $scope.sortByDate(a, b, key)
    return array.sort (a, b) ->
      $scope.alphabeticalStort(a, b, key)

  $scope.alphabeticalStort = (a, b, key) ->
    a = a[key].toLowerCase()
    b = b[key].toLowerCase()
    if a < b
      return -1
    else if a > b
      return 1
    else
      return 0

  # currently supports descending order
  $scope.sortByDate = (a, b, key) ->
    if moment(a[key]) > moment(b[key])
      return -1
    else if moment(a[key]) < moment(b[key])
      return 1
    else
      return 0

  $scope.range =
    start: null
    end: null
    str: null

  $scope.selectDateRange = ->
    $scope.load { startDay: $scope.range.start, endDay: $scope.range.end }

  $scope.joinCareTeamMembers = (careTeams) ->
    careTeamMembers = careTeams.map (member) -> member.details
    return careTeamMembers.join(' | ')

  $scope.formatDetails = (data, keys, type) ->
    accessedData = keys.map (key) ->
      isDate = key.toLowerCase().indexOf("date") isnt -1

      if data[key] is undefined and not isDate
        return null
      if key is 'isActive'
        return if data[key] then 'Active' else 'Inactive'
      if isDate
        formatteddate = moment.utc(data[key]).format('MM/DD/YYYY')
        if type is 'implantableDevices'
          return if data[key] then "Implant Date: #{formatteddate}" else null

        if type is 'diagnoses' or type is 'medications'
          startDate = if data.startDateTimeUtc then moment.utc(data.startDateTimeUtc).format('MM/DD/YYYY') else null
          endDate = if data.endDateTimeUtc then moment.utc(data.endDateTimeUtc).format('MM/DD/YYYY') else null

          if not startDate and not endDate
            return null
          if not startDate and endDate
            return "End: #{endDate}"
          return "Start: #{startDate}#{if endDate then " - End: #{endDate}" else ""}"

        return if formatteddate then formatteddate else null
      return data[key]
    .filter (x) -> x != null
    return accessedData.join(' | ')

  $scope.generateCarePlanItems = (data) ->
    items = []
    for plan in data
      items.push
        title: "Encounter #{moment.utc(plan.associatedDateTimeUtc).format('MM/DD/YYYY')}"
        details: plan.carePlanText
    return items

  $scope.generateVitalItems = (data) ->
    items = []
    for vital,value of data
      details = value[0]
      metadata = $scope.vitalsMetadata[vital]
      items.splice metadata.order, 0,
        title: "#{metadata.title} - #{details.result || 'Never recorded'}"
        details: if details.recordedDateTimeUtc then moment.utc(details.recordedDateTimeUtc).format('MM/DD/YYYY') else null
    return items

  $scope.flattenLabTests = (data) ->
    tests = []
    for order in data
      for test in order.tests
        tests.push
          name: test.name
          orderDate: order.orderSubmittedDatetime
          orderingProviderName: order.orderingProviderName
          facilityAddress: order.facilityAddress
          observations: test.observations
          specimens: test.specimens
    tests.sort (a, b) -> $scope.sortByDate(a, b, 'orderDate')
  $scope.refresh = ->
    $scope.load { startDay: $scope.range.start, endDay: $scope.range.end, forceRefresh: true }
  $scope.clear = ->
    $scope.range =
      start: null
      end: null
      str: null
    $scope.load { forceRefresh: true }
  $scope.setIsDatePickerOpen = (isOpen) ->
    $scope.isDatePickerOpen = isOpen

  $scope.checkExportButtonState = ->
    $scope.disableExportButton = $scope.loading || $scope.loadingError

  $scope.$watch 'DS.currentPhrPatientIndex', (newValue, oldValue) -> $scope.clear() if newValue isnt oldValue
  $scope.$watch('loading', -> $scope.checkExportButtonState())
  $scope.$watch('loadingError', -> $scope.checkExportButtonState())

  $scope.load {}
