window.portalModule.controller 'PortalController', ($rootScope, $scope, $state, $stateParams, $log, AuthService, DataStore, TimerService, phrPatients, patientPractices, practices, user) ->
  $log = $log.getInstance('PortalController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'portal controller loaded.'

  AuthService.setPhrUser(user?.users)

  # start the app timer
  TimerService.bootstrap()

  # breakpoint detection was firing before the DOM was ready on mobile devices.
  # delaying the breakpoint detection directive from firing until the portal controller is inititalized.
  $scope.$emit 'init-breakpoint-detection-directive'

  # initialize our data store and pass in the portal $scope so it's available portal-wide
  DataStore.init($scope, phrPatients, patientPractices, practices, user)

  # turn this on globally and turn if off via an override in a controller
  $scope.hasDownloadAndTransmitLinks = true

  # handle setting the global responsive state variable in the rootScope
  $scope.$on 'setup', (event, payload) ->
    $scope.logResponsiveState('setup', event, payload)
  $scope.$on 'match', (event, payload) ->
    $scope.logResponsiveState('match', event, payload)

  $scope.logResponsiveState = (typeOf, event, payload) ->
    if payload is '$isMobile'
      $scope.IS_MOBILE = true
      $scope.IS_TABLET = false
      $scope.IS_DESKTOP = false
      $log.log typeOf, 'IS_MOBILE'
    if payload is '$isTablet'
      $scope.IS_MOBILE = false
      $scope.IS_TABLET = true
      $scope.IS_DESKTOP = false
      $log.log typeOf, 'IS_TABLET'
    if payload is '$isDesktop'
      $scope.IS_MOBILE = false
      $scope.IS_TABLET = false
      $scope.IS_DESKTOP = true
      $log.log typeOf, 'IS_DESKTOP'

    # update the rootscope object
    $rootScope.IS_MOBILE = $scope.IS_MOBILE
    $rootScope.IS_TABLET = $scope.IS_TABLET
    $rootScope.IS_DESKTOP = $scope.IS_DESKTOP

  # used in child controllers when hamburger icon is clicked
  # open navigation
  $scope.hamburgerClick = ->
    $scope.$broadcast 'navigation-toggle-open'
