window.globalHelpersModule.directive "updatesDropdown", 
['$log', 'NotificationService', ($log, NotificationsService) ->
  restrict: "E"
  scope:
    updates: '='
  link: (scope, element, attrs) ->

    scope.init = ->
      NotificationsService.findAll().then (response) ->
        scope.meta = response.meta
        scope.updates = response.data

    scope.markUpdateRead = (e, update) ->
      e.stopPropagation() # don't close the update dropdown when we mark an item as read
      update.attributes.is_read = true
      NotificationsService.patch(update)
      $log.log 'update read', update

    scope.checkForUpdates = (e) ->
      scope.init()
      e.stopPropagation() # don't close the update dropdown when we mark an item as read
      $log.log 'checking for updates'

    scope.markAllUpdatesAsRead = (e) ->
      e.stopPropagation() # don't close the update dropdown when we mark an item as read
      for update in scope.updates
        update.attributes.is_read = true
      NotificationsService.updateAllAsRead(scope.updates)
      $log.log 'mark all updates as read'

    scope.unreadCount = ->
      return scope.meta.unread_count

    scope.init()

  templateUrl: "portal/appointments/updates-dropdown/updates-dropdown-directive-template.html"
]