# Proposed new times (transition state)
window.portalModule.factory 'ProposalService', ($rootScope, $log, Restangular, AuthService, CacheFactory, FeedItem) ->
  $log = $log.getInstance('ProposalService')

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().APPOINTMENT_ENDPOINT_URL + '/api/v2')

  _proposalService = restAngular.all('requests')

  transitionRequest: (feedItem, transition) ->
    request = {}
    request.data = {
      type: 'transitions'
      id: feedItem.feed_item_guid
      attributes: {
        request_type: feedItem.feed_item_type
        transition: transition
        user_type: 'phr_user'
        state_token: feedItem.request_state_token
      }
    }
    return _proposalService.one('', feedItem.feed_item_guid).post('transitions', request)
