window.portalModule.directive "transmitBtn",
['$rootScope', '$log', '$timeout', '$filter', 'ModalService', 'CCDAService', 'AuditrService', 'EmailValidationService',
($rootScope, $log, $timeout, $filter, ModalService, CCDAService, AuditrService, EmailValidationService) ->
  restrict: "E"
  replace: true
  scope:
    ccdaDocuments : "="
  link: (scope, element, attrs) ->
    # Because modal is dependent on ccdaDocuments being loaded and available via rootScope, add watcher
    # for ccdaDocuments which is loaded asynchronously from parent controller (i.e. Labs Controller)
    scope.$watch 'ccdaDocuments', (newValue, oldValue) ->
      if newValue != oldValue
        $rootScope.DS.ccdas = scope.ccdaDocuments

    scope.getSelectedCCDA = ->
      if $rootScope.DS.ccdas && $rootScope.DS.ccdas.length > 0
        scope.selectedCCDA = $rootScope.DS.ccdas[0]

    scope.recordTypes = CCDAService.getRecordTypes()
    scope.isGenerateXmlRecordsVisible = false
    scope.isEmailValid = null # must start as null for validations to work in modal
    scope.validateEmailTimer = null
    scope.showCCDARequestStatus = null
    scope.delayModalCloseMs = 3000

    scope.launchTransmitModal = ->
      modalOptions =
        isGenerateXmlRecordsVisible: scope.isGenerateXmlRecordsVisible
        recordTypes: scope.recordTypes
        ccdas: $rootScope.DS.ccdas
        transmission: {
          email: ''
          message: ''
          selectedCCDA: scope.getSelectedCCDA()
        }
        headerText: "Transmit patient summary"
        subHeaderText: "Select previously generated XML to transmit"
        actionBtnText: "Transmit patient summary"
        generateActionBtnText: "Generate XML Record"
        selectedRecordTypes: []
        isEmailValid: ->
          return scope.isEmailValid
        validateEmail: (email) ->
          return scope.validateEmail(email)
        showCCDARequestStatus: ->
          return scope.showCCDARequestStatus

      modalDefaults =
        backdrop: true
        keyboard: true
        modalFade: true
        # lets have a custom controller for this modal
        controller: ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) ->
          $scope.modalOptions = modalOptions
          # the result object is whatever you pass in from the button with the "ok" method on it
          $scope.modalOptions.ok = (result) ->
            if result.result is 'transmit'
              ccdaRequest = CCDAService.export(result.transmission)
              ccdaRequest.then (response) ->
                scope.showCCDARequestStatus = true
                # close the modal
                $timeout ->
                  $uibModalInstance.dismiss "cancel"
                  scope.showCCDARequestStatus = null
                , scope.delayModalCloseMs

              ccdaRequest.catch (e) ->
                scope.showCCDARequestStatus = false
                $log.error(e)

            else if result.result is 'generateXml'
              CCDAService.generateXMLRecord(result).then (response) ->
                # add result to the beginning of the Datastore array
                $rootScope.DS.ccdas.unshift(response.data)
                $log.log 'generateXml', response.data
                # scope.isGenerateXmlSuccess = true # show the success alert
                # TODO: hook up alerts to success and error alerts
                $uibModalInstance.dismiss "cancel"

                # reopen the download modal
                scope.launchTransmitModal()

            return false

          $scope.modalOptions.close = (result) ->
            $uibModalInstance.dismiss "cancel"
        ]
        templateUrl: "portal/ccdas/transmit-btn/transmit-modal.html"

      # validate the email against the health vault, etc ie. is this a valid recipient per our systems
      scope.validateEmail = (email) ->
        result = false
        if scope.validateEmailTimer != null
          #if there is already a timeout in process cancel it
          $timeout.cancel scope.validateEmailTimer
        scope.validateEmailTimer = $timeout((->
          EmailValidationService.validate(email).then (response) ->
            result = response
            # $log.log 'validateEmail', result
            if result is true or result is 'true'
              scope.isEmailValid = true
            else
              scope.isEmailValid = false
            scope.validateEmailTimer = null
            return
        ), 300)

        return result

      ModalService.showModal(modalDefaults, modalOptions)

  templateUrl: 'portal/ccdas/transmit-btn/transmit-btn-directive-template.html'
]