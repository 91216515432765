window.globalHelpersModule.directive 'confirmedRescheduleRequested',
['$rootScope', '$log', '$timeout', '$filter', '$state', 'AuthService',
($rootScope, $log, $timeout, $filter, $state, AuthService) ->
  restrict: "E"
  scope:
    feedItem: "="
  link: (scope, element, attrs) ->
    scope.intakeFormLink = AuthService.getConfigData().INTAKE_URL + '/patient?route=patient/appointments&appointment_guid=' + scope.feedItem.scheduled_event_guid
    scope.intakeFormLinkText = 'Complete check-in'

    if scope.feedItem.submitted_at
      scope.intakeFormLinkText = 'Review check-in'

  templateUrl: 'portal/appointments/feed-items/confirmed_reschedule_requested/confirmed_reschedule_requested-directive-template.html'
]