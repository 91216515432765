window.tasksModule.controller 'TasksActiveController', ($rootScope, $scope, $state, $stateParams, $filter, $log, AuthService, TasksService, FEATURE_BITS) ->
  $log = $log.getInstance('TasksActiveController', true, false, true)
  $log.log 'tasks active controller loaded.'
  $scope.bidiEnabled = FEATURE_BITS.biDirectionalAppointments

  $scope.init = ->
    TasksService.findAll('active').then (response) ->
      $scope.tasks = response.data
      $scope.showPlaceholderTask = if $scope.tasks.length is 0 then true else false
      $scope.patientName = if $rootScope.DS.canAccessPatientData then "for #{$rootScope.DS.getCurrentPhrPatient().firstName}" else ""

      # automatically launch the verify access modal when an active identity verification tasks exists
      verifyAccessTask = _.find response.data, (task) -> task.task_type is 'IDENTITY_VERIFICATION'
      if verifyAccessTask?
        $rootScope.$broadcast 'tasks:launch_verify_access_modal', verifyAccessTask

  # reload the tasks when one is updated
  $scope.$on 'task-updated', ->
    # tell the task-filter directive to update
    $scope.$broadcast 'update-task-filter-count'
    $scope.init()

  $scope.init()
