window.portalModule.directive "acknowledgments", 
['$rootScope', '$log', '$timeout', ($rootScope, $log, $timeout) ->
  restrict: "E"

  link: (scope, element, attrs) ->
    scope.$log = $log.getInstance('acknowledgments directive')
    scope.acknowledgments = [] # CON-4056 initial empty array prevents flicker in UI of acknowledgments loading and then disappearing

    # format the acknowledgment type text displayed in the widget
    scope.getAgreementTypeString = (acknowledgment) ->
      return 'OAB treatment'

  templateUrl: 'portal/account-settings/widgets/acknowledgments/acknowledgments-directive-template.html'
]