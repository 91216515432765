window.globalHelpersModule.directive 'dateRangePickerInput', ->
  restrict: 'E'
  replace: true
  link: (scope, element, attrs) ->
    updateError = ->
      if scope.error
        element.find('input').tooltip({ title: scope.error, trigger: 'manual' })
        element.find('input').tooltip('show')
      else
        element.find('input').tooltip('destroy')
    scope.$watch('error', updateError)

  scope:
    value: '='
    error: '='
    placeholder: '@'
    today: '='
    blur: '&onBlur'

  templateUrl: 'portal/health-records/date-range-picker-input/date-range-picker-input-directive-template.html'
