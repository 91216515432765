window.portalModule.factory 'AcknowledgmentsService', ($rootScope, $log, Restangular, AuthService) ->
  $log = $log.getInstance('AcknowledgmentsService')

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().AGREEMENT_ENDPOINT_URL)

  _acknowledgmentsService = restAngular.all("acknowledgments")

  findAll: (agreementType) ->
    return _acknowledgmentsService.get('', {agreement_type:agreementType})