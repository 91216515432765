window.globalHelpersModule.directive "breakpoints", [ '$rootScope', '$log', '$timeout', ($rootScope, $log, $timeout) ->
  restrict: "EA"
  controller: [
    "$scope"
    "$element"
    "$attrs"
    ($scope, $element, $attrs) ->
  ]

  link: (scope) ->
    scope.$on 'init-breakpoint-detection-directive', ->
      scope.$log = $log.getInstance('breakpoints directive')

      breakpoints =
        $isMobile: "screen and (max-width: 767px)"
        $isTablet: "screen and (min-width: 768px) and (max-width: 991px)"
        $isDesktop: "screen and (min-width: 992px)"

      keys = Object.keys(breakpoints)
      i = 0

      while i < keys.length
        ((i) ->
          enquire.register breakpoints[keys[i]],

            # OPTIONAL
            # If supplied, triggered when a media query matches.
            match: ->
              scope.$log.log "matching in directive", keys[i]
              $timeout ->
                scope.$broadcast "match", keys[i]
              , 10
              return

          # OPTIONAL
          # If supplied, triggered when the media query transitions
          # *from a matched state to an unmatched state*.
            unmatch: ->
  #            console.log "un-matching in directive", keys[i]
              $timeout ->
                scope.$broadcast "unmatch", keys[i]
              , 10
              return

          # OPTIONAL
          # If supplied, triggered once, when the handler is registered.
            setup: ->
              scope.$log.log "setup in directive", keys[i]
              $timeout ->
                scope.$broadcast "setup", keys[i]
              , 10
              return

          # OPTIONAL, defaults to false
          # If set to true, defers execution of the setup function
          # until the first time the media query is matched
            deferSetup: true

          # OPTIONAL
          # If supplied, triggered when handler is unregistered.
          # Place cleanup code here
            destroy: ->
              # console.log "destroy in directive", keys[i]
              scope.$broadcast "destroy", keys[i]
              return

          return
        ) i
        i++
      return
]