window.portalModule.directive 'exportModalDownloadBtn', 
['$rootScope', '$sce', 'AuthService',
($rootScope, $sce, AuthService) ->
  restrict: 'E'
  replace: true
  scope:
    format: '@'
    range: '='
    components: '='
    tabIndex: '='
    close: '&onClose'
  templateUrl: 'portal/ccdas/export-btn/export-modal-download-btn-directive-template.html'
  link: (scope, element, attrs) ->
    downloadUrl = "#{AuthService.getConfigData().CCDA_ENDPOINT_URL}/api/v2/ccda_documents/#{if scope.format is 'xml' then 'download_xml' else 'download_human_readable'}"
    scope.downloadUrl = $sce.trustAsResourceUrl downloadUrl
    scope.buttonText = "Download #{if scope.format is 'xml' then 'data file (XML)' else 'document (HTML)'}"
    scope.phrPatientGuid = $rootScope.DS.getCurrentPhrPatient().phrPatientGuid
]