window.portalModule.factory 'InsurancePlansService', ($rootScope, $log, Restangular, CacheFactory, AuthService) ->
  $log = $log.getInstance('InsurancePlansService')

  # create a cache
  insurancePlansCache = CacheFactory('insurancePlansCache', {
    deleteOnExpire: 'aggressive',
    cacheFlushInterval: 1800000, # this cache will check for expired items 30 minutes
    storageMode: 'memory'
  })

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().BILLING_ENDPOINT_URL + '/api/v1/')
    Configurer.setDefaultHttpFields({cache: insurancePlansCache})

  _insurancePlansService = restAngular.all("phr_patients")

  find: () ->
    return _insurancePlansService.one($rootScope.DS.getCurrentPhrPatient().phrPatientGuid).customGET('insurance_plans', {})