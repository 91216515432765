window.portalModule.directive "authorizations", 
['$rootScope', '$log', '$timeout', 'PhrPatientService', 
($rootScope, $log, $timeout, PhrPatientService) ->
  restrict: "E"

  link: (scope, element, attrs) ->
    scope.$log = $log.getInstance('authorizations directive')
    scope.authorizations = {}
    scope.authorizationsLength = 0

    PhrPatientService.getPatientPractices().then (patientPractices) ->
      # note the response object here is an object, not an array. see service.
      scope.authorizations = patientPractices
      scope.authorizationsLength = _.keys(scope.authorizations).length
      scope.$log.log 'scope.authorizations', scope.authorizations

  templateUrl: 'portal/account-settings/widgets/authorizations/authorizations-directive-template.html'
]