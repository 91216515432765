# one cannot close the ui.bootstrap.typeahead popup as there's no functionality for that
# this directive allows that to happen via a custom event
globalHelpersModule = angular.module('GlobalHelpersModule')
globalHelpersModule.directive 'uiTypeaheadClose', ['$log', '$timeout', ($log, $timeout) ->
  restrict: 'A'
  require: ['ngModel']
  link: (scope, element, attrs, ctrls) ->
    scope.$on 'close-typeahead-popup', ->
      ctrls[0].$setViewValue('')
      element.val('')
]
