import logo from '../../../../images/base/logo-brandmark.svg';

window.portalModule.controller 'AllergiesController', ($scope, $state, $stateParams, $log, $filter, AuthService, AllergiesService, FEATURE_BITS) ->
  $scope.logo = logo
  $log = $log.getInstance('AllergiesController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'allergies controller loaded.'
  $scope.isHealthRecordsOverviewOn = FEATURE_BITS.isHealthRecordsOverviewOn

  AllergiesService.findAll().then (response) ->
    $scope.allergies = response.data
    $log.log '$scope.allergies', $scope.allergies
