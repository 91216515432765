import {
  expireAuthToken,
  extractAuthToken,
  setAuthCookie
} from "../../../../helpers/auth0-helpers.js";

# this is a simple auth service that checks for the gon object and if it doesn't have a session key in it we are returning
# that the session is not valid. it's not meant to be for rock solid auth. it's mostly so we can redirect the user to
# an error page when they don't have a valid session. the 'real' auth logic is happening on each endpoint. without a valid
# token API's won't return data anyways. rather than just show the app in a broken state we'll perform redirects here
angular.module('GlobalHelpersModule').factory 'AuthService', ($log, $http, jwtHelper) ->
  $log = $log.getInstance('AuthService')

  debug = true
  session = Object.assign({}, gon)

  deleteAuthToken: ->
    expireAuthToken()

  getSessionData: ->
    session

  getConfigData: ->
    session.config

  getEnvironment: ->
    session?.env

  hasValidSession: ->
    $log.info 'valid session:', session.sessionKey? if debug
    $log.info 'session data:', @getSessionData() if debug
    session?.sessionKey? and session?.sessionKey != ''

  hasValidAuthToken: ->
    if session?.authToken? and session?.authToken != ''
      if this.getEnvironment() is 'test'
        return true
      isValid = false
      try
        isValid = !jwtHelper.isTokenExpired(session?.authToken)
      catch e
        this.deleteAuthToken()
      finally
        return isValid
    return false

  hasProviderPhotoUrl: ->
    session?.providerPhotoUrl? and session?.providerPhotoUrl != ''

  hasAppointmentGuid: ->
    session?.appointmentGuid? and session?.appointmentGuid != ''

  hasPracticeGuid: ->
    session?.practiceGuid? and session?.practiceGuid != ''

  hasPatientPracticeGuid: ->
    session?.patientPracticeGuid? and session?.patientPracticeGuid != ''

  initializeAuthenticatedSession: ->
    if this.getEnvironment() isnt 'test'
      session?.authToken = extractAuthToken()

    if this.hasValidAuthToken()
      $http.defaults.headers.common['Authorization'] = this.getAuthToken()
    else
      delete $http.defaults.headers.common['Authorization']

  isPhrUser: ->
    session?.isPhrUser

  isProvider: ->
    session?.isProvider

  getPhrUserGuid: ->
    session?.phrUserGuid

  setPhrUser: (user) ->
    session.isPhrUser = true
    session.phrUserGuid = user?.phrUserGuid
    session.username = user?.username

  getUsername: ->
    session?.username

  getAuthToken: ->
    'Bearer ' + session?.authToken

  setAuthToken: (token) ->
    setAuthCookie(token)

  # TODO: remove once REDIS session storage is decomissioned
  isAuthTokenJWT: ->
    session?.authToken.slice(0, 4) is 'JWT '

  getProviderGuid: ->
    session?.providerGuid

  getPracticeGuid: ->
    session?.practiceGuid

  getPatientPracticeGuid: ->
    session?.patientPracticeGuid

  getSessionKey: ->
    session?.sessionKey

  getProviderPhotoUrl: ->
    session?.providerPhotoUrl

  getDefaultRouteEnabled: ->
    this.getEnvironment() isnt 'test' or session?.autoRouteEnabled

  getRouteState: ->
    if session?.route?.state?
      session?.route?.state
    else
      session?.route

  getRouteParams: ->
    # make sure to pass the phrPatientGuid
    if _.isObject(session?.route)
      session?.route.phrPatientGuid = @getPhrPatientGuid()
      session.route
    else
      { phrPatientGuid: @getPhrPatientGuid() }

  getPhrPatientGuid: ->
    session?.phrPatientGuid

  setPhrPatientGuid: (phrPatientGuid) ->
    session.phrPatientGuid = phrPatientGuid

  getAppointmentGuid: ->
    session?.appointmentGuid

  getError: ->
    session?.error

  getSignoutUrl: ->
    this.getConfigData().PF_LOGIN_URL + '?success=UserLoggedOut'

  # public facing doctor profile photo paths
  getBuiltProfilePhotoUrl: (providerProfileGuid) ->
    return 'http://s3.amazonaws.com/pf_aws_int_profiles/files/' + providerProfileGuid + '/profilephoto.jpg' if @getEnvironment() == 'test'
    return 'http://s3.amazonaws.com/pf_aws_int_profiles/files/' + providerProfileGuid + '/profilephoto.jpg' if @getEnvironment() == 'development'
    return 'http://s3.amazonaws.com/pf_aws_int_profiles/files/' + providerProfileGuid + '/profilephoto.jpg' if @getEnvironment() == 'int'
    return 'http://s3.amazonaws.com/pf_aws_qa_profiles/files/' + providerProfileGuid + '/profilephoto.jpg' if @getEnvironment() == 'qa'
    return 'http://s3.amazonaws.com/foobar_profiles/files/' + providerProfileGuid + '/profilephoto.jpg' if @getEnvironment() == 'rc'
    return 'http://pf-stg-pubfiles.s3.amazonaws.com/files/' + providerProfileGuid + '/profilephoto.jpg' if @getEnvironment() == 'staging'
    return 'https://pfpubfiles.s3.amazonaws.com/files/' + providerProfileGuid + '/profilephoto.png' if @getEnvironment() == 'production'

  # determines if the user is authenticated as a provider/phr_user. if so, and the app route they are trying to access
  # matches that user type this returns true, otherwise false. we're using this method to redirect a user trying to access
  # a section of intake they don't have permissions for.
  # appState (string): set in the data object for each app section in routes.js.coffee
  isAuthenticatedUserType: (appState) ->
    if (appState is 'provider' and @isProvider()) or (appState is 'phr_user' and @isPhrUser())
      return true
    else
      return false

  # retrieve a value for a query parameter
  # http://stackoverflow.com/questions/2090551/parse-query-string-in-javascript
  getQueryVariable: (variable) ->
    query = window.location.search.substring(1)
    vars = query.split("&")
    i = 0

    while i < vars.length
      pair = vars[i].split("=")
      return decodeURIComponent(pair[1])  if decodeURIComponent(pair[0]) is variable
      i++

  showDebugInfo: ->
    $log.info "AuthService.hasValidSession()", @hasValidSession()
    $log.info "AuthService.isAuthenticatedUserType('provider')", @isAuthenticatedUserType('provider')
    $log.info "AuthService.isAuthenticatedUserType('phr_user')", @isAuthenticatedUserType('phr_user')
    $log.info "AuthService.hasValidAuthToken()", @hasValidAuthToken()
    $log.info "AuthService.getRoute()", @getRoute()

  signoutUserFromAuth0: ->
    expireAuthToken()

    config = this.getConfigData()
    auth0SignoutUrl = config.AUTH0_DOMAIN_URL +
      '/v2/logout?client_id=' +
      config.PATIENT_PORTAL_AUTH0_CLIENT_ID +
      '&returnTo=' +
      encodeURIComponent(this.getSignoutUrl())

    window.location = auth0SignoutUrl
