import logo from '../../../../images/base/logo-brandmark.svg';

window.portalModule.controller 'DiagnosesController', ($scope, $state, $stateParams, $log, $filter, AuthService, DiagnosesService, FEATURE_BITS) ->
  $scope.logo = logo
  $log = $log.getInstance('DiagnosesController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'diagnoses controller loaded.'
  $scope.isHealthRecordsOverviewOn = FEATURE_BITS.isHealthRecordsOverviewOn

  DiagnosesService.findAll().then (response) ->
    $scope.diagnoses = response
    $log.log '$scope.diagnoses', $scope.diagnoses
