window.portalModule.directive "contactInfo", 
['$rootScope', '$log', '$timeout', 'PhrUserService', 'AgreementsOrderService', 'toastr', 'ModalService',
($rootScope, $log, $timeout, PhrUserService, AgreementsOrderService, toastr, ModalService) ->
  restrict: "E"

  link: (scope, element, attrs) ->
    scope.$log = $log.getInstance('contactInfo directive')

    PhrUserService.get().then (response) ->
      scope.patient = response.users
      scope.initPatient = angular.copy(scope.patient)
      if _.isEmpty(scope.patient.firstName)
        scope.patientForm.firstName.$invalid = true
        scope.isNameEditable = true
        scope.isFieldBeingEdited = true
        scope.isInitialNameMissing = true
      if _.isEmpty(scope.patient.lastName)
        scope.patientForm.lastName.$invalid = true
        scope.isNameEditable = true
        scope.isFieldBeingEdited = true
        scope.isInitialNameMissing = true
      scope.$log.log 'scope.patient', scope.patient

    scope.save = (patient, formField, isEditState, isFieldInvalid) ->
      if !_.isEmpty isFieldInvalid.$modelValue
        isFirstNameValid = true
        isLastNameValid = true
        if scope.isNameEditable
          invalidCharactersRegex = /([\&<]+([^>]+)[\;>]+)/ig
          isFirstNameValid = !_.isEmpty(scope.patientForm.firstName.$modelValue) && _.isEmpty(scope.patientForm.firstName.$modelValue.match(invalidCharactersRegex))
          isLastNameValid = !_.isEmpty(scope.patientForm.lastName.$modelValue) && _.isEmpty(scope.patientForm.lastName.$modelValue.match(invalidCharactersRegex))
        if isFirstNameValid && isLastNameValid
          scope.isInitialNameMissing = false
          scope.$log.log 'patientForm being submitted', scope.patientForm
          contact = angular.copy(scope.patient)
          delete contact.allowSms
          PhrUserService.patchAccountSettings(contact).then (response) ->
            if formField == 'email'
              scope.initPatient.email = patient.email
              toastr.success('Check your email for the confirmation we just sent and click on the link to confirm your email address.', '', { timeOut: 8000 })
            else if formField == 'username'
              scope.initPatient.username = patient.username
              toastr.success('Your new username has been successfully saved.', '', { toastClass: 'toastr-width-500' })
            else if formField == 'mobilePhone'
              scope.initPatient.phone = patient.phone
            else
              scope.initPatient.firstName = patient.firstName
              scope.initPatient.lastName = patient.lastName
            $log.log 'patient contact info saved', response
            scope[isEditState] = false # fire this after the success callback
            scope.isFieldBeingEdited = false;

            # update our user record in the data store (DS)
            angular.extend($rootScope.DS.user, scope.patient)
          , (response) ->
            scope.patientForm[formField].$invalid = true
            if response.data && (response.data.errors || [])[0] && response.data.errors[0].status == 409
              if formField == 'email'
                toastr.error('Unable to save changes. The email address entered is already in use.')
              else if formField == 'username'
                toastr.error('Unable to save changes. The username entered is already in use.')
            else
              toastr.error('An error occurred while saving your changes')
        else
          if (_.isEmpty(scope.patientForm.firstName.$modelValue) || _.isEmpty(scope.patientForm.lastName.$modelValue))
            toastr.error('Enter a first and last name')
          else
            toastr.error('Field contains invalid characters')
          if !isFirstNameValid
            scope.patientForm.firstName.$invalid = true
          if !isLastNameValid
            scope.patientForm.lastName.$invalid = true

    scope.reset = () ->
      scope.patient = angular.copy(scope.initPatient)
      scope.isFieldBeingEdited = false;

    scope.popup = (patient, formField, isEditState, isFieldInvalid) ->
      modalOptions =
        patient: patient

      modalDefaults =
        templateUrl: "portal/account-settings/widgets/contact-info/modal-screens/confirm-email.html"
        backdrop: 'static'
        keyboard: true
        modalFade: true

      ModalService.showModal(modalDefaults, modalOptions).then (result) ->
        if result.result == "ok"
          if patient.email == result.confirmEmail
            scope[isEditState] = false
            $log.log "Saving email address:", patient.email
            toastr.success('We appreciate your patience as we process your new email.')
            scope.save(patient, 'email', isEditState, isFieldInvalid)
          else
            toastr.error('Your email addresses must match. Enter the email address again.')
        else
          $log.log "CANCEL selected"

  templateUrl: 'portal/account-settings/widgets/contact-info/contact-info-directive-template.html'
]