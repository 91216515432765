import logo from '../../../../images/base/logo-brandmark.svg';

window.portalModule.controller 'ProceduresController', ($scope, $state, $stateParams, $log, $filter, AuthService, ProceduresService, FEATURE_BITS) ->
  $scope.logo = logo
  $log = $log.getInstance('ProceduresController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'procedures controller loaded.'
  $scope.isHealthRecordsOverviewOn = FEATURE_BITS.isHealthRecordsOverviewOn

  ProceduresService.findAll().then (response) ->
    $scope.procedures = response.data
    $log.log '$scope.procedures', $scope.procedures
