angular.module('GlobalHelpersModule').factory 'NotificationService', ($rootScope, $timeout, $log) ->
  emit: (errorType, payload) ->
    if errorType is 'intake-warning'
      # angularjs error? the broadcast is fired before the $on listener is created. this prevents that.
      $timeout ->
        $rootScope.$broadcast 'intake-warning', payload
      , 0

    if errorType is 'intake-success'
        # angularjs error? the broadcast is fired before the $on listener is created. this prevents that.
      $timeout ->
        $rootScope.$broadcast 'intake-success', payload
      , 0

    if errorType is 'intake-info'
      # angularjs error? the broadcast is fired before the $on listener is created. this prevents that.
      $timeout ->
        $rootScope.$broadcast 'intake-info', payload
      , 0