# retrieves session to make sure its valid.
# PUT/PATCH to /session to reset the TTL (keep alive)
window.portalModule.factory 'PhrAuthenticationService', ($log, Restangular, AuthService) ->

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().PHR_AUTHN_ENDPOINT_URL)

  _phrAuthnFoundationService = restAngular.all("")

  getSession: ->
    return _phrAuthnFoundationService.get('sessions')

  keepAlive: -> # this forces the TTL to refresh
    return _phrAuthnFoundationService.one('sessions').patch()
