window.portalModule.factory 'HealthRecordOverviewService', ($rootScope, $log, Restangular, AuthService, $q) ->
  $log = $log.getInstance('HealthRecordOverviewService')

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().CLINICAL_ENDPOINT_URL+ '/overview')

  _healthRecordOverviewService = restAngular.all("")

  cache: {}
  findAll: (forceRefresh) ->
    phr_patient_guid = $rootScope.DS.getCurrentPhrPatient().phrPatientGuid
    if not forceRefresh and @cache[phr_patient_guid]
      return $q.resolve @cache[phr_patient_guid]
    _healthRecordOverviewService.customGET('', { phr_patient_guid }).then((response) =>
      @processResponse(response)
      @cache[phr_patient_guid] = response
      response
    )

  findAllInRange: (start_date, end_date)->
    phr_patient_guid = $rootScope.DS.getCurrentPhrPatient().phrPatientGuid
    _healthRecordOverviewService.customGET('', { phr_patient_guid, start_date, end_date }).then((response) => @processResponse(response))

  processResponse: (response) ->
    response.timestamp = moment(new Date)
    if response.labsAndImaging
      for order in response.labsAndImaging
        for test in order.tests
          for observation in test.observations
            @processObservation(observation)
            if observation.childObservations
              @processObservation(childObservation) for childObservation in observation.childObservations
    response

  processObservation: (observation) ->
    if observation.abnormalFlags
      for flag in observation.abnormalFlags
        if not (flag.indexOf('Normal') is 0)
          observation.isAbnormal = true
          break
    observation.comments = observation.abnormalFlags.join(', ')
    observation.isCorrected = observation.statusDescription isnt 'Final'
