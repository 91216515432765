import logo from '../../../../images/base/logo-brandmark.svg';

window.portalModule.controller 'SocialHistoryController', ($scope, $state, $stateParams, $log, $filter, AuthService, SocialHistoryService, FEATURE_BITS) ->
  $scope.logo = logo
  $log = $log.getInstance('SocialHistoryController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'social-history controller loaded.'
  $scope.isHealthRecordsOverviewOn = FEATURE_BITS.isHealthRecordsOverviewOn

  SocialHistoryService.find().then (response) ->
    $scope.socialHistory = response.data
    $log.log '$scope.socialHistory', $scope.socialHistory
