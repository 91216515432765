window.portalModule.factory 'MedicationsService', ($rootScope, $log, Restangular, AuthService, CacheFactory) ->
  $log = $log.getInstance('MedicationsService')

  # create a cache
  medicationsCache = CacheFactory('medicationsCache', {
    deleteOnExpire: 'aggressive',
    cacheFlushInterval: 1800000, # this cache will check for expired items 30 minutes
    storageMode: 'memory'
  })

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().CLINICAL_ENDPOINT_URL)
    Configurer.setDefaultHttpFields({cache: medicationsCache})

  _medicationsService = restAngular.all("")

  getMedications: (filter) -> # filter would be 'historical' for past meds
    return _medicationsService.customGET('medications', {patient_practice_guid: $rootScope.DS.getCurrentPatientPracticeGuid(), filter: filter})