window.portalModule.factory 'AuditrService', ['$rootScope', '$log', 'Restangular', 'AuthService', 'TimerService', 'UtilityService'
($rootScope, $log, Restangular, AuthService, TimerService, UtilityService) ->
  $log = $log.getInstance('AuditrService')
  offset = 0

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().AUDITR_ENDPOINT_URL + '/api/v1')

  _auditrService = restAngular.all("audit_entries")

  ## TODO: Fix - we broke audit after introducing authorization with a jwt
  findAll: (offset, limit) ->
    return _auditrService.get('', {patient_practice_guid: $rootScope.DS.getCurrentPatientPracticeGuid(), offset:offset, limit:limit})

  getMoreEntries: (incrementOffsetBy, limit) ->
    return _auditrService.get('', {patient_practice_guid: $rootScope.DS.getCurrentPatientPracticeGuid(), offset:offset += incrementOffsetBy, limit:limit})

  create: (description, auditType=false) ->
    newAudit = {}
    newAudit.description = description
    newAudit.patientPracticeGuid = $rootScope.DS.getCurrentPatientPracticeGuid()
    newAudit.humanName = AuthService.getUsername()
    # $log.log "audit create", newAudit

    return _auditrService.post({data:newAudit})
]