window.portalModule.factory 'NotificationsService', ($log, Restangular, AuthService, CacheFactory) ->
  $log = $log.getInstance('NotificationsService')

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().NOTIFICATION_ENDPOINT_URL)

  _notificationsService = restAngular.all("notifications")

  findAll: ->
    return _notificationsService.customGET('')

  patch: (request) ->
    return _notificationsService.one('update').patch({data: request})
    $log.log "update one object as read"

  updateAllAsRead: (request) ->
    # PATCH the request array to the service
    return _notificationsService.one('bulk_update').patch({data: request})
    $log.log "update all objects as read"