import logo from '../../../../images/base/logo-brandmark.svg';

window.portalModule.controller 'VitalsController', ($scope, $state, $stateParams, $log, $filter, AuthService, VitalsService, FEATURE_BITS, HealthRecordOverviewService) ->
  $log = $log.getInstance('VitalsController')
  $scope.environment = AuthService.getEnvironment()
  $log.log 'vitals controller loaded.'
  $scope.isHealthRecordsOverviewOn = FEATURE_BITS.isHealthRecordsOverviewOn
  $scope.logo = logo

  $scope.vitalsMetadata = [{
    key: 'height',
    title: 'Height'
  }, {
    key: 'weight',
    title: 'Weight'
  }, {
    key: 'bmi',
    title: 'BMI'
  }, {
    key: 'bloodPressure',
    title: 'Blood Pressure'
  }, {
    key: 'heartRate',
    title: 'Heart Rate'
  }, {
    key: 'respiratoryRate',
    title: 'Respiratory Rate'
  }, {
    key: 'bodyTemperature',
    title: 'Body Temperature'
  }, {
    key: 'pulseOximetry',
    title: 'Pulse Oximetry'
  }, {
    key: 'inhaledOxygenConcentration',
    title: 'Inhaled Oxygen Concentration'
  }]

  if $scope.isHealthRecordsOverviewOn
    $scope.customPageHeader = 'Vital Records'
    $scope.load = (forceRefresh) ->
      HealthRecordOverviewService.findAll(forceRefresh).then (response) ->
        { vitals } = response
        $scope.vitals = $scope.vitalsMetadata.map (metadata) ->
          vital = vitals[metadata.key]
          newestObservation = null
          if vital
            for observation in vital
              if not newestObservation or newestObservation.recordedDateTimeUtc < observation.recordedDateTimeUtc
                newestObservation = observation
          return {
            name: metadata.title,
            value: if newestObservation then newestObservation.result else null,
            lastUpdated: if newestObservation then moment.utc(newestObservation.recordedDateTimeUtc).format('MM/DD/YYYY') else null
          }
    $scope.$watch 'DS.currentPhrPatientIndex', (newValue, oldValue) -> $scope.load(true) if newValue isnt oldValue
    $scope.load()
  else
    VitalsService.findAll().then (response) ->
      $scope.vitals = response.data
      $log.log '$scope.vitals', $scope.vitals
