window.portalModule.controller 'ConfirmedRescheduleApprovedInfoModalController', ($scope, $log, $timeout, $uibModalInstance, feedItem, InfoModalService) ->
  $log = $log.getInstance('ConfirmedRescheduleApprovedInfoModalController')
  $log.log 'confirmed reschedule approved info modal controller loaded.'

  # Variables
  # -----------------------------------

  $scope.headerText = "Your appointment is rescheduled per your request"
  $scope.feedItem = feedItem.data
  $log.log 'feedItem', $scope.feedItem

  # Setup
  # -----------------------------------
  # this service determines the default background state to load if a modal doesn't have one (page refresh)
  # in that case it also determines where to tranition to when the user closes the modal
  InfoModalService.setup($scope.feedItem)

  # Actions
  # -----------------------------------
  $scope.close = ->
    $log.log 'cancel called'
    $uibModalInstance.dismiss "cancel"

  # when you click outside the modal go to the parent state
  $uibModalInstance.result.finally (response) ->
    InfoModalService.transitionAway()

  # Transistion away if the feedItem is in the incorrect state
  $uibModalInstance.rendered.finally (response) ->
    unless $scope.feedItem.record_state == 'confirmed_reschedule_approved'
      $uibModalInstance.dismiss "cancel"