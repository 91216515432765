window.portalModule.factory 'InfoModalService', ($log, $stickyState, $state, $stateParams, $previousState) ->
  $log = $log.getInstance('InfoModalService')
  $log.log '$stateParams', $stateParams
  defaultBackgroundState = ''

  # on page refreshes no previous sticky state will exist and nothing will load behind the modal.
  # in this case determine the state of the feedItem and set the background modal state to the
  # correct appt filter (upcoming, past, canceled)
  setup: (feedItem) ->
    @defaultBackgroundState = 'portal.appointments.' + feedItem.scope
    if $stickyState.getInactiveStates().length is 0
      # load the background state - {location:false} doesn't change the url (or remove the modal)
      $state.go(@defaultBackgroundState, $stateParams, { location: false })

  # If we have an inactive state transition back to it, if not, load the default background state
  transitionAway: ->
    if $stickyState.getInactiveStates().length is 0
      $state.go(@defaultBackgroundState, $stateParams)
    else
      $previousState.go()