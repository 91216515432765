window.portalModule.factory 'CCDAService', ($rootScope, $log, $filter, $timeout, Restangular, AuthService, CacheFactory) ->
  $log = $log.getInstance('CCDAService')
  timeout = null

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl("#{AuthService.getConfigData().CCDA_ENDPOINT_URL}/api/v1")

  _ccdasService = restAngular.all("ccda_documents")
  _ccdasExportService = restAngular.all("ccda_exports")

  generateXMLRecord: (result) ->
    request = {}
    recordTypeArray = []
    request.patient_practice_guid = $rootScope.DS.getCurrentPatientPracticeGuid()

    for recordType in result.recordTypes
      recordTypeArray.push(recordType.label) if recordType.value is true
    request['sections'] = recordTypeArray

    return _ccdasService.post({data:request})

  export: (transmission) ->
    request = {
      to: transmission.email
      note: transmission.message
      ccda_document_guid: transmission.selectedCCDA.attributes.ccda_document_guid
      patient_practice_guid: $rootScope.DS.getCurrentPatientPracticeGuid()
    }
    return _ccdasService.customPOST({data:request}, 'export')

#    ccda_export[to]:PRD-EHR-test-mcontrary@direct.practicefusion.com
#    ccda_export[note]:test
#    id:1646
#    ccda_export[patient_practice_guid]:CBE9BA16-BDBB-4434-8F3B-BF4F07F61E9D

  getRecordTypes: ->
    return [
      {
        label: 'Vital Signs'
        name: 'vitals'
        value: true
      },
      {
        label: 'Medications'
        name: 'medications'
        value: true
      },
      {
        label: 'Allergies'
        name: 'allergies'
        value: true
      },
      {
        label: 'Diagnoses'
        name: 'diagnoses'
        value: true
      },
      {
        label: 'Procedures'
        name: 'procedures'
        value: true
      },
      {
        label: 'Care Plans'
        name: 'care_plans'
        value: true
      },
      {
        label: 'Immunizations'
        name: 'immunizations'
        value: true
      }
      {
        label: 'Lab Tests and Results'
        name: 'lab_results'
        value: true
      }
      {
        label: 'Social History'
        name: 'social_history'
        value: true
      }
    ]

# example ccda object
#      {
#        'createdAt': '2014-05-30T16:39:21Z'
#        'failed': null
#        'guid': '7D74A530-5DBB-4771-8F52-8F8630E638A5'
#        'id': 244
#        'patientPracticeGuid': 'C40C4B72-081B-4D9D-8014-9A174CD11E6A'
#        'requestToken': '1310'
#        'updatedAt': '2014-05-30T16:40:34Z'
#        'url': null
#        'userId': 18026
#        'status': 'Completed'
#        'disabled': false
#      }
