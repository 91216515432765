window.tasksModule.factory 'TasksService', ($rootScope, $log, Restangular, AuthService, Task) ->
  $log = $log.getInstance('TasksService')

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().TASK_ENDPOINT_URL + '/api/v1')
    Configurer.addResponseInterceptor (data, operation, what) ->
      if operation is 'get' and what isnt 'tasks/count'
        for item in data.data
          # extend the objects with the Task factory
          angular.extend(item, Task)
        return data
      return data

  _tasksService = restAngular.all("")

  findAll: (filterState) ->
    return _tasksService.one('users', AuthService.getPhrUserGuid()).customGET('tasks/' + filterState, {phr_patient_guid:$rootScope.DS.getCurrentPhrPatient().phrPatientGuid})

  find: (taskGuid) ->
    return _tasksService.one('users', AuthService.getPhrUserGuid()).one('tasks', taskGuid).customGET('', {phr_patient_guid:$rootScope.DS.getCurrentPhrPatient().phrPatientGuid})

  count: ->
    return _tasksService.one('users', AuthService.getPhrUserGuid()).customGET('tasks/count', {phr_patient_guid:$rootScope.DS.getCurrentPhrPatient().phrPatientGuid})

  patch: (task, request) ->
    return _tasksService.one('tasks', task.task_guid).patch(data: request)

  setState: (task, state) ->
    # build request object to PATCH
    request = {}
    request.state = state
    $log.log 'setTaskState', request

    # perform the PATCH
    this.patch(task, request)