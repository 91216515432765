window.globalHelpersModule.directive 'observations', 
['$rootScope', '$log', '$timeout', '$state',
($rootScope, $log, $timeout, $state) ->
  restrict: 'E'
  link: (scope, element, attrs) ->
    scope.isOpen = true # is drawer open?
    scope.test = attrs.test

    scope.formatUnitOfMeasure = (unitOfMeasure) ->
      if _.isEmpty unitOfMeasure
        return ""
      else
        return '(' + unitOfMeasure + ')'

    scope.formatStandardRange = (unitOfMeasure, range) ->
      return range.replace(unitOfMeasure, "")

  templateUrl: 'portal/health-records/labs/observations-directive-template.html'
]