window.portalModule.service 'TimerService', ($window, $log, $timeout, AuthService, PhrAuthenticationService, ModalService, jwtHelper, LogoutService) ->
  $log = $log.getInstance('TimerService')

  expiresAt = null
  ttlAndPromptDifference = 300 # prompt w/ modal this many seconds before timeoutInSeconds expires
  timeoutInSeconds = null
  promptUserTimeoutInSeconds = null
  logoutTimer = null
  promptUserTimer = null
  logoutMessage = 'You have been logged out of Patient Fusion after 60 minutes of inactivity.'

  bootstrap: ->
    if AuthService.getEnvironment() != 'test'
      expiresAt = jwtHelper.getTokenExpirationDate(AuthService.getAuthToken())
      timeoutInSeconds = @getTimeoutInSeconds()
      promptUserTimeoutInSeconds = timeoutInSeconds - ttlAndPromptDifference
      @init()

  init: ->
    promptUserTimer = $timeout(=>
      # show modal
      @showKeepAliveModal()
    , (promptUserTimeoutInSeconds * 1000))

    logoutTimer = $timeout(=>
      @signOutUser()
    , (timeoutInSeconds * 1000))

    logoutTimer.then (->
      # $log.log 'Timer resolved!'
      return
    ), ->
      # $log.log 'Timer rejected!'
      return

  resetTimer: ->
    # PhrAuthenticationService.keepAlive()
    $timeout.cancel(promptUserTimer)
    # $timeout.cancel(logoutTimer)
    # @init()

  signOutUser: ->
    LogoutService.logout().finally () ->
      AuthService.signoutUserFromAuth0()

  showKeepAliveModal: ->
    modalDefaults =
      backdrop: 'static'
      keyboard: true
      modalFade: true
      templateUrl: "global/templates/session-timeout-modal.html"

    modalOptions =
      closeButtonText: "Ignore"
      actionButtonText: "Ok"
      headerText: "Session timeout alert"
      bodyText: "You will be logged out in "
      countdown: Number(@getTimeoutInSeconds())

    ModalService.showModal(modalDefaults, modalOptions).then (result) =>
      if result is 'ok'
        $log.log 'user has extended their session TTL'
        @resetTimer()

  getTimeoutInSeconds: ->
    Math.floor((expiresAt.valueOf() - Date.now()) / 1000)
