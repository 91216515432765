import { parse } from 'uuid';

angular.module('GlobalHelpersModule').factory 'UtilityService', ($log) ->
  compareGuids: (guid1, guid2) ->
    try
      bytes1 = parse(String(guid1))
      bytes2 = parse(String(guid2))
      # difference returns [] for equal arrays
      difference = _.difference(bytes1, bytes2)
      return difference.length == 0
    catch
      return false

  truncate: (string, maxCharacters) ->
    substring = string.substring(0, maxCharacters)
    if string.length > maxCharacters
      return substring + "..."
    else
      return substring