window.portalModule.factory 'AllergiesService', ($rootScope, $log, Restangular, AuthService, CacheFactory) ->
  $log = $log.getInstance('AllergiesService')

  # create a cache
  allergiesCache = CacheFactory('allergiesCache', {
    deleteOnExpire: 'aggressive',
    cacheFlushInterval: 1800000, # this cache will check for expired items 30 minutes
    storageMode: 'memory'
  })

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().CLINICAL_ENDPOINT_URL)
    Configurer.setDefaultHttpFields({cache: allergiesCache})

  _allergiesService = restAngular.all("")

  findAll: ->
    return _allergiesService.customGET('allergies', {patient_practice_guid: $rootScope.DS.getCurrentPatientPracticeGuid()})