window.portalModule.controller 'EnrollmentTaskController',
['enrollmentGuid', '$log', 'RegistrationService', '$state',
(enrollmentGuid, $log, RegistrationService, $state) ->
    $log = $log.getInstance('EnrollmentTaskController')
    $log.log('enrollment task controller loaded.')

    RegistrationService.getEnrollment(enrollmentGuid).then((enrollment) ->
        if enrollment
            enrollmentHasExpired = moment.utc(enrollment.expiresAt).isBefore()
            enrollmentIsComplete = enrollment.status.toLowerCase() == 'completed'
            enrollmentIsPendingForExistingUser = (
                enrollment.status.toLowerCase() == 'pending' and enrollment.phrUserProfileGuid
            )
            if(enrollmentHasExpired or enrollmentIsComplete or enrollmentIsPendingForExistingUser)
                if(enrollmentHasExpired)
                    $log.log('The enrollment request has expired')
                if(enrollmentIsComplete)
                    $log.log('The enrollment request has already been completed')
                if(enrollmentIsPendingForExistingUser)
                    $log.log('The enrollment is pending for a user profile which already exists')
            else
                RegistrationService.updateEnrollment(enrollmentGuid)
    ).finally ->
        $state.go('root')
]
