window.portalModule.factory 'DiagnosesService', ($rootScope, $log, Restangular, AuthService, CacheFactory) ->
  $log = $log.getInstance('DiagnosesService')

  # create a cache
  diagnosesCache = CacheFactory('diagnosesCache', {
    deleteOnExpire: 'aggressive',
    cacheFlushInterval: 1800000, # this cache will check for expired items 30 minutes
    storageMode: 'memory'
  })

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().CLINICAL_ENDPOINT_URL)
    Configurer.setDefaultHttpFields({cache: diagnosesCache})
    Configurer.addResponseInterceptor (data, operation, what) ->
      if operation is 'get'
        # format the dates as per antionette
        list = data.data
        for record in list
          if moment(record.startDate) < moment("1902-01-01")
            record.startDate = null
          if moment(record.stopDate) > moment("9000-01-01")
            record.stopDate = null
        return list

  _diagnosesService = restAngular.all("diagnoses")

  findAll: ->
    return _diagnosesService.customGET('', {patient_practice_guid: $rootScope.DS.getCurrentPatientPracticeGuid()})
