window.tasksModule.directive "tVerifyAccess", ->
  restrict: "E"
  scope:
    task: '='

  link: (scope, element, attrs) ->
      properties = scope.task.properties
      scope.patientName = "#{properties.patient_first_name} #{properties.patient_last_name}"

  templateUrl: 'portal/tasks/tasks/t-verify-access/t-verify-access-template.html'
