window.portalModule.factory 'RescheduleService', ($rootScope, $log, Restangular, AuthService, CacheFactory, FeedItem, PhrPatientService, SchedulingService, ProposalService, NewRequestService) ->
  $log = $log.getInstance('RescheduleService')

  # Configuration
  # -----------------------------------

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().APPOINTMENT_ENDPOINT_URL + '/api/v2')
    Configurer.addResponseInterceptor (data, operation, what) ->
      if operation is 'get'
        if _.isArray(data.data)
          for item in data.data
            # extend the objects with the FeedItem factory
            angular.extend(item, FeedItem)
        else
          angular.extend(data.data, FeedItem)

        return data
      return data

  _rescheduleService = restAngular.all('')

  # Actions
  # -----------------------------------

  # POST a confirmed appointment
  postAppointment: (feedItem, rescheduleRequest) ->
    request = {}
    request.data = {
      type: 'reschedule_requests'
      attributes: {
        practice_guid: feedItem.practice.practice_guid
        start_date_time_flt: SchedulingService.formatDateTime(rescheduleRequest.dateTime)
        patient_reason: rescheduleRequest.notes
      }
    }
    return _rescheduleService.one('appointments', rescheduleRequest.scheduledEventGuid).post('reschedule_requests', request)

  # PUT an appointment request
  putRequest: (feedItem, rescheduleRequest) ->
    request = {}
    request.data = {
      type: feedItem.feed_item_type
      attributes: {
        practice_guid: feedItem.practice.practice_guid
        start_date_time_flt: SchedulingService.formatDateTime(rescheduleRequest.dateTime)
        patient_reason: rescheduleRequest.notes
      }
    }
    return _rescheduleService.one(feedItem.feed_item_type, feedItem.feed_item_guid).customPUT(request)

  updateProviderProposed: (feedItem, rescheduleRequest) ->
    phrPatientRequest = null
    appointmentRequest = {}
    # build the new appointment request object
    appointmentRequest.facilityGuid = feedItem.facility.facility_guid
    appointmentRequest.dateTime = SchedulingService.formatDateTime(rescheduleRequest.dateTime)
    appointmentRequest.isExistingPatient = true
    appointmentRequest.providerGuid = feedItem.provider.provider_guid
    appointmentRequest.reason = rescheduleRequest.notes
    appointmentRequest.preferences = ''

    # fetch the phr patient
    phrPatientRequest = PhrPatientService.get($rootScope.DS.getCurrentPhrPatient().phrPatientGuid)
    phrPatientRequest.then (response) ->
      phrPatient = response.data
      $log.log 'phrPatient', phrPatient
      # finish building the new appointment request object
      appointmentRequest.firstName = phrPatient.firstName
      appointmentRequest.lastName = phrPatient.lastName
      appointmentRequest.dateOfBirth = phrPatient.dateOfBirth
      appointmentRequest.email = phrPatient.email
      appointmentRequest.mobilePhone = phrPatient.mobilePhone

      # transition the proposal (reject it)
      ProposalService.transitionRequest(feedItem, 'reject').then (response) ->
        # upon transition success, create a new appt request
        return NewRequestService.create(appointmentRequest)
