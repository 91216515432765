window.portalModule.directive("authorizedApps", ['$rootScope', 'PhrUserService', 'toastr', function ($rootScope, PhrUserService, toastr) {
  return {
    restrict: 'E',
    link: function (scope) {
      scope.grants = [];
      scope.accessMessage = 'No connections at this time.';
      scope.getGrants = function() {
        return PhrUserService.getGrants()
          .then(function(response) {
            scope.grants = response.grants.filter(function(grant) {
              return grant.grant_name !== 'patient_portal';
            });
          })
          .catch(function(error) {
            scope.accessMessage = 'There was a problem retrieving your apps. Please refresh and try again.';
            console.error(error);
            toastr.warning('Unable to retrieve your 3rd party applications');
          });
      };

      scope.revokeGrant = function(grant) {
        scope.disableRevoke = true;
        PhrUserService.deleteGrant(grant.grant_id)
          .then(function() {
            scope.getGrants()
              .then(function() {
                scope.disableRevoke = false;
              });
            toastr.success('Application removed');
          })
          .catch(function(error) {
            console.error(error);
            scope.disableRevoke = false;
            toastr.warning('Unable to revoke at this time, please try again');
          });
      };

      scope.getGrants();
    },
    templateUrl: 'portal/account-settings/widgets/authorized-apps/authorized-apps-directive-template.html'
  };
}]);
