window.tasksModule.directive "tOabInformation", 
['$log', '$state', 'TasksService', 'AuditrService',
($log, $state, TasksService, AuditrService) ->
  restrict: "E"
  scope:
    task: '='
  link: (scope, element, attrs) ->
    scope.$log = $log.getInstance('tOabInformation directive', true, false, true)
    scope.$log.log 'tOab'

    scope.setTaskState = (state) ->
      # update the task on the server
      $log.log 'setTaskState: tAppointment', state
      TasksService.setState(scope.task, state).then (response) ->
        scope.$emit 'task-updated'

    scope.read = (state) ->
      scope.setTaskState(state).then (response) ->
        # transition on success
        $state.go('portal.oab-task', {task_guid: scope.task.task_guid})

  templateUrl: 'portal/tasks/tasks/t-oab-information/t-oab-information-directive-template.html'
]